import React, { useState } from 'react';
import styles from './styles.module.scss';
import clsx from "clsx";


export const SubscriptionSelector = ({
  selected,
  setSelected,
  options
}: {
  selected: string | number | null;
  setSelected: (selected: string | number | null) => void;
  options: Array<{ id: string | number; label: string }>;
}) => {

  const handleSelect = (id: string | number) => {
    setSelected(id);
  };

  return (
    <div className={styles.Subscription}>
      {options.map((option) => (
        <div
          key={option.id}
          className={clsx(styles.Subscription__Card, selected === option.id && styles.Subscription__Card_selected)}
          onClick={() => handleSelect(option.id)}
        >
          <span className={styles.Subscription__Label}>
            {option.label}
          </span>
          <div className={styles.Subscription__Circle}>
            {selected === option.id && (
              <div className={styles.Subscription__Circle_filled} />
            )}
          </div>
        </div>
      ))}
    </div>
  );
};
