import { wrapApiRequest } from '../../helpers/_common/wrap-api-request'
import { mapDongleCreateBackendToDongleCreate } from '../../mappers/dongle/_crud/create/map-dongle-create-backend-to-dongle-create'
import { mapDongleDeleteBackendToDongleDelete } from '../../mappers/dongle/_crud/delete/map-dongle-delete-backend-to-dongle-delete'
import { mapDongleGetBackendToDongleGet } from '../../mappers/dongle/_crud/get/map-dongle-get-backend-to-dongle-get'
import { mapDongleListBackendToDongleList } from '../../mappers/dongle/_crud/list/map-dongle-list-backend-to-dongle-list'
import { mapDongleUpdateBackendToDongleUpdate } from '../../mappers/dongle/_crud/update/map-dongle-update-backend-to-dongle-update'
import { mapDongleAtBackendToDongleAt } from '../../mappers/dongle/at/map-dongle-at-backend-to-dongle-at'
import { mapDongleCallCreateBackendToDongleCallCreate } from '../../mappers/dongle/call/_crud/create/map-dongle-call-create-backend-to-dongle-call-create'
import { mapDongleCallDeleteBackendToDongleCallDelete } from '../../mappers/dongle/call/_crud/delete/map-dongle-call-delete-backend-to-dongle-call-delete'
import { mapDongleCallGetBackendToDongleCallGet } from '../../mappers/dongle/call/_crud/get/map-dongle-call-get-backend-to-dongle-call-get'
import { mapDongleCallListBackendToDongleCallList } from '../../mappers/dongle/call/_crud/list/map-dongle-call-list-backend-to-dongle-call-list'
import { mapDongleMobileNetworkIdsBackendToDongleMobileNetworkIds } from '../../mappers/dongle/mobile-network-ids/map-dongle-mobile-network-ids-backend-to-dongle-mobile-network-ids'
import { mapDongleMobileNetworkInfoBackendToDongleMobileNetworkInfo } from '../../mappers/dongle/mobile-network-info/map-dongle-mobile-network-info-backend-to-mobile-network-info'
import { mapDongleRebootBackendToDongleReboot } from '../../mappers/dongle/reboot/map-dongle-reboot-backend-to-dongle-reboot'
import { mapDongleRedirectionCreateBackendToDongleRedirectionCreate } from '../../mappers/dongle/redirection/_crud/create/map-dongle-redirection-create-backend-to-dongle-redirection-create'
import { mapDongleRedirectionDeleteBackendToDongleRedirectionDelete } from '../../mappers/dongle/redirection/_crud/delete/map-dongle-redirection-delete-backend-to-dongle-redirection-delete'
import { mapDongleRedirectionGetBackendToDongleRedirectionGet } from '../../mappers/dongle/redirection/_crud/get/map-dongle-redirection-get-backend-to-dongle-redirection-get'
import { mapDongleRedirectionListBackendToDongleRedirectionList } from '../../mappers/dongle/redirection/_crud/list/map-dongle-redirection-list-backend-to-dongle-redirection-list'
import { mapDongleRedirectionUpdateBackendToDongleRedirectionUpdate } from '../../mappers/dongle/redirection/_crud/update/map-dongle-redirection-update-backend-to-dongle-redirection-update'
import { mapDongleSmsCreateBackendToDongleSmsCreate } from '../../mappers/dongle/sms/_crud/create/map-dongle-sms-create-backend-to-dongle-sms-create'
import { mapDongleSmsDeleteBackendToDongleSmsDelete } from '../../mappers/dongle/sms/_crud/delete/map-dongle-sms-delete-backend-to-dongle-sms-delete'
import { mapDongleSmsGetBackendToDongleSmsGet } from '../../mappers/dongle/sms/_crud/get/map-dongle-sms-get-backend-to-dongle-sms-get'
import { mapDongleSmsListBackendToDongleSmsList } from '../../mappers/dongle/sms/_crud/list/map-dongle-sms-list-backend-to-dongle-sms-list'
import { mapDongleStatisticBackendToDongleStatistic } from '../../mappers/dongle/statistic/map-dongle-statistic-backend-to-dongle-statistic'
import { mapDongleUssdBackendToDongleUssd } from '../../mappers/dongle/ussd/map-dongle-ussd-backend-to-dongle-ussd'
import {
  DongleCreateBackendResponse,
  DongleCreateRequest,
  DongleCreateResponse,
} from '../../types/dongle/_crud/create'
import {
  DongleDeleteBackendResponse,
  DongleDeleteRequest,
  DongleDeleteResponse,
} from '../../types/dongle/_crud/delete'
import {
  DongleGetBackendResponse,
  DongleGetRequest,
  DongleGetResponse,
} from '../../types/dongle/_crud/get'
import {
  DongleListBackendResponse,
  DongleListRequest,
  DongleListResponse,
} from '../../types/dongle/_crud/list'
import {
  DongleUpdateBackendResponse,
  DongleUpdateRequest,
  DongleUpdateResponse,
} from '../../types/dongle/_crud/update'
import {
  DongleAtBackendResponse,
  DongleAtRequest,
  DongleAtResponse,
} from '../../types/dongle/at'
import {
  DongleCallCreateBackendResponse,
  DongleCallCreateRequest,
  DongleCallCreateResponse,
} from '../../types/dongle/call/_crud/create'
import {
  DongleCallDeleteBackendResponse,
  DongleCallDeleteRequest,
  DongleCallDeleteResponse,
} from '../../types/dongle/call/_crud/delete'
import {
  DongleCallGetBackendResponse,
  DongleCallGetRequest,
  DongleCallGetResponse,
} from '../../types/dongle/call/_crud/get'
import {
  DongleCallListBackendResponse,
  DongleCallListRequest,
  DongleCallListResponse,
} from '../../types/dongle/call/_crud/list'
import {
  DongleMobileNetworkIdsBackendResponse,
  DongleMobileNetworkIdsRequest,
  DongleMobileNetworkIdsResponse,
} from '../../types/dongle/mobile-network-ids'
import {
  DongleMobileNetworkInfoBackendResponse,
  DongleMobileNetworkInfoRequest,
  DongleMobileNetworkInfoResponse,
} from '../../types/dongle/mobile-network-info'
import {
  DongleRebootBackendResponse,
  DongleRebootRequest,
  DongleRebootResponse,
} from '../../types/dongle/reboot'
import {
  DongleRedirectionCreateBackendResponse,
  DongleRedirectionCreateRequest,
  DongleRedirectionCreateResponse,
} from '../../types/dongle/redirection/_crud/create'
import {
  DongleRedirectionDeleteBackendResponse,
  DongleRedirectionDeleteRequest,
  DongleRedirectionDeleteResponse,
} from '../../types/dongle/redirection/_crud/delete'
import {
  DongleRedirectionGetBackendResponse,
  DongleRedirectionGetRequest,
  DongleRedirectionGetResponse,
} from '../../types/dongle/redirection/_crud/get'
import {
  DongleRedirectionListBackendResponse,
  DongleRedirectionListRequest,
  DongleRedirectionListResponse,
} from '../../types/dongle/redirection/_crud/list'
import {
  DongleRedirectionUpdateBackendResponse,
  DongleRedirectionUpdateRequest,
  DongleRedirectionUpdateResponse,
} from '../../types/dongle/redirection/_crud/update'
import {
  DongleSmsCreateBackendResponse,
  DongleSmsCreateRequest,
  DongleSmsCreateResponse,
} from '../../types/dongle/sms/_crud/create'
import {
  DongleSmsDeleteBackendResponse,
  DongleSmsDeleteRequest,
  DongleSmsDeleteResponse,
} from '../../types/dongle/sms/_crud/delete'
import {
  DongleSmsGetBackendResponse,
  DongleSmsGetRequest,
  DongleSmsGetResponse,
} from '../../types/dongle/sms/_crud/get'
import {
  DongleSmsListBackendResponse,
  DongleSmsListRequest,
  DongleSmsListResponse,
} from '../../types/dongle/sms/_crud/list'
import {
  DongleStatisticBackendResponse,
  DongleStatisticRequest,
  DongleStatisticResponse,
} from '../../types/dongle/statistic'
import {
  DongleUssdBackendResponse,
  DongleUssdRequest,
  DongleUssdResponse,
} from '../../types/dongle/ussd'
import {DonglePayBackendResponse, DonglePayRequest, DonglePayResponse} from "../../types/dongle/pay";
import {mapDonglePayBackend} from "../../mappers/dongle/pay/map-dongle-pay";
import {
  DongleIMEIChangeBackendResponse,
  DongleIMEIChangeRequest,
  DongleIMEIChangeResponse
} from "../../types/dongle/IMEIChange";
import {mapDongleIMEIChangeBackendToDongleIMEIChange} from "../../mappers/dongle/IMEIChange/map";

export const dongleListDatagate = wrapApiRequest<
  DongleListRequest,
  DongleListBackendResponse,
  DongleListResponse
>('dongle', mapDongleListBackendToDongleList, 'GET')

export const donglePayDatagate = wrapApiRequest<
  DonglePayRequest,
  DonglePayBackendResponse,
  DonglePayResponse
>('dongle/{dongle_id}/pay', mapDonglePayBackend, 'POST')

export const dongleGetDatagate = wrapApiRequest<
  DongleGetRequest,
  DongleGetBackendResponse,
  DongleGetResponse
>('dongle/{dongle_id}', mapDongleGetBackendToDongleGet, 'GET')

export const dongleCreateDatagate = wrapApiRequest<
  DongleCreateRequest,
  DongleCreateBackendResponse,
  DongleCreateResponse
>('dongle/activate', mapDongleCreateBackendToDongleCreate, 'POST')

export const dongleUpdateDatagate = wrapApiRequest<
  DongleUpdateRequest,
  DongleUpdateBackendResponse,
  DongleUpdateResponse
>('dongle/{dongle_id}', mapDongleUpdateBackendToDongleUpdate, 'PATCH')

export const dongleDeleteDatagate = wrapApiRequest<
  DongleDeleteRequest,
  DongleDeleteBackendResponse,
  DongleDeleteResponse
>('dongle/{dongle_id}', mapDongleDeleteBackendToDongleDelete, 'DELETE')

export const dongleStatisticDatagate = wrapApiRequest<
  DongleStatisticRequest,
  DongleStatisticBackendResponse,
  DongleStatisticResponse
>(
  'dongle/{dongle_id}/statistic',
  mapDongleStatisticBackendToDongleStatistic,
  'GET',
)

export const dongleAtDatagate = wrapApiRequest<
  DongleAtRequest,
  DongleAtBackendResponse,
  DongleAtResponse
>('dongle/{dongle_id}/at', mapDongleAtBackendToDongleAt)

export const dongleRebootDatagate = wrapApiRequest<
  DongleRebootRequest,
  DongleRebootBackendResponse,
  DongleRebootResponse
>('dongle/{dongle_id}/reboot', mapDongleRebootBackendToDongleReboot)

export const dongleIMEIChangeDatagate = wrapApiRequest<
  DongleIMEIChangeRequest,
  DongleIMEIChangeBackendResponse,
  DongleIMEIChangeResponse
>('/dongle/{dongle_id}/fake-imei', mapDongleIMEIChangeBackendToDongleIMEIChange, 'POST')

export const dongleMobileNetworkInfoDatagate = wrapApiRequest<
  DongleMobileNetworkInfoRequest,
  DongleMobileNetworkInfoBackendResponse,
  DongleMobileNetworkInfoResponse
>(
  'dongle/{dongle_id}/mobile-network-info',
  mapDongleMobileNetworkInfoBackendToDongleMobileNetworkInfo,
)

export const dongleMobileNetworkIdsDatagate = wrapApiRequest<
  DongleMobileNetworkIdsRequest,
  DongleMobileNetworkIdsBackendResponse,
  DongleMobileNetworkIdsResponse
>(
  'dongle/{dongle_id}/mobile-network-ids',
  mapDongleMobileNetworkIdsBackendToDongleMobileNetworkIds,
)

export const dongleUssdDatagate = wrapApiRequest<
  DongleUssdRequest,
  DongleUssdBackendResponse,
  DongleUssdResponse
>('dongle/{dongle_id}/ussd', mapDongleUssdBackendToDongleUssd)

export const dongleCallListDatagate = wrapApiRequest<
  DongleCallListRequest,
  DongleCallListBackendResponse,
  DongleCallListResponse
>('dongle/{dongle_id}/call', mapDongleCallListBackendToDongleCallList, 'GET')

export const dongleCallGetDatagate = wrapApiRequest<
  DongleCallGetRequest,
  DongleCallGetBackendResponse,
  DongleCallGetResponse
>(
  'dongle/{dongle_id}/call/{call_id}',
  mapDongleCallGetBackendToDongleCallGet,
  'GET',
)

export const dongleCallCreateDatagate = wrapApiRequest<
  DongleCallCreateRequest,
  DongleCallCreateBackendResponse,
  DongleCallCreateResponse
>(
  'dongle/{dongle_id}/call',
  mapDongleCallCreateBackendToDongleCallCreate,
  'POST',
)

export const dongleCallDeleteDatagate = wrapApiRequest<
  DongleCallDeleteRequest,
  DongleCallDeleteBackendResponse,
  DongleCallDeleteResponse
>(
  'dongle/{dongle_id}/call/{call_id}',
  mapDongleCallDeleteBackendToDongleCallDelete,
  'DELETE',
)

export const dongleSmsListDatagate = wrapApiRequest<
  DongleSmsListRequest,
  DongleSmsListBackendResponse,
  DongleSmsListResponse
>('dongle/{dongle_id}/sms', mapDongleSmsListBackendToDongleSmsList, 'GET')

export const dongleSmsGetDatagate = wrapApiRequest<
  DongleSmsGetRequest,
  DongleSmsGetBackendResponse,
  DongleSmsGetResponse
>(
  'dongle/{dongle_id}/sms/{sms_id}',
  mapDongleSmsGetBackendToDongleSmsGet,
  'GET',
)

export const dongleSmsCreateDatagate = wrapApiRequest<
  DongleSmsCreateRequest,
  DongleSmsCreateBackendResponse,
  DongleSmsCreateResponse
>('dongle/{dongle_id}/sms', mapDongleSmsCreateBackendToDongleSmsCreate, 'POST')

export const dongleSmsDeleteDatagate = wrapApiRequest<
  DongleSmsDeleteRequest,
  DongleSmsDeleteBackendResponse,
  DongleSmsDeleteResponse
>(
  'dongle/{dongle_id}/sms/{sms_id}',
  mapDongleSmsDeleteBackendToDongleSmsDelete,
  'DELETE',
)

export const dongleRedirectionListDatagate = wrapApiRequest<
  DongleRedirectionListRequest,
  DongleRedirectionListBackendResponse,
  DongleRedirectionListResponse
>(
  'dongle/{dongle_id}/redirection',
  mapDongleRedirectionListBackendToDongleRedirectionList,
  'GET',
)

export const dongleRedirectionGetDatagate = wrapApiRequest<
  DongleRedirectionGetRequest,
  DongleRedirectionGetBackendResponse,
  DongleRedirectionGetResponse
>(
  'dongle/{dongle_id}/redirection/{redirection_id}',
  mapDongleRedirectionGetBackendToDongleRedirectionGet,
  'GET',
)

export const dongleRedirectionCreateDatagate = wrapApiRequest<
  DongleRedirectionCreateRequest,
  DongleRedirectionCreateBackendResponse,
  DongleRedirectionCreateResponse
>(
  'dongle/{dongle_id}/redirection',
  mapDongleRedirectionCreateBackendToDongleRedirectionCreate,
  'POST',
)

export const dongleRedirectionUpdateDatagate = wrapApiRequest<
  DongleRedirectionUpdateRequest,
  DongleRedirectionUpdateBackendResponse,
  DongleRedirectionUpdateResponse
>(
  'dongle/{dongle_id}/redirection/{redirection_id}',
  mapDongleRedirectionUpdateBackendToDongleRedirectionUpdate,
  'PATCH',
)

export const dongleRedirectionDeleteDatagate = wrapApiRequest<
  DongleRedirectionDeleteRequest,
  DongleRedirectionDeleteBackendResponse,
  DongleRedirectionDeleteResponse
>(
  'dongle/{dongle_id}/redirection/{redirection_id}',
  mapDongleRedirectionDeleteBackendToDongleRedirectionDelete,
  'DELETE',
)
