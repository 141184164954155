import {useEffect, useMemo, useState} from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../app/hooks/useModal'
import { useSnackbar } from '../../../app/hooks/useSnackbar'
import { queryFromFilters } from '../../../layouts/filters/_helpers/queryFromFilters'
import { SNACKBAR_TYPES } from '../../../layouts/snackbar/SnackbarProvider'
import { getErrorMessage } from '../../../shared/lib/utils/get-error-message/getErrorMessage'
import { dongleSmsDeleteDatagate } from '../../datagates/api/dongle'
import { smsListDatagate } from '../../datagates/api/sms'
import { useBackendErrorCodes } from '../../datagates/helpers/_common/use-backend-error-codes'
import { QueryParameter } from '../../datagates/helpers/_common/wrap-api-request'
import { SmsListResponse } from '../../datagates/types/sms/_crud/list'

type UseSmsesProps = {
  filters: QueryParameter[]
  mailingHistoryId?: number
}

export const SmsType = {
  OUTGOING: 1,
  INCOMING: 2,
  AUTO_REPLY: 3,
  REPLY: 4,
  DELAYED: 5,
  MAILING: 6,
  SILENT: 7,
}

export const SmsTypeTabs = {
  ALL: '',
  OUTGOING: SmsType.OUTGOING.toString(),
  INCOMING: SmsType.INCOMING.toString(),
  DELAYED: SmsType.DELAYED.toString(),
}

const UseSmsesMessages = defineMessages({
  positiveDeleted: {
    id: 'UseSmsesMessages.positiveDeleted',
    defaultMessage: 'SMS is successfully deleted',
  },
  negativeDeleted: {
    id: 'UseSmsesMessages.negativeDeleted',
    defaultMessage: 'An error occurred while deleting SMS',
  },
})


export const canSmsBeReplied = (sms: SmsListResponse['smses'][0]) => {
  return (
    (sms.smsTypeId === SmsType.INCOMING || sms.smsTypeId === SmsType.SILENT)
  )
}

export const isSmsAutoReplied = (sms: SmsListResponse['smses'][0]) => {
  return sms.repliedFromSms?.smsTypeId === SmsType.AUTO_REPLY
}

export const isSmsManuallyReplied = (sms: SmsListResponse['smses'][0]) => {
  return sms.repliedFromSms?.smsTypeId === SmsType.REPLY
}

export const isSmsRedirected = (sms: SmsListResponse['smses'][0]) => {
  return sms.redirectedTo.length > 0
}

export const isDeviceSender = (smsTypeId: number) =>
  [
    SmsType.OUTGOING,
    SmsType.DELAYED,
    SmsType.REPLY,
    SmsType.AUTO_REPLY,
    SmsType.MAILING,
  ].includes(smsTypeId);


export const useSmses = (props: UseSmsesProps) => {
  const {
    filters = [],
    mailingHistoryId,
  } = props

  const stableFilters = useMemo(() => filters, [JSON.stringify(filters)]);

  const intl = useIntl()
  const { resolveBackendError } = useBackendErrorCodes()
  const { handleHideModal } = useModal()
  const { handleOpenSnackbar } = useSnackbar()

  const [smses, setSmses] = useState<SmsListResponse['smses']>([])
  const [totalCount, setTotalCount] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(true)
  const [initialFetchCompleted, setInitialFetchCompleted] = useState<boolean>(false)

  const handleDeleteSms = async (dongleId: number, smsId: number) => {
    try {
      const urlParams = [
        { name: 'dongle_id', value: dongleId.toString() },
        { name: 'sms_id', value: smsId.toString() },
      ]

      await dongleSmsDeleteDatagate(null, urlParams)
      await handleFetch()
      handleHideModal()
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.success,
        text: intl.formatMessage(UseSmsesMessages.positiveDeleted),
      })
    } catch {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: intl.formatMessage(UseSmsesMessages.negativeDeleted),
      })
    }
  }

  const handleFetch = async ({ params = [], hidden }: { params?: QueryParameter[], hidden?: boolean } = {}) => {
    try {
      setLoading(!hidden)

      const queryParams: QueryParameter[] = [
        { name: 'order_by', value: 'created_at' },
        { name: 'order_type', value: 'desc' },
        ...stableFilters,
        ...params,
      ]

      if (mailingHistoryId) {
        queryParams.push({
          name: 'mailing_history_ids',
          value: [mailingHistoryId.toString()],
        })
        queryParams.push({
          name: 'sms_type_ids',
          value: [SmsType.MAILING.toString()],
        })
      }

      const { data } = await smsListDatagate(null, null, queryParams)

      setTotalCount(data.totalCount)
      setSmses(data.smses)
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: resolveBackendError(getErrorMessage(e)),
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    void handleFetch();
  }, [stableFilters])

  return {
    smses,
    totalCount,
    handleFetch,
    handleDeleteSms,
    loading,
    initialFetchCompleted,
    setInitialFetchCompleted
  }
}
