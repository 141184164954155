import {LoadingContent} from "../../../../layouts/loading-content/LoadingContent";
import {Card} from "../../../../shared/ui-kit-2/data-display/card/Card";
import {PageWrapper} from "../../../../layouts/page-wrapper/PageWrapper";
import { ReactComponent as AlertIcon } from '../../../../assets/icons/v2/ic-alert.svg';
import { ReactComponent as CheckInCircleIcon } from '../../../../assets/icons/v2/ic-check-in-circle.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/v2/ic-close.svg';
import styles from "./styles.module.scss";
import {Button} from "../../../../shared/ui-kit-3/components/Button";
import {RoutePath} from "../../../../config/routes/constants/routePath";
import {Route} from "../../../../config/routes/enums/route";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import {useSearchParams} from "react-router-dom";
import {useDevice} from "../../../../sdk/hooks/use-device/useDevice";
import clsx from "clsx";
import {useTariffPackages} from "../../../../sdk/hooks/use-tariff-packages/useTariffPackages";
import {
  TARIFF_MONTH_PACKAGES_IDS,
  TARIFF_PACKAGE_IDS,
  TARIFF_YEAR_PACKAGES_IDS
} from "../../../../sdk/datagates/types/order/_common";
import {formatPrice} from "../../../../sdk/formatters/format-price";
import {formatDate} from "../../../../shared/lib/utils/date-utils/formatDate";


export const DeviceConnectionStatus = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams();

  const dongleId = searchParams.get("dongleId");
  const errorText = searchParams.get("errorText");
  const IMEIFullCode = searchParams.get("IMEIFullCode");
  const MACFullCode = searchParams.get("MACFullCode");

  const [hasError, setError] = useState<boolean>(Boolean(!dongleId || errorText));

  const {
    device,
    loading: devicesLoading,
  } = useDevice({ deviceId: Number(dongleId) });

  const {
    tariffPackages,
    loading: tariffPackagesLoading,
    totalCount,
    getTariffPackageTranslation,
    getTariffPackagePrice,
    getDevicePrice,
    getTotalPrices,
  } = useTariffPackages()

  useEffect(() => {
    if (!device && !(devicesLoading || tariffPackagesLoading)) {
      setError(true);
    }
  }, [device, devicesLoading, tariffPackagesLoading]);

  return (
    <PageWrapper>
      <LoadingContent
        loading={!hasError && (devicesLoading || tariffPackagesLoading)}
        content={(
          <Card additionalClassNames={[styles.CardAdditional]}>
            <div className={styles.Content}>
              <div className={clsx(styles.Icon, hasError && styles.Icon_error)}>
                {hasError ? <CloseIcon /> : <CheckInCircleIcon />}
              </div>
              <div className={styles.Title}>
                {hasError ? 'Modem is not connected' : `${device?.name} successfully connected`}
              </div>
              {errorText && (
                <div className={styles.ErrorText}>
                  {errorText}
                </div>
              )}
              {!hasError && !device?.tariffPackageId && (
                <div className={styles.Help}>
                  <div className={styles.Help__Icon}>
                    <AlertIcon />
                  </div>
                  <div className={styles.Help__Text}>
                    You have access to the basic functionality of our cabinet: incoming sms, WI-FI distribution. In order to use all the functions of the cabinet, select “Buy subscription”
                  </div>
                </div>
              )}
              {!hasError && device?.tariffPackageId && (
                <div className={styles.Subscription}>
                  <div className={styles.Subscription__Title}>
                    Subscription
                  </div>
                  <div className={styles.Subscription__Item}>
                    <div className={styles.Subscription__Name}>
                      Type:
                    </div>
                    <div className={styles.Subscription__Value}>
                      {TARIFF_MONTH_PACKAGES_IDS.includes(device.tariffPackageId) && (
                        <>
                          1 month
                        </>
                      )}
                      {TARIFF_YEAR_PACKAGES_IDS.includes(device.tariffPackageId) && (
                        <>
                          1 year
                        </>
                      )}
                    </div>
                  </div>
                  <div className={styles.Subscription__Item}>
                    <div className={styles.Subscription__Name}>
                      Cost:
                    </div>
                    <div className={styles.Subscription__Value}>
                      {formatPrice(getDevicePrice(TARIFF_PACKAGE_IDS.TARIFF_PACKAGE_MONTH_EUROPE))}
                    </div>
                  </div>
                  {device.tariffPackageEnd && (
                    <div className={styles.Subscription__Item}>
                      <div className={styles.Subscription__Name}>
                        Next payment:
                      </div>
                      <div className={styles.Subscription__Value}>
                        {formatDate(device.tariffPackageEnd, 0, 'date')}
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div className={styles.Actions}>
                {hasError ? (
                  <Button
                    variant={'greenFilled'}
                    text={'Try again'}
                    size={'xl'}
                    onClick={() => navigate(`${RoutePath[Route.ActivateDevice]}?IMEIFullCode=${IMEIFullCode}&MACFullCode=${MACFullCode}`)}
                    className={styles.Button}
                  />
                ) : device?.tariffPackageId ? (
                  <Button
                    variant={'greenFilled'}
                    text={'Go to modems'}
                    size={'xl'}
                    onClick={() => navigate(RoutePath[Route.DeviceList])}
                    className={styles.Button}
                  />
                ) : (
                  <>
                    <Button
                      variant={'greyOutlined'}
                      text={'Continue unsubscribed'}
                      size={'xl'}
                      onClick={() => navigate(RoutePath[Route.DeviceList])}
                      className={styles.Button}
                    />
                    <Button
                      variant={'greenFilled'}
                      text={'Buy subscription'}
                      size={'xl'}
                      onClick={() => navigate(`${RoutePath[Route.DeviceList]}?dongleId=${dongleId}`)}
                      className={styles.Button}
                    />
                  </>
                )}
              </div>
            </div>
          </Card>
        )}
      />
    </PageWrapper>
  )
}