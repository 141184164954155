import React, { FC, useEffect, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../../../../../../app/hooks/useModal'
import { CommonButtonMessages } from '../../../../../../../../config/intl/common-messages/common-button-messages/CommonButtonMessages'
import { MODAL_TYPES } from '../../../../../../../../layouts/modals/ModalsProvider'
import { EmptySmsRedirections } from '../../../../../../../../layouts/status-layouts/empty-sms-redirections/EmptySmsRedirections'
import { REDIRECTION_TYPES } from '../../../../../../../../sdk/hooks/use-redirection-types/constants/RedirectionTypes'
import { useRedirections } from '../../../../../../../../sdk/hooks/use-redirections/useRedirections'
import { Card } from '../../../../../../../../shared/ui-kit-2/data-display/card/Card'
import { Loader } from '../../../../../../../../shared/ui-kit/data-display/loader/Loader'
import { SmsRedirectionCard } from './components/sms-redirection-card/SmsRedirectionCard'
import styles from './styles.module.scss'
import ControlHeader from "../../../../../../../../shared/ui-kit-3/components/ControlHeader/ControlHeader";
import { ReactComponent as AddIcon } from '../../../../../../../../assets/icons/v2/ic-plus.svg';


interface SmsRedirectionListProps {}

export const SmsRedirectionList: FC<SmsRedirectionListProps> = () => {
  const intl = useIntl()
  const { handleOpenModal } = useModal()
  const {
    redirections,
    totalCount,
    handleFetch,
    handleDeleteRedirection,
    loading,
  } = useRedirections({
    page: 0,
    limit: 1000,
    redirectionTypes: [REDIRECTION_TYPES.SMS],
    takeAll: true,
  })
  const [initialLoading, setInitialLoading] = useState<boolean>(true)
  const [atLeastOneRedirectionCreated, setAtLeastOneRedirectionCreated] =
    useState<boolean>(false)

  useEffect(() => {
    if (totalCount > 0) setAtLeastOneRedirectionCreated(true)
  }, [totalCount])

  useEffect(() => {
    if (!loading) setInitialLoading(false)
  }, [loading])

  const handleOpenAutoReplySettingsModal = () => {
    handleOpenModal({
      type: MODAL_TYPES.REDIRECTION_SETTINGS,
      props: {
        redirectionTypeId: REDIRECTION_TYPES.SMS,
        handleFetch: handleFetch,
        phoneSourceVariant: 'all',
      },
    })
  }

  const content = !atLeastOneRedirectionCreated ? (
    <EmptySmsRedirections />
  ) : loading ? (
    <Loader />
  ) : (
    <div className={styles.ScrollableContent}>
      {redirections.map((redirection) => (
        <SmsRedirectionCard
          key={redirection.redirectionId}
          redirection={redirection}
          handleFetch={handleFetch}
          handleDeleteRedirection={handleDeleteRedirection}
        />
      ))}
    </div>
  )

  return (
    <Card additionalClassNames={[styles.CardAdditional]}>
      <ControlHeader
        title={'SMS Auto Replies'}
        counter={{
          min: redirections.length,
          max: totalCount
        }}
        actions={[{
          variant: 'greenFilled',
          size: 'md',
          text: intl.formatMessage(CommonButtonMessages.create),
          prefix: <AddIcon />,
          onClick: handleOpenAutoReplySettingsModal,
        }]}
        loading={loading}
      />

      {initialLoading ? <Loader /> : content}
    </Card>
  )
}
