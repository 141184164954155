import {useEffect, useMemo, useState} from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../app/hooks/useModal'
import { useSnackbar } from '../../../app/hooks/useSnackbar'
import { SNACKBAR_TYPES } from '../../../layouts/snackbar/SnackbarProvider'
import { getErrorMessage } from '../../../shared/lib/utils/get-error-message/getErrorMessage'
import {
  redirectionDeleteDatagate,
  redirectionListDatagate,
} from '../../datagates/api/redirection'
import { useBackendErrorCodes } from '../../datagates/helpers/_common/use-backend-error-codes'
import { QueryParameter } from '../../datagates/helpers/_common/wrap-api-request'
import { RedirectionListResponse } from '../../datagates/types/redirection/_crud/list'
import {isNumber} from "lodash";

type UseRedirectionsProps = {
  filters?: QueryParameter[]
  page?: number
  limit?: number
  deviceIds?: string[]
  redirectionTypes?: number[]
  takeAll?: boolean
}

const UseRedirectionsMessages = defineMessages({
  positiveDeleted: {
    id: 'UseRedirectionsMessages.positiveDeleted',
    defaultMessage: 'Redirection is successfully deleted',
  },
  negativeDeleted: {
    id: 'UseRedirectionsMessages.negativeDeleted',
    defaultMessage: 'An error occurred while deleting redirection',
  },
})

export const useRedirections = (props: UseRedirectionsProps) => {
  const {
    page,
    limit,
    deviceIds = [],
    redirectionTypes,
    takeAll = false,
    filters = []
  } = props;

  const stableFilters = useMemo(() => filters, [JSON.stringify(filters)]);

  const intl = useIntl()
  const { resolveBackendError } = useBackendErrorCodes()
  const { handleHideModal } = useModal()
  const { handleOpenSnackbar } = useSnackbar()

  const [redirections, setRedirections] = useState<
    RedirectionListResponse['redirections']
  >([])
  const [totalCount, setTotalCount] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(true)
  const [initialFetchCompleted, setInitialFetchCompleted] = useState<boolean>(false)

  const handleDeleteRedirection = async (redirectionId: number) => {
    try {
      const urlParams = [
        { name: 'redirection_id', value: redirectionId.toString() },
      ]

      await redirectionDeleteDatagate(null, urlParams)
      await handleFetch()
      handleHideModal()
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.success,
        text: intl.formatMessage(UseRedirectionsMessages.positiveDeleted),
      })
    } catch {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: intl.formatMessage(UseRedirectionsMessages.negativeDeleted),
      })
    }
  }

  const handleFetch = async ({ params = [] }: { params?: QueryParameter[] } = {}) => {
    try {
      setLoading(true)

      const queryParams: QueryParameter[] = [
        { name: 'order_by', value: 'created_at' },
        { name: 'order_type', value: 'desc' },
        ...stableFilters,
        ...params,
      ]

      if (deviceIds && params.length === 0) {
        queryParams.push({ name: 'dongle_ids', value: deviceIds })
      }

      if (!takeAll && !stableFilters && isNumber(page) && isNumber(limit)) {
        queryParams.push({ name: 'page', value: (page - 1).toString() })
        queryParams.push({ name: 'limit', value: limit.toString() })
      }

      if (redirectionTypes)
        queryParams.push({
          name: 'redirection_type_ids',
          value: redirectionTypes.map((x) => x.toString()),
        })

      const { data } = await redirectionListDatagate(null, null, queryParams)

      setTotalCount(data.totalCount)
      setRedirections(data.redirections)
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: resolveBackendError(getErrorMessage(e)),
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    void handleFetch()
  }, [page, limit, stableFilters, ...deviceIds])

  return {
    redirections,
    totalCount,
    handleFetch,
    handleDeleteRedirection,
    loading,
    initialFetchCompleted,
    setInitialFetchCompleted
  }
}
