import React, { FC, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDeleteMailingSubmodal } from '../../../../../_hooks/use-bool-action-submodal/templates/useDeleteMailingSubmodal'
import { CommonButtonMessages } from '../../../../../../../config/intl/common-messages/common-button-messages/CommonButtonMessages'
import { Button } from '../../../../../../../shared/ui-kit-2/inputs/button/Button'
import styles from './styles.module.scss'

interface SmsMailingCancelledContentProps {
  onDeleteMailing: () => Promise<void>
  forceAction: 'delete' | 'cancel' | null
}

export const CallsMailingCancelledContent: FC<SmsMailingCancelledContentProps> = (
  props,
) => {
  const { onDeleteMailing, forceAction } = props

  const intl = useIntl()
  const { setIsDeleteSubmodalOpen, deleteSubmodalComponent } =
    useDeleteMailingSubmodal({ onDeleteMailing })

  useEffect(() => {
    if (forceAction) setIsDeleteSubmodalOpen(true)
  }, [])

  return (
    <>
      <div className={styles.Container}>
        <Button
          text={intl.formatMessage(CommonButtonMessages.delete)}
          variant={'danger'}
          icon={'trash'}
          onClick={() => setIsDeleteSubmodalOpen(true)}
          additionalClassNames={[styles.Button]}
        />
      </div>
      {deleteSubmodalComponent}
    </>
  )
}
