
import { Card } from '../../../../../../shared/ui-kit-2/data-display/card/Card'
import styles from './styles.module.scss'
import {useDevices} from "../../../../../../sdk/hooks/use-devices/useDevices";
import {Switcher} from "../../../../../../shared/ui-kit-2/inputs/switcher/Switcher";
import {UserAutoRenewal} from "../../../../../../store/reducers/user/types";
import React, {useState} from "react";
import clsx from "clsx";
import {LoadingContent} from "../../../../../../layouts/loading-content/LoadingContent";
import {MainMessages} from "../../../../../../shared/intl-messages/MainMessages";
import {
  DeviceDetailsModalMessages
} from "../../../../../../layouts/modals/DeviceDetailsModal/messages/DeviceDetailsModalMessages";
import {ActionCard, SwitchVariant} from "../../../../../../shared/ui-kit-2/data-display/action-card/ActionCard";
import {DongleListResponse} from "../../../../../../sdk/datagates/types/dongle/_crud/list";
import {dongleUpdateDatagate} from "../../../../../../sdk/datagates/api/dongle";
import {useSnackbar} from "../../../../../../app/hooks/useSnackbar";
import {SNACKBAR_TYPES} from "../../../../../../layouts/snackbar/SnackbarProvider";
import {useDialog} from "../../../../../../app/hooks/useDialog";
import {DialogVersion} from "../../../../../../store/reducers/dialog/types";
import {DongleUpdateResponse} from "../../../../../../sdk/datagates/types/dongle/_crud/update";

// TODO: перенести в константы
export enum DongleCallTypeIds {
  VOICE_CALL = 1,
  ROBOTIC_CALL
}

export const CallsSettingsPage = () => {
  const { devices, loading, setDevices } = useDevices({
    page: 0,
    limit: 1000,
    takeAll: true,
    isActive: true,
  });
  const [loadings, setLoadings] = useState<Record<string, boolean>>({});
  const { handleOpenSnackbar } = useSnackbar();
  const { handleOpenDialog, handleHideDialog } = useDialog();

  const handleChangeLoadings = ({ dongleId, status }: { dongleId: number, status: boolean }) => {
    setLoadings(prev => {
      const updatedLoadings = { ...prev };
      if (status) {
        updatedLoadings[dongleId] = true;
      } else {
        delete updatedLoadings[dongleId];
      }
      return updatedLoadings;
    });
  };

  // TODO: Refactor and add to global components folder
  const GridHelper = () => (
    <>
      {devices.length < 5 ? [...new Array(5 - devices.length)].map(() => <div></div>) : undefined}
    </>
  );

  const handleUpdateDeviceById = (dongleId: number, data: DongleUpdateResponse) => {
    setDevices(prev => {
      const updatedDeviceIndex = prev.findIndex(({ dongleId: prevDeviceId }) => dongleId === prevDeviceId);
      if (updatedDeviceIndex === -1) return prev;

      const updatedDevices = [...prev];
      updatedDevices[updatedDeviceIndex] = {
        ...updatedDevices[updatedDeviceIndex],
        ...data
      };

      return updatedDevices;
    });
  }

  const handleSwitchHotspot = async (dongleId: number, dongleCallTypeId: number) => {
    try {
      handleChangeLoadings({ dongleId, status: true })
      const { data } = await dongleUpdateDatagate(
        {
          dongle_call_type_id: dongleCallTypeId,
        },
        [{ name: 'dongle_id', value: String(dongleId) }],
      );

      handleUpdateDeviceById(dongleId, data);
    } catch {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: 'An error occurred when changing the modem call mode.',
      })
    } finally {
      handleChangeLoadings({ dongleId, status: false })
    }
  }

  const handleUpdateMuteMode = async (dongleId: number, isMuteIncomingCall: boolean) => {
    try {
      const { data } = await dongleUpdateDatagate(
        {
          is_mute_incoming_call: isMuteIncomingCall,
        },
        [{ name: 'dongle_id', value: String(dongleId) }],
      );

      handleUpdateDeviceById(dongleId, data);
    } catch {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: 'An error occurred when changing the modem mute mode.',
      })
    }
  }

  const handleSwitchCallType = async (id: number, callType: number) => {
    if (callType === DongleCallTypeIds.VOICE_CALL) {
      handleOpenDialog({
        version: DialogVersion.v2,
        props: {
          title: 'Change type of call?',
          subtitle: 'Your auto response rules will be delete automatically. This action cannot be undone',
          subtitleIsAlert: true,
          cancel: {
            text: 'Cancel',
            onClick: () => handleHideDialog()
          },
          submit: {
            variant: 'green',
            text: 'Yes',
            onClick: () => {
              handleHideDialog();
              handleSwitchHotspot(id, callType);
            }
          },
        }
      })
    } else {
      handleSwitchHotspot(id, callType);
    }
  }

  const updContent = (
    <div className={styles.CallsSettingsPage}>
      {devices
        .sort((a, b) => +b.isOnline - +a.isOnline)
        .map(({ dongleId, name, phoneNumber, isOnline, dongleCallTypeId, isMuteIncomingCall }) => (
          <ActionCard
            key={dongleId}
            id={dongleId}
            name={name}
            number={phoneNumber}
            variant={SwitchVariant.NORMAL_CHOICE}
            loading={loadings[dongleId]}
            switcher={{
              currentValue: dongleCallTypeId === DongleCallTypeIds.VOICE_CALL,
              leftButton: {
                text: 'Voice call',
                onClick: id => handleSwitchCallType(id, DongleCallTypeIds.VOICE_CALL),
              },
              rightButton: {
                text: 'Robotic call',
                onClick: id => handleSwitchCallType(id, DongleCallTypeIds.ROBOTIC_CALL)
              },
            }}
            copyBlock={{
              show: false,
            }}
            footer={(
              <div className={styles.MuteMode}>
                <div className={styles.MuteMode__Col}>
                  <div className={styles.MuteMode__Title}>
                    Mute mode
                  </div>
                  <div className={styles.MuteMode__Subtitle}>
                    For incoming signals
                  </div>
                </div>
                <div className={styles.MuteMode__Col}>
                  <Switcher
                    checked={isMuteIncomingCall}
                    onChange={() => handleUpdateMuteMode(dongleId, !isMuteIncomingCall)}
                  />
                </div>
              </div>
            )}
            alert={{
              show: !isOnline,
              text: 'Please connect the modem to the network'
            }}
          />
        ))}
    </div>
  );

  return (
    <LoadingContent loading={loading} content={updContent} />
  )
}