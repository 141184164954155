import React, { FC, useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { defineMessages, useIntl } from 'react-intl'
import { PhoneMultipleSettings } from '../../../_components/phone-multiple-settings/PhoneMultipleSettings'
import { Source } from '../../../_hooks/use-add-source/useAddSource'
import { useDeleteMailingSubmodal } from '../../../_hooks/use-bool-action-submodal/templates/useDeleteMailingSubmodal'
import { useSnackbar } from '../../../../../app/hooks/useSnackbar'
import { CommonButtonMessages } from '../../../../../config/intl/common-messages/common-button-messages/CommonButtonMessages'
import { mailingUpdateDatagate } from '../../../../../sdk/datagates/api/mailing'
import { useBackendErrorCodes } from '../../../../../sdk/datagates/helpers/_common/use-backend-error-codes'
import { MailingGetResponse } from '../../../../../sdk/datagates/types/mailing/_crud/get'
import { formatPhoneNumber } from '../../../../../sdk/formatters/format-phone-number'
import { useDevices } from '../../../../../sdk/hooks/use-devices/useDevices'
import { MAILING_STATUSES } from '../../../../../sdk/hooks/use-mailing-statuses/constants/MailingStatuses'
import { MAILING_TYPE_IDS } from '../../../../../sdk/hooks/use-mailings/useMailings'
import { formatDate } from '../../../../../shared/lib/form/form-field-adapters/v2/datepicker-field/_helpers/formatDate'
import { InputField } from '../../../../../shared/lib/form/form-field-adapters/v2/input-field/InputField'
import { SelectSearchField } from '../../../../../shared/lib/form/form-field-adapters/v2/select-search-field/SelectSearchField'
import { TextareaField } from '../../../../../shared/lib/form/form-field-adapters/v2/textarea-field/TextareaField'
import { DatepickerOptionalField } from '../../../../../shared/lib/form/form-field-templates/v2/datepicker-optional-field/DatepickerOptionalField'
import { useFormRules } from '../../../../../shared/lib/form/form-rules'
import { getErrorMessage } from '../../../../../shared/lib/utils/get-error-message/getErrorMessage'
import { Button } from '../../../../../shared/ui-kit-2/inputs/button/Button'
import { SelectSearchOption } from '../../../../../shared/ui-kit-2/inputs/select-search/SelectSearch'
import { LoadingContent } from '../../../../loading-content/LoadingContent'
import { SNACKBAR_TYPES } from '../../../../snackbar/SnackbarProvider'
import { SmsMailingLimitTip } from '../../../../tips/sms-mailing-limit-tip/SmsMailingLimitTip'
import styles from './styles.module.scss'

type SmsMailingUpdatingType = 'create-draft' | 'send-now' | 'create-planned'

type SmsMailingCreateFormType = {
  creationType: SmsMailingUpdatingType
  mailingName: string
  deviceId: string
  smsMessage: string
  date?: string
}

interface SmsMailingDraftContentProps {
  mailing: MailingGetResponse
  handleHideModal: () => void
  onDeleteMailing: () => Promise<void>
  handleFetchMailings?: () => void
  forceAction: 'delete' | 'cancel' | null
}

const SmsMailingDraftContentMessages = defineMessages({
  title: {
    id: 'SmsMailingDraftContentMessages.title',
    defaultMessage: 'Create SMS mailing',
  },
  mailingNameLabel: {
    id: 'SmsMailingDraftContentMessages.mailingNameLabel',
    defaultMessage: 'Mailing name',
  },
  mailingNamePlaceholder: {
    id: 'SmsMailingDraftContentMessages.mailingNamePlaceholder',
    defaultMessage: 'Enter mailing name',
  },
  deviceLabel: {
    id: 'SmsMailingDraftContentMessages.deviceLabel',
    defaultMessage: 'Device',
  },
  devicePlaceholder: {
    id: 'SmsMailingDraftContentMessages.devicePlaceholder',
    defaultMessage: 'Choose device',
  },
  smsMessageLabel: {
    id: 'SmsMailingDraftContentMessages.smsMessageLabel',
    defaultMessage: 'SMS message',
  },
  smsMessagePlaceholder: {
    id: 'SmsMailingDraftContentMessages.smsMessagePlaceholder',
    defaultMessage: 'Enter text',
  },
  scheduledAtDateLabel: {
    id: 'SmsMailingDraftContentMessages.scheduledAtDateLabel',
    defaultMessage: 'Schedule a mailing time (UTC)',
  },
  saveChanges: {
    id: 'SmsMailingDraftContentMessages.saveChanges',
    defaultMessage: 'Save changes',
  },
  saveInDraftsSuccess: {
    id: 'SmsMailingDraftContentMessages.saveInDraftsSuccess',
    defaultMessage: 'New mailing saved in drafts successfully',
  },
  saveInDraftsError: {
    id: 'SmsMailingDraftContentMessages.saveInDraftsError',
    defaultMessage: 'An error occurred while saving mailing in drafts',
  },
  sendNow: {
    id: 'SmsMailingDraftContentMessages.sendNow',
    defaultMessage: 'Send now',
  },
  sendNowSuccess: {
    id: 'SmsMailingDraftContentMessages.sendNowSuccess',
    defaultMessage: 'New mailing successfully started',
  },
  sendNowError: {
    id: 'SmsMailingDraftContentMessages.sendNowError',
    defaultMessage: 'An error occurred while creating new mailing',
  },
  create: {
    id: 'SmsMailingDraftContentMessages.create',
    defaultMessage: 'Create',
  },
  createSuccess: {
    id: 'SmsMailingDraftContentMessages.createSuccess',
    defaultMessage: 'New mailing planned successfully',
  },
  createError: {
    id: 'SmsMailingDraftContentMessages.createError',
    defaultMessage: 'An error occurred while creating new planned mailing',
  },
  zeroSourcesError: {
    id: 'SmsMailingDraftContentMessages.zeroSourcesError',
    defaultMessage:
      'At least one phone number, contact or contact group must be selected',
  },
})

export const SmsMailingDraftContent: FC<SmsMailingDraftContentProps> = (
  props,
) => {
  const {
    mailing,
    handleHideModal,
    handleFetchMailings,
    onDeleteMailing,
    forceAction,
  } = props

  const intl = useIntl()
  const { handleOpenSnackbar } = useSnackbar()
  const { ruleRequired } = useFormRules()
  const { resolveBackendError } = useBackendErrorCodes()

  const [isScheduledAtDateOpen, setIsScheduledAtDateOpen] = useState<boolean>(
    !!mailing.scheduledAt,
  )

  const [sources, setSources] = useState<Source[]>([])

  const { devices, loading } = useDevices({ page: 0, limit: 1000, takeAll: true })
  const deviceOptions: SelectSearchOption[] = useMemo(
    () =>
      devices.map((d) => {
        return {
          key: d.dongleId,
          label: d.name,
          inputLabel: d.name,
          value: d.dongleId.toString(),
        }
      }),
    [devices],
  )

  const { setIsDeleteSubmodalOpen, deleteSubmodalComponent } =
    useDeleteMailingSubmodal({
      onDeleteMailing,
    })

  useEffect(() => {
    const phoneSources =
      mailing.mailingPhones?.map<Source>((mp) => {
        return {
          sourceType: 'phone',
          sourceName: formatPhoneNumber(mp.phone),
          sourceValue: mp.phone,
        }
      }) ?? []

    const contactSources =
      mailing.contacts?.map<Source>((c) => {
        return {
          sourceType: 'contacts',
          sourceName: c ? `${c.name} ${formatPhoneNumber(c.phone)}` : '',
          sourceValue: c.contactId.toString(),
        }
      }) ?? []

    const contactGroupSources =
      mailing.contactGroups?.map<Source>((cg) => {
        return {
          sourceType: 'contact-groups',
          sourceName: cg.name ?? '',
          sourceValue: cg.contactGroupId.toString(),
        }
      }) ?? []

    const allSources = [
      ...phoneSources,
      ...contactSources,
      ...contactGroupSources,
    ]

    setSources(allSources)
  }, [])

  useEffect(() => {
    if (forceAction === 'delete') setIsDeleteSubmodalOpen(true)
  }, [])

  const onSubmit = async (values: SmsMailingCreateFormType) => {
    try {
      if (sources.length === 0) {
        handleOpenSnackbar({
          type: SNACKBAR_TYPES.error,
          text: intl.formatMessage(
            SmsMailingDraftContentMessages.zeroSourcesError,
          ),
        })
        return
      }

      const getMailingStatusId = () => {
        switch (values.creationType) {
          case 'create-draft':
            return MAILING_STATUSES.DRAFT
          case 'send-now':
          case 'create-planned':
            return MAILING_STATUSES.PLANNED
        }
      }

      const contactGroups = sources
        .filter((s) => s.sourceType === 'contact-groups')
        .map((cg) => +cg.sourceValue)
      const contacts = sources
        .filter((s) => s.sourceType === 'contacts')
        .map((c) => +c.sourceValue)
      const phones = sources
        .filter((s) => s.sourceType === 'phone')
        .map((p) => p.sourceValue)

      const urlParams = [
        { name: 'mailing_id', value: mailing.mailingId.toString() },
      ]

      await mailingUpdateDatagate(
        {
          name: values.mailingName,
          mailing_type_id: values.date
            ? MAILING_TYPE_IDS.DELAYED
            : MAILING_TYPE_IDS.SIMPLE,
          mailing_status_id: getMailingStatusId(),
          text: values.smsMessage,
          dongle_id: +values.deviceId,
          mailing_phones: phones,
          mailing_contact_ids: contacts,
          mailing_contact_group_ids: contactGroups,
          scheduled_at: values.date ? formatDate(values.date) : undefined,
        },
        urlParams,
      )

      const successText = () => {
        switch (values.creationType) {
          case 'create-draft':
            return intl.formatMessage(
              SmsMailingDraftContentMessages.saveInDraftsSuccess,
            )
          case 'create-planned':
            return intl.formatMessage(
              SmsMailingDraftContentMessages.createSuccess,
            )
          case 'send-now':
            return intl.formatMessage(
              SmsMailingDraftContentMessages.sendNowSuccess,
            )
        }
      }

      handleOpenSnackbar({
        type: SNACKBAR_TYPES.success,
        text: successText(),
      })

      handleHideModal()
      handleFetchMailings?.()
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: resolveBackendError(getErrorMessage(e)),
      })
    }
  }

  const content = (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        mailingName: mailing.name,
        deviceId: mailing.dongle.dongleId,
        smsMessage: mailing.text,
        date: mailing.scheduledAt ? [mailing.scheduledAt] : undefined,
      }}
      render={({ handleSubmit, submitting, form, values }) => {
        const isPlanned = !!values.date

        return (
          <form onSubmit={handleSubmit} className={styles.Container}>
            <SmsMailingLimitTip />

            <InputField
              name={'mailingName'}
              validate={ruleRequired()}
              label={intl.formatMessage(
                SmsMailingDraftContentMessages.mailingNameLabel,
              )}
              placeholder={intl.formatMessage(
                SmsMailingDraftContentMessages.mailingNamePlaceholder,
              )}
              markAsRequired={true}
            />

            <SelectSearchField
              name="deviceId"
              defaultInputValue={mailing.dongle.name}
              validate={ruleRequired()}
              options={deviceOptions}
              label={intl.formatMessage(
                SmsMailingDraftContentMessages.deviceLabel,
              )}
              placeholder={intl.formatMessage(
                SmsMailingDraftContentMessages.devicePlaceholder,
              )}
              markAsRequired={true}
            />

            <PhoneMultipleSettings
              sources={sources}
              setSources={setSources}
              phoneSourceVariant={'all'}
              allPhonesFeatureOn={false}
            />

            <TextareaField
              name={'smsMessage'}
              validate={ruleRequired()}
              label={intl.formatMessage(
                SmsMailingDraftContentMessages.smsMessageLabel,
              )}
              placeholder={intl.formatMessage(
                SmsMailingDraftContentMessages.smsMessagePlaceholder,
              )}
            />

            <DatepickerOptionalField
              name={'date'}
              defaultDate={mailing.scheduledAt}
              validate={ruleRequired()}
              isOpen={isScheduledAtDateOpen}
              setIsOpen={setIsScheduledAtDateOpen}
              title={intl.formatMessage(
                SmsMailingDraftContentMessages.scheduledAtDateLabel,
              )}
              minDateToday={true}
              onRemove={() => form.change('date', undefined)}
            />

            <div className={styles.Actions}>
              <Button
                text={intl.formatMessage(CommonButtonMessages.delete)}
                variant={'danger'}
                icon={'trash'}
                disabled={submitting}
                onClick={() => setIsDeleteSubmodalOpen(true)}
              />

              <Button
                text={intl.formatMessage(
                  SmsMailingDraftContentMessages.saveChanges,
                )}
                variant={'blackTextBlackBorderOutlined'}
                disabled={submitting}
                onClick={() => {
                  form.change('creationType', 'create-draft')
                  handleSubmit()
                }}
              />

              {!isPlanned && (
                <Button
                  text={intl.formatMessage(
                    SmsMailingDraftContentMessages.sendNow,
                  )}
                  variant={'green'}
                  disabled={submitting}
                  onClick={() => {
                    form.change('creationType', 'send-now')
                    handleSubmit()
                  }}
                  additionalClassNames={[styles.ButtonFullWidth]}
                />
              )}

              {isPlanned && (
                <Button
                  text={intl.formatMessage(
                    SmsMailingDraftContentMessages.create,
                  )}
                  variant={'green'}
                  disabled={submitting}
                  onClick={() => {
                    form.change('creationType', 'create-planned')
                    handleSubmit()
                  }}
                  additionalClassNames={[styles.ButtonFullWidth]}
                />
              )}
            </div>
          </form>
        )
      }}
    />
  )

  return (
    <>
      <LoadingContent loading={loading} content={content} />
      {deleteSubmodalComponent}
    </>
  )
}
