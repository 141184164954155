import React, { FC } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { CommonButtonMessages } from '../../../../../../../config/intl/common-messages/common-button-messages/CommonButtonMessages'
import { MailingGetResponse } from '../../../../../../../sdk/datagates/types/mailing/_crud/get'
import { Button } from '../../../../../../../shared/ui-kit-2/inputs/button/Button'
import { MailingInProgressTip } from '../../../../../../tips/mailing-in-progress-tip/MailingInProgressTip'
import styles from './styles.module.scss'

interface SmsMailingInProcessProps {
  mailing: MailingGetResponse
  handleHideModal: () => void
}

const SmsMailingInProcessMessages = defineMessages({
  tickerTitle: {
    id: 'SmsMailingInProcessMessages.tickerTitle',
    defaultMessage: 'SMS Sending: {count}',
  },
})

export const SmsMailingInProcess: FC<SmsMailingInProcessProps> = (props) => {
  const intl = useIntl()

  const { mailing, handleHideModal } = props

  return (
    <div className={styles.Container}>
      <div className={styles.ProgressContainer}>
        <div className={styles.ProgressContainer__title}>
          {intl.formatMessage(SmsMailingInProcessMessages.tickerTitle, {
            count: mailing.countMailingPhones,
          })}
        </div>

        <div className={styles.ProgressBar}>
          <div className={styles.ProgressBar__ticker} />
        </div>
      </div>

      <div className={styles.Content}>
        <MailingInProgressTip />

        <Button
          text={intl.formatMessage(CommonButtonMessages.close)}
          onClick={handleHideModal}
        />
      </div>
    </div>
  )
}
