import React, { FC, useEffect, useMemo, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../../../../app/hooks/useModal'
import { CommonTabMessages } from '../../../../../../config/intl/common-messages/common-tab-messages/CommonTabMessages'
import {
  useFilters,
  UseFiltersTestIdsType,
} from '../../../../../../layouts/filters/hook/useFilters'
import { MODAL_TYPES } from '../../../../../../layouts/modals/ModalsProvider'
import { DongleListResponse } from '../../../../../../sdk/datagates/types/dongle/_crud/list'
import {
  SmsTypeTabs,
  useSmses,
} from '../../../../../../sdk/hooks/use-smses/useSmses'
import { Card } from '../../../../../../shared/ui-kit-2/data-display/card/Card'
import { LC } from '../../../../../../tests/e2e/locators'
import styles from './styles.module.scss'
import {getUrlParams} from "../../../../../../shared/lib/utils/get-url-params/get-url-params";
import {useWebSockets} from "../../../../../../shared/lib/hooks/useWebSockets";
import {WEB_SOCKETS_EVENTS_VALUES} from "../../../../../../shared/lib/constants/WEB_SOCKETS_EVENTS_VALUES";
import ControlHeader from "../../../../../../shared/ui-kit-3/components/ControlHeader/ControlHeader";
import { ReactComponent as PlusIcon } from '../../../../../../assets/icons/v2/ic-plus.svg';
import {NoDevicesForSmses} from "../../../../../../layouts/status-layouts/no-devices-for-smses/NoDevicesForSmses";
import {Table, TableColType} from "../../../../../../shared/ui-kit-2/data-display/table/Table";
import {SmsTableRows} from "../../../../../../layouts/tables/sms-table/components/sms-table-rows/SmsTableRows";
import {
  HeaderCol,
  TABLE_FILTERS_VARIANTS
} from "../../../../../../shared/ui-kit-2/data-display/table/components/TableHeader/TableHeader";
import {
  DEFAULT_COLS,
  DEFAULT_COLS_VARIANTS,
  IconContainer
} from "../../../../../../shared/ui-kit-2/data-display/table/contants/defaultCols";
import {useTableFilters} from "../../../../../../shared/ui-kit-2/data-display/table/hooks/useTableFilters";
import { ReactComponent as CaretDownIcon } from '../../../../../../assets/icons/v2/ic-caret-down.svg';
import {SMS_STATUSES} from "../../components/SmsStatus/constants/SmsStatuses";
import {
  tableFiltersToUrlFilters
} from "../../../../../../shared/lib/utils/table-filters-to-url-filters/tableFiltersToUrlFilters";


interface SmsListPageProps {
  activeDevices: DongleListResponse['dongles']
  activeDevicesLoading: boolean
}

const SmsListPageMessages = defineMessages({
  title: {
    id: 'SmsListPageMessages.title',
    defaultMessage: 'SMS',
  },
  newSms: {
    id: 'SmsListPageMessages.newSms',
    defaultMessage: 'SMS',
  },
})


export const SmsListPage: FC<SmsListPageProps> = ({
  activeDevices,
  activeDevicesLoading,
}) => {
  const intl = useIntl()

  const { webSocketsEventData } = useWebSockets({
    events: [
      WEB_SOCKETS_EVENTS_VALUES.sms_status_changed,
      WEB_SOCKETS_EVENTS_VALUES.sms_incoming_created,
      WEB_SOCKETS_EVENTS_VALUES.sms_outgoing_delayed_created,
    ]
  });

  const { handleOpenModal } = useModal()

  const COLS = {
    type: 'type',
    smsc: 'smsc',
  }

  const headerCols = [
    {
      id: COLS.type,
      filterType: TABLE_FILTERS_VARIANTS.CHECKBOXES,
      label: 'Type',
      defaultText: 'Select',
      defaultTextIcon: (
        <IconContainer>
          <CaretDownIcon />
        </IconContainer>
      ),
      checkboxes: [
        { text: 'All', value: 'All' },
        { text: 'Incoming', value: '2' },
        { text: 'Outgoing', value: '1' },
        { text: 'Planned', value: '5' },
      ]
    },
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.time],
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.smsStatus],
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.sender],
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.recipient],
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.smsc],
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.textMessage],
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.actions],
  ] as HeaderCol[];

  const {
    filters,
    handleChangeFilters,
    handlePageChange,
    debouncedFilters,
  } = useTableFilters({
    queryIds: [
      ...headerCols.map(({ id }) => id),
      'page',
      'limit',
    ],
    defaultFilterValues: [
      {
        name: 'sms_status_ids[]',
        value: [1, 2]
      },
      {
        name: 'page',
        value: 1
      },
      {
        name: 'limit',
        value: 50
      }
    ],
  });

  const smsStatuses = useMemo(() => {
    let smsStatuses = filters?.smsStatus ? [...filters?.smsStatus] : undefined;

    if (smsStatuses) {
      if (smsStatuses.includes(SMS_STATUSES.CREATED)) {
        smsStatuses.push(SMS_STATUSES.SENT)
      } else {
        smsStatuses = smsStatuses.filter(status => status !== SMS_STATUSES.SENT)
      }
    }

    return smsStatuses;
  }, [filters?.smsStatus]);

  const currentFilters = useMemo(() => tableFiltersToUrlFilters([
    { name: 'page', value: debouncedFilters?.page - 1 },
    { name: 'limit', value: debouncedFilters?.limit },
    { name: 'sms_type_ids[]', value: filters?.type },
    { name: 'from', value: filters?.time?.from },
    { name: 'to', value: filters?.time?.to },
    { name: 'sms_status_ids[]', value: smsStatuses },
    { name: 'caller', value: filters?.sender },
    { name: 'receiver', value: filters?.recipient },
    { name: 'sms_center', value: filters?.smsc },
    { name: 'text', value: filters?.textMessage },
  ]), [debouncedFilters, smsStatuses]);

  const {
    smses,
    totalCount: smsesTotalCount,
    handleFetch: handleFetchSmses,
    handleDeleteSms,
    loading: smsesLoading,
  } = useSmses(
    {
      filters: currentFilters,
    },
  )

  const openSendSmsModal = () => {
    handleOpenModal({
      type: MODAL_TYPES.SEND_SMS,
      props: { handleFetch: handleFetchSmses },
    })
  }

  useEffect(() => {
    if (filters.page === 1 && !smsesLoading) {
      handleFetchSmses({ hidden: true });
    }
  }, [webSocketsEventData]);

  const rowGroups = SmsTableRows(smses, handleFetchSmses, handleDeleteSms)

  const globalLoading = activeDevicesLoading || smsesLoading;

  return (
    <Card additionalClassNames={[styles.Card]}>
      <ControlHeader
        title={'SMS'}
        counter={{
          min: smses.length,
          max: smsesTotalCount
        }}
        actions={[{
          variant: 'greenFilled',
          size: 'md',
          text: intl.formatMessage(SmsListPageMessages.newSms),
          prefix: <PlusIcon />,
          onClick: openSendSmsModal,
        }]}
        loading={globalLoading}
      />

      <Table
        headerCols={headerCols}
        filters={filters}
        handleChangeFilters={handleChangeFilters}
        name={'SMS'}
        rowGroups={rowGroups}
        currentPage={filters.page}
        totalCount={smsesTotalCount}
        onPageChange={handlePageChange}
        itemsPerPage={filters.limit}
        loading={globalLoading}
        columnWidths={['100px', '125px', '120px', 2, 2, 1.5, 2, 2.5]}
        testId={LC.SMS.TABLE._}
        noDataComponent={activeDevices.length === 0 && <NoDevicesForSmses />}
      />
    </Card>
  )
}
