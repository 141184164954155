import styles from './styled.module.scss';
import clsx from "clsx";
import {useState, useEffect, useRef, ReactNode} from "react";
import {TableHeaderFilterContent} from "../TableHeaderFilterContent/TableHeaderFilterContent";
import {isNumber, isObject, isString} from "lodash";
import {formatDate as formatDateToUserFormat} from "../../../../../lib/utils/date-utils/formatDate";
import { ReactComponent as CloseIcon } from '../../../../../../assets/icons/v2/ic-close.svg'

export type HeaderCol = {
  id: string | number;
  label?: string;
  filterType?: TABLE_FILTERS_VARIANTS;
  defaultText?: string;
  defaultTextIcon?: ReactNode;
  hideDefaultTextIconAfterSelectedValue?: boolean;
  checkboxes?: Array<{ text: string; value: string }>;
  sort?: boolean;
  postfix?: ReactNode;
  position?: 'top' | 'bottom' | 'center' | 'right';
};

export enum TABLE_FILTERS_VARIANTS {
  TEXT_FIELD,
  CHECKBOXES,
  RANGE_SELECTOR,
  SEARCH,
  CALENDAR,
}

export type TableHeaderProps = {
  cols: HeaderCol[]
  filters: Record<string, any>
  handleChangeFilters: (colId: string, value: unknown) => void
  gridColWidthsStyles: Record<string, string>;
};

const COLS_WITH_CUSTOM_FILTER_WIDTH = {
  time: '440px'
} as Record<string, string>;


export const TableHeader = ({ cols, filters, handleChangeFilters, gridColWidthsStyles }: TableHeaderProps) => {
  const [openedColFilter, setOpenedColFilter] = useState<Record<string, any> | null>(null);
  const colRefs = useRef<Array<HTMLDivElement | null>>([]);
  const filterRef = useRef<HTMLDivElement | null>(null);
  const headerRef = useRef<HTMLDivElement | null>(null);
  const blockCloseFilterRef = useRef<boolean>(false);

  const handleFilterClick = (col: Record<string, any>, index: number) => {
    if (openedColFilter === col) {
      closeFilter();
      return;
    }

    setOpenedColFilter(col);
  };

  const closeFilter = () => {
    setOpenedColFilter(null);
  };

  const handleGlobalClick = (event: MouseEvent) => {
    if (!blockCloseFilterRef.current) {
      const clickTarget = event.target as Node;

      if (
        !filterRef?.current?.contains(clickTarget) &&
        !colRefs.current.some((ref) => ref && ref.contains(clickTarget))
      ) {
        closeFilter();
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleGlobalClick);

    return () => {
      document.removeEventListener("mousedown", handleGlobalClick);
    };
  }, []);

  useEffect(() => {
    if (openedColFilter) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [openedColFilter]);

  // TODO: NEEDS A HUGE REFACTORING

  const formatDate = (calendarSelectedValue: { from: string, to: string }) => {
    const { from, to } = calendarSelectedValue;

    if (from && to) {
      return `From ${formatDateToUserFormat(from)} to ${formatDateToUserFormat(to)}`;
    }

    if (from) {
      return `From ${formatDateToUserFormat(from)}`
    }

    if (to) {
      return `To ${formatDateToUserFormat(to)}`
    }

    return '';
  }

  const TableHeaderFilterRow = ({ col, index }: { col: HeaderCol, index: number }) => {
    const currentFilter = filters[col.id];
    const currentFilterIsString = isString(currentFilter);

    const SelectedValueContent = () => {
      if (currentFilterIsString) {
        return (
          <>
            {currentFilter}
          </>
        );
      }

      if (Array.isArray(currentFilter) && currentFilter.length > 0) {
        if (currentFilter.includes('All')) {
          return <>{'All'}</>;
        } else {
          return <>{`${col.defaultText} (${currentFilter.length})`}</>;
        }
      }

      if (isObject(currentFilter) && currentFilter) {
        return (
          <>
            {formatDate({
              // @ts-ignore
              from: currentFilter?.from,
              // @ts-ignore
              to: currentFilter?.to,
            })}
          </>
        )
      }

      return <>{col.defaultText}</>;
    }

    return (
      <div
        className={clsx(
          styles.Col__FilterRow,
          styles.OverflowedText,
          col.filterType === TABLE_FILTERS_VARIANTS.TEXT_FIELD && styles.Col__FilterRow_textField
        )}
        onClick={() => handleFilterClick(col, index)}
      >
        {col?.defaultText && typeof col.id === 'string' && (
          <div
            className={styles.OverflowedText}
            ref={(el) => (colRefs.current[index] = el)}
          >
            <SelectedValueContent />
          </div>
        )}
        {col.defaultTextIcon
          ? !(col.hideDefaultTextIconAfterSelectedValue && filters[col.id]) && (
            <div>
              {col.defaultTextIcon}
            </div>
          )
          : currentFilterIsString
            ? (
              <div
                className={styles.IconContainer}
                onClick={(e) => {
                  handleChangeFilters(String(col.id), null)
                  e.stopPropagation()
                }}
              >
                <CloseIcon />
              </div>
            )
            : <></>
        }
      </div>
    )
  }

  return (
    <div className={styles.TableHeader} ref={headerRef}>
      <div
        className={styles.Cols}
        style={gridColWidthsStyles}
      >
        {cols.map((col, index) => {
          const hasFilterType = isNumber(col.filterType);

          // @ts-ignore
          return (
            <div
              // @ts-ignore
              key={col.label}
              className={clsx(
                styles.Col,
              )}
            >
              {col?.postfix && (
                <div className={styles.Postfix}>{col.postfix}</div>
              )}
              <div
                className={clsx(
                  styles.Col__Name,
                  col?.sort && styles.Col__Name_sort,
                  !hasFilterType && styles.Col__Name_withoutFilter
               )}
              >
                <span>{col.label}</span>
              </div>

              {hasFilterType && (
                <TableHeaderFilterRow
                  col={col}
                  index={index}
                />
              )}

              {openedColFilter?.id === col.id && (
                <div
                  ref={filterRef}
                  className={styles.Filter}
                  style={{
                    width: COLS_WITH_CUSTOM_FILTER_WIDTH[openedColFilter.id]
                  }}
                >
                  <TableHeaderFilterContent
                    col={openedColFilter}
                    filterValue={filters[openedColFilter.id]}
                    handleChangeFilters={handleChangeFilters}
                    blockCloseFilterRef={blockCloseFilterRef}
                  />
                </div>
              )}
            </div>
          )
        })}
      </div>
    </div>
  );
};
