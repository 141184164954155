import React, { FC, useEffect } from 'react'
import clsx from 'clsx'
import { defineMessages, useIntl } from 'react-intl'
import { useDeleteMailingSubmodal } from '../../../../../_hooks/use-bool-action-submodal/templates/useDeleteMailingSubmodal'
import { CommonButtonMessages } from '../../../../../../../config/intl/common-messages/common-button-messages/CommonButtonMessages'
import { SMS_STATUSES } from '../../../../../../../pages/lk/subpages/sms/components/SmsStatus/constants/SmsStatuses'
import { MailingGetResponse } from '../../../../../../../sdk/datagates/types/mailing/_crud/get'
import { formatPhoneNumber } from '../../../../../../../sdk/formatters/format-phone-number'
import { useSmses } from '../../../../../../../sdk/hooks/use-smses/useSmses'
import { Button } from '../../../../../../../shared/ui-kit-2/inputs/button/Button'
import { LoadingContent } from '../../../../../../loading-content/LoadingContent'
import styles from './styles.module.scss'
import {useCalls} from "../../../../../../../sdk/hooks/use-calls/useCalls";
import {CALL_STATUSES} from "../../../../../../../sdk/hooks/use-call-statuses/constants/CallStatuses";
import {
  tableFiltersToUrlFilters
} from "../../../../../../../shared/lib/utils/table-filters-to-url-filters/tableFiltersToUrlFilters";
import {WavSurfer, WavSurferThemes} from "../../../../../../../shared/ui-kit-2/components/wav-surfer/WavSurfer";


interface SmsMailingEndedContentProps {
  mailing: MailingGetResponse
  onDeleteMailing: () => Promise<void>
  forceAction: 'delete' | 'cancel' | null
}

const CallsMailingEndedContentMessages = defineMessages({
  overviewTitle: {
    id: 'CallsMailingEndedContentMessages.overviewTitle',
    defaultMessage: 'Calls ended: {count}',
  },
  deliveredCount: {
    id: 'CallsMailingEndedContentMessages.deliveredCount',
    defaultMessage: 'Received: {count}',
  },
  notDeliveredCount: {
    id: 'CallsMailingEndedContentMessages.notDeliveredCount',
    defaultMessage: 'Not Received: {count}',
  },
  received: {
    id: 'CallsMailingEndedContentMessages.received',
    defaultMessage: 'Received',
  },
  unreceived: {
    id: 'CallsMailingEndedContentMessages.unreceived',
    defaultMessage: 'Not Received',
  },
})

export const CallsMailingEndedContent: FC<SmsMailingEndedContentProps> = (
  props,
) => {
  const { mailing, onDeleteMailing, forceAction } = props

  const intl = useIntl()
  const { setIsDeleteSubmodalOpen, deleteSubmodalComponent } =
    useDeleteMailingSubmodal({ onDeleteMailing })

  const mailingHistoryId = mailing.mailingHistory?.mailingHistoryId

  const {
    calls: backCalls,
    loading: callsLoading,
    totalCount: callsTotalCount
  } = useCalls({
    filters: tableFiltersToUrlFilters([
      {
        name: 'page',
        value: '0',
      },
      {
        name: 'limit',
        value: '0'
      },
      {
        name: 'mailing_history_ids[]',
        value: [String(mailingHistoryId ?? mailing.mailingId)]
      },
      {
        name: 'call_type_ids[]',
        value: ['4']
      }
    ])
  });

  const calls = [
    ...backCalls,
    /*
    {
      callId: 5,
      numberReceiver: '+77059647933',
      callStatusId: CALL_STATUSES.RECEIVED_STATUS,
      contact: {
        name: 'Test name'
      },
      file: {
        cdnUrl: 'https://file-examples.com/storage/fef09afdeb679921392cb49/2017/11/file_example_WAV_1MG.wav'
      }
    },
    {
      callId: 6,
      numberReceiver: '65465466',
      callStatusId: CALL_STATUSES.RECEIVED_STATUS,
      contact: {
        name: 'Test name 2'
      },
      file: {
        cdnUrl: 'https://file-examples.com/storage/fef09afdeb679921392cb49/2017/11/file_example_WAV_1MG.wav'
      }
    }
    */
  ]

  useEffect(() => {
    if (forceAction) setIsDeleteSubmodalOpen(true)
  }, [])

  const overviewContent = (
    <>
      <div className={styles.OverviewHeader}>
        <div className={styles.OverviewHeader__Title}>
          {intl.formatMessage(CallsMailingEndedContentMessages.overviewTitle, {
            count: callsTotalCount,
          })}
        </div>

        <div className={styles.OverviewHeader__Statuses}>
          <div className={styles.OverviewStatusItem}>
            <div className={styles.OverviewStatusItem__GreenCircle} />
            <div className={styles.OverviewStatusItem__Text}>
              {intl.formatMessage(
                CallsMailingEndedContentMessages.deliveredCount,
                {
                  count: calls.filter(
                    (call) => call.callStatusId === CALL_STATUSES.RECEIVED_STATUS,
                  ).length,
                },
              )}
            </div>
          </div>

          <div className={styles.OverviewStatusItem}>
            <div className={styles.OverviewStatusItem__RedCircle} />
            <div className={styles.OverviewStatusItem__Text}>
              {intl.formatMessage(
                CallsMailingEndedContentMessages.notDeliveredCount,
                {
                  count: calls.filter((call) =>
                    [
                      CALL_STATUSES.NOT_RECEIVED_STATUS,
                      CALL_STATUSES.CANCELED_STATUS
                    ].includes(call.callStatusId),
                  ).length,
                },
              )}
            </div>
          </div>
        </div>
      </div>

      {calls.length > 0 && (
        <div className={styles.OverviewBody}>
          {calls.map((call) => (
            <div key={call.callId} className={styles.CallStatus}>
              <div className={styles.CallStatus__Top}>
                <div className={styles.CallStatus__Receiver}>
                  {call?.contact?.name && (
                    <div className={styles.CallStatus__Receiver__Contact}>
                      {call?.contact.name}
                    </div>
                  )}
                  <div className={styles.CallStatus__Receiver__Phone}>
                    {formatPhoneNumber(call.numberReceiver)}
                  </div>
                </div>
                <div
                  className={clsx(
                    call.callStatusId === CALL_STATUSES.RECEIVED_STATUS
                      ? styles.CallStatus__Green
                      : styles.CallStatus__Red,
                  )}
                >
                  {
                    call.callStatusId === CALL_STATUSES.RECEIVED_STATUS
                      ? intl.formatMessage(CallsMailingEndedContentMessages.received)
                      : intl.formatMessage(CallsMailingEndedContentMessages.unreceived)
                  }
                </div>
              </div>
              <div className={styles.CallStatus__Audio}>
                {call?.file?.cdnUrl && call.callStatusId === CALL_STATUSES.RECEIVED_STATUS && (
                  <WavSurfer
                    audioSource={call.file.cdnUrl}
                    theme={WavSurferThemes.black}
                    showDuration
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  )

  return (
    <>
      <div className={styles.Container}>
        <div className={styles.Overview}>
          <LoadingContent
            loading={callsLoading}
            content={overviewContent}
          />
        </div>

        <Button
          text={intl.formatMessage(CommonButtonMessages.delete)}
          variant={'danger'}
          icon={'trash'}
          onClick={() => setIsDeleteSubmodalOpen(true)}
          additionalClassNames={[styles.Button]}
        />
      </div>
      {deleteSubmodalComponent}
    </>
  )
}
