import styles from "../calls-auto-response-rules/styles.module.scss";
import ControlHeader from "../../../../../../shared/ui-kit-3/components/ControlHeader/ControlHeader";
import { ReactComponent as PlusIcon } from '../../../../../../assets/icons/v2/ic-plus.svg';
import {Card} from "../../../../../../shared/ui-kit-2/data-display/card/Card";
import React, {useEffect, useMemo} from "react";
import {
  DEFAULT_COLS,
  DEFAULT_COLS_VARIANTS
} from "../../../../../../shared/ui-kit-2/data-display/table/contants/defaultCols";
import {
  HeaderCol,
  TABLE_FILTERS_VARIANTS
} from "../../../../../../shared/ui-kit-2/data-display/table/components/TableHeader/TableHeader";
import {useTableFilters} from "../../../../../../shared/ui-kit-2/data-display/table/hooks/useTableFilters";
import {
  tableFiltersToUrlFilters
} from "../../../../../../shared/lib/utils/table-filters-to-url-filters/tableFiltersToUrlFilters";
import {RowGroupType, Table} from "../../../../../../shared/ui-kit-2/data-display/table/Table";
import {formatDate} from "../../../../../../shared/lib/utils/date-utils/formatDate";
import {formatPhoneNumber} from "../../../../../../sdk/formatters/format-phone-number";
import clsx from "clsx";
import {WavSurfer, WavSurferThemes} from "../../../../../../shared/ui-kit-2/components/wav-surfer/WavSurfer";
import { ReactComponent as ArrowRightIcon } from '../../../../../../assets/icons/arrow-right.svg'
import { ReactComponent as TrashIcon } from '../../../../../../assets/icons/trash.svg'
import { ReactComponent as CaretRightIcon } from '../../../../../../assets/icons/caret-right.svg'
import {useMailings} from "../../../../../../sdk/hooks/use-mailings/useMailings";
import {
  formatDateToTableHeader
} from "../../../../../../shared/lib/utils/format-date-to-table-header/formatDateToTableHeader";
import {groupArrayByDate} from "../../../../../../shared/lib/utils/group-array-by-date/groupArrayByDate";
import {useModal} from "../../../../../../app/hooks/useModal";
import {MODAL_TYPES} from "../../../../../../layouts/modals/ModalsProvider";
import {useMailingStatuses} from "../../../../../../sdk/hooks/use-mailing-statuses/useMailingStatuses";
import {DialogVersion} from "../../../../../../store/reducers/dialog/types";
import {useDialog} from "../../../../../../app/hooks/useDialog";
import {useWebSockets} from "../../../../../../shared/lib/hooks/useWebSockets";
import {WEB_SOCKETS_EVENTS_VALUES} from "../../../../../../shared/lib/constants/WEB_SOCKETS_EVENTS_VALUES";


export const CallsAutoDialer = () => {
  const { handleOpenModal, type } = useModal()
  const { getMailingStatusStickerComponent } = useMailingStatuses()
  const { handleOpenDialog, handleHideDialog } = useDialog()
  const { webSocketsEventData } = useWebSockets({
    events: [
      WEB_SOCKETS_EVENTS_VALUES.mailing_created,
      WEB_SOCKETS_EVENTS_VALUES.mailing_status_changed
    ]
  });

  const COLS = {
    name: 'name',
    modemName: 'modemName',
    receivers: 'receivers'
  }

  const headerCols = [
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.time],
    {
      id: COLS.name,
      filterType: TABLE_FILTERS_VARIANTS.TEXT_FIELD,
      label: 'Name',
      defaultText: 'Enter the name',
    },
    {
      id: COLS.modemName,
      filterType: TABLE_FILTERS_VARIANTS.TEXT_FIELD,
      label: 'Modem name',
      defaultText: 'Enter the name',
    },
    {
      id: COLS.receivers,
      label: 'Receivers',
    },
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.audioFile],
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.callStatus],
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.actions],
  ] as HeaderCol[];

  const {
    filters,
    handleChangeFilters,
    handlePageChange,
    debouncedFilters,
  } = useTableFilters({
    queryIds: [
      ...headerCols.map(({ id }) => id),
      'page',
      'limit',
    ],
    defaultFilterValues: [
      {
        name: 'page',
        value: 1
      },
      {
        name: 'limit',
        value: 50
      }
    ]
  })

  const currentFilters = useMemo(() => tableFiltersToUrlFilters([
    { name: 'page', value: debouncedFilters?.page - 1 },
    { name: 'limit', value: debouncedFilters?.limit },
    { name: 'from', value: debouncedFilters?.time?.from },
    { name: 'to', value: debouncedFilters?.time?.to },
    { name: 'name', value: debouncedFilters?.name },
    { name: 'dongle_name', value: debouncedFilters?.modemName },
  ]), [debouncedFilters])

  const {
    mailings,
    totalCount: mailingsTotalCount,
    handleFetch: handleFetchMailings,
    loading: mailingsLoading,
    handleDeleteMailing,
  } = useMailings({
    filters: currentFilters
  })

  const mailingGroups = useMemo(() => {
    return groupArrayByDate(mailings, 'createdAt');
  }, [mailings]);

  useEffect(() => {
    const shouldFetch =
      (webSocketsEventData?.event === WEB_SOCKETS_EVENTS_VALUES.mailing_status_changed &&
        mailings.some(({ mailingId }) => String(mailingId) === String(webSocketsEventData?.entityId))) ||
      (filters?.page === 1 && !loading);

    if (shouldFetch) {
      handleFetchMailings();
    }
  }, [webSocketsEventData]);

  const onDeleteMailing = async (id: number) => {
    handleOpenDialog({
      version: DialogVersion.v2,
      props: {
        title: 'Delete autodialer?',
        subtitle: 'This action cannot be undone',
        subtitleIsAlert: true,
        cancel: {
          text: 'Cancel',
          onClick: () => handleHideDialog()
        },
        submit: {
          icon: 'trash',
          text: 'Delete',
          onClick: async () => {
            handleHideDialog();
            await handleDeleteMailing(id);
            await handleFetchMailings();
          }
        },
      }
    })
  }

  const loading = mailingsLoading && !type

  const rowGroups = useMemo(() => {
    return mailingGroups.map<RowGroupType>(([date, mailingsInGroup]) => (
      {
        groupHeader: <>{formatDateToTableHeader(date)}</>,
        rows: mailingsInGroup.map(mailing => {
          return [
            <div className={styles.Time}>
              {formatDate(mailing.createdAt, null, 'time')}
            </div>,
            <div>{mailing.name}</div>,
            <div className={styles.Contact}>
              <div className={styles.Contact__container}>
                <div
                  className={clsx(
                    styles.Contact__name,
                    styles.Contact__name_device,
                  )}
                >
                  {mailing.dongle.name}
                </div>
                <div className={styles.Contact__phone}>
                  {formatPhoneNumber(mailing.dongle.number)}
                </div>
              </div>
              <ArrowRightIcon />
            </div>,
            <div className={styles.Contact}>
              <div className={styles.Contact__container}>
                <div className={styles.Contact__phone}>
                  {`${mailing.countMailingPhones} ${mailing.countMailingPhones === 1 ? 'Numbers' : 'Number'}`}
                </div>
              </div>
            </div>,
            <div>
              {mailing?.file?.cdnUrl && (
                <WavSurfer
                  audioSource={mailing.file.cdnUrl}
                  audioWavesWidth={61}
                  showDuration={false}
                  theme={WavSurferThemes.black}
                />
              )}
            </div>,
            <div>{getMailingStatusStickerComponent(mailing?.mailingStatusId)}</div>,
            <div className={styles.Actions}>
              <button
                className={styles.Action}
                onClick={() => onDeleteMailing(mailing.mailingId)}
              >
                <TrashIcon />
              </button>
              <button
                className={styles.Action}
                onClick={() => handleOpenModal({
                  type: MODAL_TYPES.CALLS_MAILING,
                  props: {
                    mailingId: mailing.mailingId,
                    handleFetchMailings,
                  }})
                }
              >
                <CaretRightIcon />
              </button>
            </div>,
          ]
        })
      }
    ))
  }, [mailings]);

  return (
    <Card additionalClassNames={[styles.CardAdditional]}>
      <div className={styles.CallAutoResponseRules}>
        <ControlHeader
          title={'Autodialer'}
          counter={{
            min: mailings.length,
            max: mailingsTotalCount
          }}
          actions={[{
            variant: 'greenFilled',
            size: 'md',
            text: 'Create',
            prefix: <PlusIcon />,
            onClick: () => handleOpenModal({
              type: MODAL_TYPES.CALLS_MAILING,
              props: {
                handleFetchMailings,
              }
            }),
          }]}
          loading={loading}
        />

        <Table
          headerCols={headerCols}
          filters={filters}
          handleChangeFilters={handleChangeFilters}
          name={'CallAutoResponse'}
          rowGroups={rowGroups}
          currentPage={filters.page}
          totalCount={mailingsTotalCount}
          onPageChange={handlePageChange}
          loading={loading}
          columnWidths={['125px', 2, 2, 2, '200px', 1, 1]}
          itemsPerPage={filters.limit}
        />
      </div>
    </Card>
  )
}