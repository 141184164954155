import {ButtonProps} from "./Button.types";
import clsx from "clsx";
import styles from './styles.module.scss';

const Button = ({ variant, text, size, prefix, postfix, onClick, className = '', disabled }: ButtonProps) => {
  return (
    <button
      className={clsx(
        styles.Button,
        size === 'md' && styles.Button_md,
        size === 'xl' && styles.Button_xl,
        variant === 'greenOutlined' && styles.Button_greenOutlined,
        variant === 'greenFilled' && styles.Button_greenFilled,
        variant === 'white' && styles.Button_white,
        variant === 'greyOutlined' && styles.Button_greyOutlined,
        disabled && styles.Button_disabled,
        className
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {prefix && (
        <div className={styles.Prefix}>
          {prefix}
        </div>
      )}
      <div className={styles.Text}>
        {text}
      </div>
      {postfix && (
        <div className={styles.Prefix}>
          {postfix}
        </div>
      )}
    </button>
  )
};

export default Button;