import { defineMessages, useIntl } from 'react-intl'
import { CommonButtonMessages } from '../../../../../config/intl/common-messages/common-button-messages/CommonButtonMessages'
import { useBoolActionSubmodal } from '../useBoolActionSubmodal'

interface UseCancelMailingSubmodalProps {
  onCancelMailing: () => Promise<void>
}

const UseCancelMailingSubmodalMessages = defineMessages({
  actionName: {
    id: 'UseCancelMailingSubmodalMessages.actionName',
    defaultMessage: 'cancel',
  },
  actionItem: {
    id: 'UseCancelMailingSubmodalMessages.actionItem',
    defaultMessage: 'mailing',
  },
  description: {
    id: 'UseCancelMailingSubmodalMessages.description',
    defaultMessage: 'This action cannot be undone',
  },
})

export const useCancelMailingSubmodal = (
  props: UseCancelMailingSubmodalProps,
) => {
  const { onCancelMailing } = props

  const intl = useIntl()

  const {
    setIsOpen: setIsCancelSubmodalOpen,
    component: cancelSubmodalComponent,
  } = useBoolActionSubmodal({
    handleAction: onCancelMailing,
    actionName: intl.formatMessage(UseCancelMailingSubmodalMessages.actionName),
    itemName: intl.formatMessage(UseCancelMailingSubmodalMessages.actionItem),
    description: intl.formatMessage(
      UseCancelMailingSubmodalMessages.description,
    ),
    danger: true,
    actionButtonText: intl.formatMessage(CommonButtonMessages.yesCancel),
    actionButtonIcon: 'trash',
  })

  return {
    setIsCancelSubmodalOpen,
    cancelSubmodalComponent,
  }
}
