import { useJivoChat } from '../integrations/jivo-chat/hooks/useJivoChat'
import { Header } from '../layouts/header/Header'
import { MobileHeader } from '../layouts/mobileHeader/MobileHeader'
import { Sidebar } from '../layouts/sidebar/Sidebar'
import { TabBar } from '../layouts/tabBar/TabBar'
import { RootRoutes } from '../routing/RootRoutes'
import {
  ScreenDevices,
  useLessThanDeviceScreen,
} from '../shared/lib/hooks/useLessThanDeviceScreen'
import { Loader } from '../shared/ui-kit/data-display/loader/Loader'
import { useAppSelector } from '../store'
import { useAppFirstRender } from './hooks/useAppFirstRender'
import { withProviders } from './providers/withProviders'
import styles from './styles.module.scss'
import SoundPlayer from "../shared/global-components/SoundPlayer";

const App = () => {
  useAppFirstRender()
  const { isAuthChecked } = useAppSelector((state) => state.user)
  const isMobile = useLessThanDeviceScreen(ScreenDevices.MOBILE_LK)
  // const jivoChat = useJivoChat()

  if (!isAuthChecked) {
    return (
      <div className={styles.appLoader} />
    )
  }

  return (
    <div className={styles.appWrapper}>
      {isMobile ? <MobileHeader /> : <Header />}

      {isMobile ? <TabBar /> : <Sidebar />}

      <RootRoutes />

      {/*<Footer />*/}
      {/*jivoChat*/}
      <SoundPlayer />
    </div>
  )
}

export default withProviders(App)
