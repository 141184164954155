import React, { FC, useEffect, useRef } from 'react'
import clsx from 'clsx'
import Flatpickr from 'react-flatpickr'
import styles from './styles.module.scss'
import 'flatpickr/dist/themes/light.css'
import { ReactComponent as CalendarIcon } from './assets/icons/calendar.svg'
import { ReactComponent as ClearIcon } from './assets/icons/clear.svg'
import {defineMessages, useIntl} from "react-intl";
import { Russian as RussianLocale } from 'flatpickr/dist/l10n/ru.js'
import {useSiteVersion} from "../../../../app/hooks/useSiteVersion";

export interface DatepickerProps extends React.HTMLProps<HTMLInputElement> {
  label?: string
  error?: string
  helperText?: string
  width?: number
  withoutSeconds?: boolean
  minDateToday?: boolean
  defaultDate?: Date
  testId?: string
}

const DatePickerMessages = defineMessages({
  placeholder: {
    id: 'DatePickerMessages.placeholder',
    defaultMessage:
      'Select date',
  },
})


export const Datepicker: FC<DatepickerProps> = (props) => {
  const flatpickrRef = useRef<any>(null)
  const intl = useIntl()
  const { siteVersion } = useSiteVersion()

  const {
    label,
    error,
    helperText,
    width,
    withoutSeconds = true,
    minDateToday = false,
    defaultDate,
    testId,
    ...otherProps
  } = props

  const clearDatepicker = () => {
    flatpickrRef.current.flatpickr.clear()
    otherProps.onChange &&
      (otherProps.onChange as (event: any) => void)(undefined)
  }

  const openCalendar = () => flatpickrRef.current.flatpickr.open()

  useEffect(() => {
    if (!otherProps.value) {
      clearDatepicker()
    }
  }, [otherProps.value])

  return (
    <div className={styles.Container}>
      {label && <div className={styles.Label}>{label}</div>}

      <div className={styles.InputContainer}>
        <Flatpickr
          ref={flatpickrRef}
          className={clsx(styles.Datepicker, error && styles.Datepicker__error)}
          data-test-id={testId}
          options={{
            enableTime: true,
            dateFormat: withoutSeconds ? 'd.m.Y H:i' : 'd.m.Y H:i:S',
            time_24hr: true,
            disableMobile: true,
            enableSeconds: !withoutSeconds,
            onChange: otherProps.onChange as (event: any) => void,
            allowInput: false,
            minDate: minDateToday ? 'today' : '',
            defaultDate: defaultDate,
            ...(siteVersion === 'ru' ? { locale: RussianLocale } : {})
          }}
          placeholder={otherProps.placeholder ?? intl.formatMessage(DatePickerMessages.placeholder)}
        />

        <div className={styles.Icons}>
          <CalendarIcon
            onClick={openCalendar}
            className={styles.flatpickr_icons_calendar}
          />
          {!!otherProps.value && (
            <ClearIcon
              onClick={clearDatepicker}
              className={styles.flatpickr_icons_clear}
            />
          )}
        </div>
      </div>
    </div>
  )
}
