import React, {ReactNode} from "react";
import {TABLE_FILTERS_VARIANTS} from "../components/TableHeader/TableHeader";
import { ReactComponent as CaretDownIcon } from '../../../../../assets/icons/v2/ic-caret-down.svg';
import { ReactComponent as ClockIcon } from '../../../../../assets/icons/v2/ic-clock.svg';
import { ReactComponent as ArrowRightIcon } from '../../../../../assets/icons/arrow-right.svg'
import styles from "./styles.module.scss";
import {CALL_STATUSES} from "../../../../../sdk/hooks/use-call-statuses/constants/CallStatuses";
import {SMS_STATUSES} from "../../../../../pages/lk/subpages/sms/components/SmsStatus/constants/SmsStatuses";
import {MAILING_STATUSES} from "../../../../../sdk/hooks/use-mailing-statuses/constants/MailingStatuses";


export const DEFAULT_COLS_VARIANTS = {
  arrow: 'arrow',
  id: 'id',
  time: 'time',
  callStatus: 'callStatus',
  smsStatus: 'smsStatus',
  mailingStatus: 'mailingStatus',
  sender: 'sender',
  recipient: 'recipient',
  caller: 'caller',
  receiver: 'receiver',
  duration: 'duration',
  actions: 'actions',
  audioFile: 'audioFile',
  audioAnswer: 'audioAnswer',
  phone: 'phone',
  subscription: 'subscription',
  network: 'network',
  textMessage: 'textMessage',
  smsc: 'smsc',
}

export const IconContainer = ({ children }: { children: ReactNode }) => (
  <div className={styles.IconContainer}>
    {children}
  </div>
);

export const DEFAULT_COLS = {
  [DEFAULT_COLS_VARIANTS.arrow]: {
    id: DEFAULT_COLS_VARIANTS.arrow,
    label: '',
  },
  [DEFAULT_COLS_VARIANTS.id]: {
    id: DEFAULT_COLS_VARIANTS.id,
    filterType: TABLE_FILTERS_VARIANTS.TEXT_FIELD,
    label: 'ID',
    defaultText: 'Enter ID'
  },
  [DEFAULT_COLS_VARIANTS.time]: {
    id: DEFAULT_COLS_VARIANTS.time,
    label: 'Time',
    filterType: TABLE_FILTERS_VARIANTS.CALENDAR,
    defaultText: 'Select',
    defaultTextIcon: (
      <IconContainer>
        <ClockIcon />
      </IconContainer>
    ),
    hideDefaultTextIconAfterSelectedValue: true,
  },
  [DEFAULT_COLS_VARIANTS.callStatus]: {
    id: DEFAULT_COLS_VARIANTS.callStatus,
    // filterType: TABLE_FILTERS_VARIANTS.CHECKBOXES,
    label: 'Status',
    defaultText: 'Select',
    defaultTextIcon: (
      <IconContainer>
        <CaretDownIcon />
      </IconContainer>
    ),
    checkboxes: [
      { text: 'All', value: 'All' },
      { text: 'Created', value: CALL_STATUSES.CREATED_STATUS },
      { text: 'Successful', value: CALL_STATUSES.RECEIVED_STATUS },
    ]
  },
  [DEFAULT_COLS_VARIANTS.smsStatus]: {
    id: DEFAULT_COLS_VARIANTS.smsStatus,
    filterType: TABLE_FILTERS_VARIANTS.CHECKBOXES,
    label: 'Status',
    defaultText: 'Select',
    defaultTextIcon: (
      <IconContainer>
        <CaretDownIcon />
      </IconContainer>
    ),
    checkboxes: [
      { text: 'All', value: 'All' },
      { text: 'Created', value: SMS_STATUSES.CREATED },
      { text: 'Delivered', value: SMS_STATUSES.DELIVERED },
      { text: 'Failed', value: SMS_STATUSES.DELIVERY_FAIL },
      { text: 'Planned', value: SMS_STATUSES.ENQUEUED },
    ]
  },
  [DEFAULT_COLS_VARIANTS.mailingStatus]: {
    id: DEFAULT_COLS_VARIANTS.mailingStatus,
    filterType: TABLE_FILTERS_VARIANTS.CHECKBOXES,
    label: 'Status',
    defaultText: 'Select',
    defaultTextIcon: (
      <IconContainer>
        <CaretDownIcon />
      </IconContainer>
    ),
    checkboxes: [
      { text: 'All', value: 'All' },
      { text: 'Draft', value: MAILING_STATUSES.DRAFT },
      { text: 'Ended', value: MAILING_STATUSES.ENDED },
      { text: 'Planned', value: MAILING_STATUSES.PLANNED },
      { text: 'Cancelled', value: MAILING_STATUSES.CANCELLED },
      { text: 'In process', value: MAILING_STATUSES.IN_PROCESS },
    ]
  },
  [DEFAULT_COLS_VARIANTS.sender]: {
    id: DEFAULT_COLS_VARIANTS.sender,
    filterType: TABLE_FILTERS_VARIANTS.TEXT_FIELD,
    label: 'Sender',
    defaultText: 'Enter sender',
    postfix: (
      <ArrowRightIcon />
    ),
  },
  [DEFAULT_COLS_VARIANTS.recipient]: {
    id: DEFAULT_COLS_VARIANTS.recipient,
    filterType: TABLE_FILTERS_VARIANTS.TEXT_FIELD,
    label: 'Recipient',
    defaultText: 'Enter recipient',
  },
  [DEFAULT_COLS_VARIANTS.caller]: {
    id: DEFAULT_COLS_VARIANTS.caller,
    filterType: TABLE_FILTERS_VARIANTS.TEXT_FIELD,
    label: 'Caller',
    defaultText: 'Enter caller',
    postfix: (
      <ArrowRightIcon />
    ),
  },
  [DEFAULT_COLS_VARIANTS.receiver]: {
    id: DEFAULT_COLS_VARIANTS.receiver,
    filterType: TABLE_FILTERS_VARIANTS.TEXT_FIELD,
    label: 'Receiver',
    defaultText: 'Enter receiver',
  },
  [DEFAULT_COLS_VARIANTS.duration]: {
    id: DEFAULT_COLS_VARIANTS.duration,
    filterType: TABLE_FILTERS_VARIANTS.CHECKBOXES,
    label: 'Duration',
    defaultText: 'Select call duration',
    defaultTextIcon: (
      <IconContainer>
        <CaretDownIcon />
      </IconContainer>
    ),
    checkboxes: [
      { text: 'All', value: 'All' },
      { text: 'Less a minute', value: '1' },
      { text: '1-3 min', value: '2' },
      { text: '3-5 min', value: '3' },
      { text: 'More 5 min', value: '4' },
    ]
  },
  [DEFAULT_COLS_VARIANTS.actions]: {
    id: DEFAULT_COLS_VARIANTS.actions,
    label: 'Actions',
    position: 'right',
  },
  [DEFAULT_COLS_VARIANTS.audioFile]: {
    id: DEFAULT_COLS_VARIANTS.audioFile,
    filterType: TABLE_FILTERS_VARIANTS.CHECKBOXES,
    label: 'Audio file',
    defaultText: 'Select',
    defaultTextIcon: (
      <IconContainer>
        <CaretDownIcon />
      </IconContainer>
    ),
    checkboxes: [
      { text: 'Yes', value: '1' },
      { text: 'No', value: '0' },
    ]
  },
  [DEFAULT_COLS_VARIANTS.audioAnswer]: {
    id: DEFAULT_COLS_VARIANTS.audioAnswer,
    filterType: TABLE_FILTERS_VARIANTS.CHECKBOXES,
    label: 'Answer',
    defaultText: 'Select',
    defaultTextIcon: (
      <IconContainer>
        <CaretDownIcon />
      </IconContainer>
    ),
    checkboxes: [
      { text: 'Yes', value: '1' },
      { text: 'No', value: '0' },
    ]
  },
  [DEFAULT_COLS_VARIANTS.phone]: {
    id: DEFAULT_COLS_VARIANTS.phone,
    filterType: TABLE_FILTERS_VARIANTS.TEXT_FIELD,
    label: 'Phone',
    defaultText: 'Enter the phone',
  },
  [DEFAULT_COLS_VARIANTS.subscription]: {
    id: DEFAULT_COLS_VARIANTS.subscription,
    filterType: TABLE_FILTERS_VARIANTS.CHECKBOXES,
    label: 'Subscription',
    defaultText: 'Select',
    defaultTextIcon: (
      <IconContainer>
        <CaretDownIcon />
      </IconContainer>
    ),
    checkboxes: [
      { text: 'Active', value: '1' },
      { text: 'Inactive', value: '0' },
    ]
  },
  [DEFAULT_COLS_VARIANTS.network]: {
    id: DEFAULT_COLS_VARIANTS.network,
    filterType: TABLE_FILTERS_VARIANTS.CHECKBOXES,
    label: 'Network',
    defaultText: 'Select',
    defaultTextIcon: (
      <IconContainer>
        <CaretDownIcon />
      </IconContainer>
    ),
    checkboxes: [
      { text: 'Online', value: '1' },
      { text: 'Offline', value: '0' },
    ]
  },
  [DEFAULT_COLS_VARIANTS.textMessage]: {
    id: DEFAULT_COLS_VARIANTS.textMessage,
    filterType: TABLE_FILTERS_VARIANTS.TEXT_FIELD,
    label: 'Text',
    defaultText: 'Enter text message',
  },
  [DEFAULT_COLS_VARIANTS.smsc]: {
    id: DEFAULT_COLS_VARIANTS.smsc,
    filterType: TABLE_FILTERS_VARIANTS.TEXT_FIELD,
    label: 'SMSC',
    defaultText: 'Enter SMSC',
  },
};