
import {Table, TableColType} from "../../../../../../shared/ui-kit-2/data-display/table/Table";
import {Card} from "../../../../../../shared/ui-kit-2/data-display/card/Card";
import React, {useEffect, useMemo, useState} from "react";
import {CallType, useCalls} from "../../../../../../sdk/hooks/use-calls/useCalls";
import {
  CommonTableMessages
} from "../../../../../../config/intl/common-messages/common-table-messages/CommonTableMessages";
import {useIntl} from "react-intl";
import {formatDate} from "../../../../../../shared/lib/utils/date-utils/formatDate";
import {DialogVersion} from "../../../../../../store/reducers/dialog/types";
import {useDialog} from "../../../../../../app/hooks/useDialog";
import { ReactComponent as TrashIcon } from '../../../../../../assets/icons/trash.svg'
import { ReactComponent as ArrowRightIcon } from '../../../../../../assets/icons/arrow-right.svg'
// TODO: перенести на стили этого компонента
import styles from '../calls-auto-response-rules/styles.module.scss';
import {useCallStatuses} from "../../../../../../sdk/hooks/use-call-statuses/useCallStatuses";
import {formatPhoneNumber} from "../../../../../../sdk/formatters/format-phone-number";
import clsx from "clsx";
import _, {isNumber} from "lodash";
import {WavSurfer, WavSurferThemes} from "../../../../../../shared/ui-kit-2/components/wav-surfer/WavSurfer";
import {WEB_SOCKETS_EVENTS_VALUES} from "../../../../../../shared/lib/constants/WEB_SOCKETS_EVENTS_VALUES";
import {useWebSockets} from "../../../../../../shared/lib/hooks/useWebSockets";
import {
  DEFAULT_COLS,
  DEFAULT_COLS_VARIANTS,
  IconContainer
} from "../../../../../../shared/ui-kit-2/data-display/table/contants/defaultCols";
import {
  tableFiltersToUrlFilters
} from "../../../../../../shared/lib/utils/table-filters-to-url-filters/tableFiltersToUrlFilters";
import ControlHeader from "../../../../../../shared/ui-kit-3/components/ControlHeader/ControlHeader";
import {useTableFilters} from "../../../../../../shared/ui-kit-2/data-display/table/hooks/useTableFilters";
import {HeaderCol} from "../../../../../../shared/ui-kit-2/data-display/table/components/TableHeader/TableHeader";


export const CallsAutoResponse = () => {
  const { handleOpenDialog, handleHideDialog } = useDialog()
  const { getCallStatusStickerComponent } = useCallStatuses()
  const { webSocketsEventData } = useWebSockets({
    events: [WEB_SOCKETS_EVENTS_VALUES.call_created]
  });

  const headerCols = [
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.time],
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.callStatus],
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.caller],
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.receiver],
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.duration],
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.audioFile],
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.audioAnswer],
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.actions],
  ] as HeaderCol[];

  const {
    filters,
    handleChangeFilters,
    handlePageChange,
    debouncedFilters,
  } = useTableFilters({
    queryIds: [
      ...headerCols.map(({ id }) => id),
      'page',
      'limit',
    ],
    defaultFilterValues: [
      {
        name: 'call_type_ids',
        value: [3]
      },
      {
        name: 'page',
        value: 1
      },
      {
        name: 'limit',
        value: 50
      }
    ]
  })

  const currentFilters = useMemo(() => tableFiltersToUrlFilters([
    { name: 'call_type_ids[]', value: debouncedFilters?.call_type_ids },
    { name: 'page', value: debouncedFilters?.page - 1 },
    { name: 'limit', value: debouncedFilters?.limit },
    { name: 'from', value: debouncedFilters?.time?.from },
    { name: 'to', value: debouncedFilters?.time?.to },
    { name: 'call_type_ids[]', value: debouncedFilters?.type },
    { name: 'call_status_ids[]', value: debouncedFilters?.callStatus },
    { name: 'caller', value: debouncedFilters?.caller },
    { name: 'receiver', value: debouncedFilters?.receiver },
    { name: 'duration_presets[]', value: debouncedFilters?.duration },
    {
      name: 'is_empty_call_scenario_file_id',
      value: debouncedFilters?.audioFile && !debouncedFilters?.audioFile.includes('All')
        ? debouncedFilters.audioFile[0]
        : undefined
    },
    {
      name: 'is_empty_file_id',
      value: debouncedFilters?.audioAnswer && !debouncedFilters?.audioAnswer.includes('All')
        ? debouncedFilters.audioAnswer[0]
        : undefined
    },
  ]), [debouncedFilters])

  const {
    calls,
    totalCount,
    handleDeleteCall,
    loading,
    handleFetch: handleFetchCalls,
  } =
    useCalls({
      filters: currentFilters,
    })

  useEffect(() => {
    if (filters?.page === 1 && !loading) {
      handleFetchCalls({ hidden: true });
    }
  }, [webSocketsEventData]);

  const onDeleteCall = async (dongleId: number, callId: number) => {
    handleOpenDialog({
      version: DialogVersion.v2,
      props: {
        title: 'Delete rule?',
        subtitle: 'This action cannot be undone',
        cancel: {
          text: 'Cancel',
          onClick: () => handleHideDialog()
        },
        submit: {
          icon: 'trash',
          text: 'Delete',
          onClick: () => {
            handleDeleteCall(dongleId, callId);
            handleFetchCalls();
            handleHideDialog();
          }
        },
      }
    })
  }

  // TODO: Need import global formatTime method
  const formatTime = (seconds: number) => {
    if (!isNumber(seconds)) {
      return '';
    }

    const minutes = Math.floor(seconds / 60).toString().padStart(2, '0');
    const secs = (seconds % 60).toString().padStart(2, '0');
    return `${minutes}:${secs}`;
  };

  const rowGroups = useMemo(() => {
    if (Array.isArray(calls)) {
      return [{
        groupHeader: <></>,
        rows: calls.map(call => {
          const isDeviceSender = [CallType.OUTGOING].includes(
            call.callTypeId,
          )

          const contactSenderName = isDeviceSender
            ? call.dongleName
            : call?.contact?.name || ''

          const contactReceiverName = isDeviceSender
            ? call?.contact?.name || ''
            : call.dongleName

          return [
            <div className={styles.Time}>
              {formatDate(call.createdAt, 0, 'time')}
            </div>,
            <div>{getCallStatusStickerComponent(call.callStatusId)}</div>,
            <div className={styles.Contact}>
              <div className={styles.Contact__container}>
                <div className={styles.Contact__name}>
                  {contactSenderName}
                </div>
                <div className={styles.Contact__phone}>
                  {formatPhoneNumber(call.numberCaller)}
                </div>
              </div>
              <ArrowRightIcon />
            </div>,
            <div className={styles.Contact}>
              <div className={styles.Contact__container}>
                <div
                  className={clsx(
                    styles.Contact__name,
                    styles.Contact__name_device,
                  )}
                >
                  {contactReceiverName}
                </div>
                <div className={styles.Contact__phone}>
                  {formatPhoneNumber(call.numberReceiver)}
                </div>
              </div>
            </div>,
            <div>
              {formatTime(call.duration)}
            </div>,
            <div>
              {call?.file?.cdnUrl && (
                <WavSurfer
                  audioSource={call.callScenarioFile.cdnUrl}
                  audioWavesWidth={61}
                  showDuration={false}
                  theme={WavSurferThemes.black}
                />
              )}
            </div>,
            <div>
              {call?.callScenarioFile?.cdnUrl && (
                <WavSurfer
                  audioSource={call.file.cdnUrl}
                  audioWavesWidth={61}
                  showDuration={false}
                  theme={WavSurferThemes.black}
                />
              )}
            </div>,
            <div className={styles.Actions}>
              <button
                className={styles.Action}
                onClick={() => onDeleteCall(call.dongleId, call.callId)}
              >
                <TrashIcon/>
              </button>
            </div>,
          ]
        })
      }]
    }

    return [];
  }, [calls]);

  return (
    <Card additionalClassNames={[styles.CardAdditional]}>
      <div className={styles.CallAutoResponseRules}>
        <ControlHeader
          title={'Autoreply'}
          counter={{
            min: calls.length,
            max: totalCount
          }}
          actions={[]}
          loading={loading}
        />

        <Table
          headerCols={headerCols}
          filters={filters}
          handleChangeFilters={handleChangeFilters}
          name={'CallAutoResponse'}
          rowGroups={rowGroups}
          currentPage={filters.page}
          totalCount={totalCount}
          onPageChange={handlePageChange}
          loading={loading}
          columnWidths={['125px', 2, 2, 2, '165px', 2, 2, 1]}
          itemsPerPage={filters.limit}
        />
      </div>
    </Card>
  )
}