import styles from "./styles.module.scss";
import {ModemInfo} from "../modemInfo/ModemInfo";
import {WifiDetails} from "../wifiDetails/WifiDetails";
import clsx from "clsx";
import {DeviceDetailsModalMessages} from "../../messages/DeviceDetailsModalMessages";
import {TextButton} from "../../../../../shared/ui-kit-2/inputs/text-button/TextButton";
import {Subscriptions} from "../subscriptions/Subscriptions";
import {NetworkInformation} from "../networkInformation/NetworkInformation";
import {RedirectionsDetails} from "../redirectionsDetails/RedirectionsDetails";
import React, {useEffect, useState} from "react";
import {usePayDevice} from "../../../_hooks/use-pay-device/usePayDevice";
import {UsePayOrChangeSubscription} from "../../../_hooks/use-pay-or-change-subscription/usePayOrChangeSubscription";
import {useNetworkInformation} from "../../../../../sdk/hooks/use-network-information/useNetworkInformation";
import {useTariffPackages} from "../../../../../sdk/hooks/use-tariff-packages/useTariffPackages";
import {useSiteVersion} from "../../../../../app/hooks/useSiteVersion";
import {useIntl} from "react-intl";
import {DongleGetResponse} from "../../../../../sdk/datagates/types/dongle/_crud/get";

interface IDetailsProps {
  device: DongleGetResponse,
  handleSwitchHotspot: (dongleId: number, enabled: boolean) => Promise<void>,
  handleFetchDevices: () => Promise<void>,
  handleFetchDevice: (deviceId: number) => Promise<DongleGetResponse | undefined>,
  handleDeleteDevice: (dongleId: number) => Promise<void>,
  openSubscriptionsByDefault?: boolean,
}

export const Details = ({
  device,
  handleSwitchHotspot,
  handleFetchDevices,
  handleFetchDevice,
  handleDeleteDevice,
  openSubscriptionsByDefault
}: IDetailsProps) => {
  const intl = useIntl();
  const { getTariffPackageTranslation, getTariffPackagePrice } = useTariffPackages();
  const { siteVersion } = useSiteVersion();

  const [isPayOrChangeSubscription, setIsPayOrChangeSubscription] = useState<boolean>(false);
  const { networkInformation, networkInformationLoading } = useNetworkInformation(device);

  const isTariffPackageEnabled = !device?.isTariffPackageActive;

  const {
    component: componentPayDevice,
    setIsPayDeviceOpen,
    tariffPackageId,
    isOpen: isOpenPayDevice,
  } = usePayDevice({
    getTariffPackagePrice,
    getTariffPackageTranslation,
    device,
    setIsPayOrChangeSubscription,
  });

  const { component: componentSubscription } = UsePayOrChangeSubscription({
    isPayOrChangeSubscription,
    setIsPayOrChangeSubscription,
    tariffPackageId,
    setIsPayDeviceOpen,
    dongleId: device.dongleId,
  });

  useEffect(() => {
    if (openSubscriptionsByDefault) {
      setIsPayDeviceOpen(true);
    }
  }, [openSubscriptionsByDefault]);

  return (
    <>
      <div className={styles.DeviceDetails}>
        <ModemInfo
          device={device}
          handleFetchDevices={handleFetchDevices}
          handleFetchDevice={handleFetchDevice}
          handleDeleteDevice={handleDeleteDevice}
          isTariffPackageEnabled={isTariffPackageEnabled}
        />
        <WifiDetails
          device={device}
          handleSwitchHotspot={handleSwitchHotspot}
          handleFetchDevices={handleFetchDevices}
          isTariffPackageEnabled={isTariffPackageEnabled}
        />

        <div
          className={
            clsx(styles.Card, styles.Card_subscription, isTariffPackageEnabled && styles.ErrorDate)
          }
        >
          <div className={styles.Header}>
            <div className={styles.Title}>
              {intl.formatMessage(DeviceDetailsModalMessages.subscription)}
            </div>
            {siteVersion !== 'ru' && (
              <TextButton
                icon={'edit'}
                text={intl.formatMessage(DeviceDetailsModalMessages.manage)}
                additionalClassNames={[styles.EditButton]}
                onClick={() => setIsPayDeviceOpen(true)}
              />
            )}
          </div>
          <div className={styles.Divider} />
          <Subscriptions
            device={device}
            getTariffPackagePrice={getTariffPackagePrice}
            getTariffPackageTranslation={getTariffPackageTranslation}
            tariffPackageEnable={isTariffPackageEnabled}
          />
        </div>

        <div className={clsx(styles.Card, styles.Card_networkInformation)}>
          <div className={styles.Header}>
            <div className={clsx(styles.Title, isTariffPackageEnabled && styles.ErrorSubscriptionTitle)}>
              {intl.formatMessage(
                DeviceDetailsModalMessages.networkInformation,
              )}
            </div>
          </div>
          <div className={styles.Divider} />
          <NetworkInformation
            networkInformation={networkInformation}
            networkInformationLoading={networkInformationLoading}
            device={device}
            isTariffPackageEnabled={isTariffPackageEnabled}
          />
        </div>

        <div className={
          clsx(styles.Card,
            styles.Card_redirects,
            isTariffPackageEnabled && styles.ErrorSubscription
          )}
        >
          <div className={styles.Header}>
            <div className={styles.Title}>
              {intl.formatMessage(DeviceDetailsModalMessages.redirects)}
            </div>
          </div>
          <div className={styles.Divider} />
          <RedirectionsDetails
            deviceId={device.dongleId}
            handleFetchDevices={handleFetchDevices}
            isTariffPackageEnabled={isTariffPackageEnabled}
          />
        </div>
      </div>
      {componentPayDevice}
      {isPayOrChangeSubscription && componentSubscription}
    </>
  )
}