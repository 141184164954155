import React, {FC, useCallback, useEffect, useMemo, useRef, useState} from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../../../../../../../app/hooks/useModal'
import { MODAL_TYPES } from '../../../../../../../../../layouts/modals/ModalsProvider'
import { DongleListResponse } from '../../../../../../../../../sdk/datagates/types/dongle/_crud/list'
import {
  CallTypeTabs,
  useCalls,
} from '../../../../../../../../../sdk/hooks/use-calls/useCalls'
import { Card } from '../../../../../../../../../shared/ui-kit-2/data-display/card/Card'
import { LC } from '../../../../../../../../../tests/e2e/locators'
import styles from './styles.module.scss'
import {useSip} from "../../../../../../../../../shared/lib/hooks/useSip";
import {useSiteVersion} from "../../../../../../../../../app/hooks/useSiteVersion";
import {callType} from "../../../../../../../../../store/reducers/sip/types";
import {contactListDatagate} from "../../../../../../../../../sdk/datagates/api/contact";
import {useWebSockets} from "../../../../../../../../../shared/lib/hooks/useWebSockets";
import {WEB_SOCKETS_EVENTS_VALUES} from "../../../../../../../../../shared/lib/constants/WEB_SOCKETS_EVENTS_VALUES";
import ControlHeader from "../../../../../../../../../shared/ui-kit-3/components/ControlHeader/ControlHeader";
import { ReactComponent as CallIcon } from '../../../../../../../../../assets/icons/call.svg';
import {useTableFilters} from "../../../../../../../../../shared/ui-kit-2/data-display/table/hooks/useTableFilters";
import {
  HeaderCol,
  TABLE_FILTERS_VARIANTS
} from "../../../../../../../../../shared/ui-kit-2/data-display/table/components/TableHeader/TableHeader";
import {
  DEFAULT_COLS, DEFAULT_COLS_VARIANTS,
  IconContainer
} from "../../../../../../../../../shared/ui-kit-2/data-display/table/contants/defaultCols";
import { ReactComponent as CaretDownIcon } from '../../../../../../../../../assets/icons/v2/ic-caret-down.svg'
import {
  NoDevicesForCalls
} from "../../../../../../../../../layouts/status-layouts/no-devices-for-calls/NoDevicesForCalls";
import {Table, TableColType} from "../../../../../../../../../shared/ui-kit-2/data-display/table/Table";
import {
  CommonTableMessages
} from "../../../../../../../../../config/intl/common-messages/common-table-messages/CommonTableMessages";
import {
  CallTableRows
} from "../../../../../../../../../layouts/tables/call-table/components/call-table-rows/CallTableRows";
import { tableFiltersToUrlFilters } from 'shared/lib/utils/table-filters-to-url-filters/tableFiltersToUrlFilters'
import {useSearchParams} from "react-router-dom";


interface CallListProps {
  devices: DongleListResponse['dongles']
  devicesLoading: boolean
}


const CallsListMessages = defineMessages({
  title: {
    id: 'CallsListMessages.title',
    defaultMessage: 'Calls',
  },
  call: {
    id: 'CallsListMessages.call',
    defaultMessage: 'Call',
  },
})

export const CallsList: FC<CallListProps> = (props) => {
  const { devices, devicesLoading } = props
  const intl = useIntl()

  const { siteVersion } = useSiteVersion();
  const { handleChangeModal } = useModal();

  const audioRef = useRef(null);

  const { webSocketsEventData } = useWebSockets({
    events: [WEB_SOCKETS_EVENTS_VALUES.call_created]
  });

  const {
    deviceFrom,
    phoneFrom,
    makeCall,
    answerIncomingCall,
    endCall,
    ua,
    hasOutgoingCall,
    hasIncomingCall,
    callHasAcceptedByOtherSide,
    loading: sipLoading,
    setAudioRef,
  } = useSip();

  const headerCols = [
    {
      id: 'type',
      filterType: TABLE_FILTERS_VARIANTS.CHECKBOXES,
      label: 'Type',
      defaultText: 'Select',
      defaultTextIcon: (
        <IconContainer>
          <CaretDownIcon />
        </IconContainer>
      ),
      checkboxes: [
        { text: 'All', value: 'All' },
        { text: 'Incoming', value: '2' },
        { text: 'Outgoing', value: '1' },
      ]
    },
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.time],
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.callStatus],
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.caller],
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.receiver],
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.duration],
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.actions],
  ] as HeaderCol[];

  const {
    filters,
    handleChangeFilters,
    handlePageChange,
    debouncedFilters,
  } = useTableFilters({
    queryIds: [
      ...headerCols.map(({ id }) => id),
      'page',
      'limit',
    ],
    defaultFilterValues: [
      {
        name: 'call_type_ids',
        value: [1, 2]
      },
      {
        name: 'page',
        value: 1
      },
      {
        name: 'limit',
        value: 50
      }
    ]
  })

  const currentFilters = useMemo(() => tableFiltersToUrlFilters([
    { name: 'call_type_ids[]', value: debouncedFilters?.call_type_ids },
    { name: 'page', value: debouncedFilters?.page - 1 },
    { name: 'limit', value: debouncedFilters?.limit },
    { name: 'from', value: debouncedFilters?.time?.from },
    { name: 'to', value: debouncedFilters?.time?.to },
    { name: 'call_type_ids[]', value: debouncedFilters?.type },
    { name: 'call_status_ids[]', value: debouncedFilters?.callStatus },
    { name: 'caller', value: debouncedFilters?.caller },
    { name: 'receiver', value: debouncedFilters?.receiver },
    { name: 'duration_presets[]', value: debouncedFilters?.duration },
  ]), [debouncedFilters])

  const {
    calls,
    totalCount,
    handleDeleteCall,
    handleFetch: handleFetchCalls,
    loading: callsLoading,
  } =
    useCalls({
      filters: currentFilters,
    })

  const { handleOpenModal } = useModal()

  const openCreateCallModal = () => {
    handleOpenModal({
      type: MODAL_TYPES.CREATE_CALL,
      props: {
        makeCall,
        answerIncomingCall,
        endCall,
        ua,
        hasOutgoingCall,
        hasIncomingCall,
        callHasAcceptedByOtherSide,
      }
    })
  }

  const rowGroups = CallTableRows(calls, handleDeleteCall);

  const globalLoading = callsLoading || devicesLoading;

  useEffect(() => {
    setAudioRef(audioRef.current);
  }, [setAudioRef]);

  useEffect(() => {
    if (filters.page === 1 && !callsLoading) {
      handleFetchCalls({ hidden: true });
    }
  }, [webSocketsEventData]);

  useEffect(() => {
    const handleIncomingCall = async () => {
      if (hasIncomingCall) {
        const { data } = await contactListDatagate(null, null,[
          { name: 'phone', value: phoneFrom?.replace('+', '%2B') || '' },
          { name: 'page', value: '0' },
          { name: 'limit', value: '1' },
        ])

        handleOpenModal({
          type: MODAL_TYPES.CALL,
          props: {
            from: {
              name: data?.contacts?.[0]?.name,
              phoneNumber: phoneFrom,
            },
            to: {
              name: deviceFrom?.name,
              phoneNumber: deviceFrom?.phoneNumber,
            },
            type: callType.incoming,
            sip: {
              makeCall,
              answerIncomingCall,
              endCall,
              ua,
              hasOutgoingCall,
              hasIncomingCall,
              callHasAcceptedByOtherSide,
              loading: sipLoading
            }
          }
        })
      }
    }

    handleIncomingCall();
  }, [hasIncomingCall, deviceFrom]);

  useEffect(() => {
    if (hasIncomingCall || hasOutgoingCall) {
      handleChangeModal({
        type: hasIncomingCall
          ? callType.incoming
          : hasOutgoingCall
            ? callType.outgoing
            : null,
        sip: {
          makeCall,
          answerIncomingCall,
          endCall,
          ua,
          hasOutgoingCall,
          hasIncomingCall,
          callHasAcceptedByOtherSide,
          loading: sipLoading
        }
      })
    }
  }, [sipLoading, phoneFrom, deviceFrom, callHasAcceptedByOtherSide]);

  return (
    <Card additionalClassNames={[styles.Card]}>
      <ControlHeader
        title={'Calls'}
        counter={{
          min: calls.length,
          max: totalCount
        }}
        actions={siteVersion !== 'ru' ? [
          {
            variant: 'greenFilled',
            size: 'md',
            text: intl.formatMessage(CallsListMessages.call),
            prefix: <CallIcon />,
            onClick: openCreateCallModal,
            disabled: devices.length === 0
          },
        ] : []}
        loading={globalLoading}
      />

      <Table
        headerCols={headerCols}
        filters={filters}
        handleChangeFilters={handleChangeFilters}
        name={'CallTable'}
        rowGroups={devices.length === 0 ? [] : rowGroups}
        currentPage={filters.page}
        totalCount={totalCount}
        onPageChange={handlePageChange}
        itemsPerPage={filters.limit}
        loading={globalLoading}
        columnWidths={['100px', '125px', '120px', 1, 1, '165px', 1]}
        testId={LC.CALLS.TABLE._}
        noDataComponent={devices.length === 0 && <NoDevicesForCalls />}
      />

      <audio ref={audioRef} id="remoteAudio" autoPlay playsInline></audio>
    </Card>
  )
}
