
import React from "react";
import {ControlHeaderProps} from "./ControlHeader.types";
import {Counter} from "../../../ui-kit-2/data-display/counter/Counter";
import {Button} from "../Button";
import styles from './styles.module.scss';


const ControlHeader = ({ title, counter, actions, loading }: ControlHeaderProps) => {
  return (
    <div className={styles.ControlHeader}>
      <div className={styles.TitleContainer}>
        <div className={styles.Title}>
          {title}
        </div>
        <div className={styles.Counter}>
          <Counter
            customText={`Showing ${counter.min} of ${counter.max} items`}
            variant='filled'
            loading={loading}
          />
        </div>
      </div>
      <div className={styles.Actions}>
        {actions.map(action => (
          <Button {...action} />
        ))}
      </div>
    </div>
  )
};

export default ControlHeader;