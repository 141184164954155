import React, { useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { defineMessages, useIntl } from 'react-intl'
import { PhoneMultipleSettings } from '../_components/phone-multiple-settings/PhoneMultipleSettings'
import { Source } from '../_hooks/use-add-source/useAddSource'
import { useModal } from '../../../app/hooks/useModal'
import { useSnackbar } from '../../../app/hooks/useSnackbar'
import { mailingCreateDatagate } from '../../../sdk/datagates/api/mailing'
import { useBackendErrorCodes } from '../../../sdk/datagates/helpers/_common/use-backend-error-codes'
import { useDevices } from '../../../sdk/hooks/use-devices/useDevices'
import { MAILING_STATUSES } from '../../../sdk/hooks/use-mailing-statuses/constants/MailingStatuses'
import { MAILING_TYPE_IDS } from '../../../sdk/hooks/use-mailings/useMailings'
import { formatDate } from '../../../shared/lib/form/form-field-adapters/v2/datepicker-field/_helpers/formatDate'
import { InputField } from '../../../shared/lib/form/form-field-adapters/v2/input-field/InputField'
import { SelectSearchField } from '../../../shared/lib/form/form-field-adapters/v2/select-search-field/SelectSearchField'
import { TextareaField } from '../../../shared/lib/form/form-field-adapters/v2/textarea-field/TextareaField'
import { DatepickerOptionalField } from '../../../shared/lib/form/form-field-templates/v2/datepicker-optional-field/DatepickerOptionalField'
import { useFormRules } from '../../../shared/lib/form/form-rules'
import { getErrorMessage } from '../../../shared/lib/utils/get-error-message/getErrorMessage'
import { Drawer } from '../../../shared/ui-kit-2/data-display/drawer/Drawer'
import { Button } from '../../../shared/ui-kit-2/inputs/button/Button'
import { SelectSearchOption } from '../../../shared/ui-kit-2/inputs/select-search/SelectSearch'
import { LoadingContent } from '../../loading-content/LoadingContent'
import { SNACKBAR_TYPES } from '../../snackbar/SnackbarProvider'
import { SmsMailingLimitTip } from '../../tips/sms-mailing-limit-tip/SmsMailingLimitTip'
import styles from './styles.module.scss'

type SmsMailingCreationType = 'create-draft' | 'send-now' | 'create-planned'

type SmsMailingCreateFormType = {
  creationType: SmsMailingCreationType
  mailingName: string
  deviceId: string
  smsMessage: string
  date?: string
}

const SmsMailingCreateModalMessages = defineMessages({
  title: {
    id: 'SmsMailingCreateModalMessages.title',
    defaultMessage: 'Create SMS campaign',
  },
  mailingNameLabel: {
    id: 'SmsMailingCreateModalMessages.mailingNameLabel',
    defaultMessage: 'Campaign name',
  },
  mailingNamePlaceholder: {
    id: 'SmsMailingCreateModalMessages.mailingNamePlaceholder',
    defaultMessage: 'Enter mailing name',
  },
  deviceLabel: {
    id: 'SmsMailingCreateModalMessages.deviceLabel',
    defaultMessage: 'Device',
  },
  devicePlaceholder: {
    id: 'SmsMailingCreateModalMessages.devicePlaceholder',
    defaultMessage: 'Choose device',
  },
  smsMessageLabel: {
    id: 'SmsMailingCreateModalMessages.smsMessageLabel',
    defaultMessage: 'Message',
  },
  smsMessagePlaceholder: {
    id: 'SmsMailingCreateModalMessages.smsMessagePlaceholder',
    defaultMessage: 'Enter text',
  },
  scheduledAtDateLabel: {
    id: 'SmsMailingCreateModalMessages.scheduledAtDateLabel',
    defaultMessage: 'Optional scheduling (UTC timezone)',
  },
  saveInDrafts: {
    id: 'SmsMailingCreateModalMessages.saveInDrafts',
    defaultMessage: 'Save in drafts',
  },
  saveInDraftsSuccess: {
    id: 'SmsMailingCreateModalMessages.saveInDraftsSuccess',
    defaultMessage: 'New mailing saved in drafts successfully',
  },
  saveInDraftsError: {
    id: 'SmsMailingCreateModalMessages.saveInDraftsError',
    defaultMessage: 'An error occurred while saving new mailing in drafts',
  },
  sendNow: {
    id: 'SmsMailingCreateModalMessages.sendNow',
    defaultMessage: 'Send now',
  },
  sendNowSuccess: {
    id: 'SmsMailingCreateModalMessages.sendNowSuccess',
    defaultMessage: 'New mailing successfully started',
  },
  sendNowError: {
    id: 'SmsMailingCreateModalMessages.sendNowError',
    defaultMessage: 'An error occurred while creating new mailing',
  },
  create: {
    id: 'SmsMailingCreateModalMessages.create',
    defaultMessage: 'Create',
  },
  createSuccess: {
    id: 'SmsMailingCreateModalMessages.createSuccess',
    defaultMessage: 'New mailing planned successfully',
  },
  createError: {
    id: 'SmsMailingCreateModalMessages.createError',
    defaultMessage: 'An error occurred while creating new planned mailing',
  },
  zeroSourcesError: {
    id: 'SmsMailingCreateModalMessages.zeroSourcesError',
    defaultMessage:
      'At least one phone number, contact or contact group must be selected',
  },
})

export const SmsMailingCreateModal = () => {
  const intl = useIntl()

  const { handleHideModal, props } = useModal()

  const { handleFetch } = props

  const { handleOpenSnackbar } = useSnackbar()
  const { resolveBackendError } = useBackendErrorCodes()
  const { ruleRequired } = useFormRules()
  const [isScheduledAtDateOpen, setIsScheduledAtDateOpen] =
    useState<boolean>(false)
  const [sources, setSources] = useState<Source[]>([])
  const { devices, loading } = useDevices({ page: 0, limit: 1000, takeAll: true })
  const deviceOptions: SelectSearchOption[] = useMemo(
    () =>
      devices.map((d) => {
        return {
          key: d.dongleId,
          label: d.name,
          inputLabel: d.name,
          value: d.dongleId.toString(),
        }
      }),
    [devices],
  )

  const onSubmit = async (values: SmsMailingCreateFormType) => {
    try {
      if (sources.length === 0) {
        handleOpenSnackbar({
          type: SNACKBAR_TYPES.error,
          text: intl.formatMessage(
            SmsMailingCreateModalMessages.zeroSourcesError,
          ),
        })
        return
      }

      const getMailingStatusId = () => {
        switch (values.creationType) {
          case 'create-draft':
            return MAILING_STATUSES.DRAFT
          case 'send-now':
          case 'create-planned':
            return MAILING_STATUSES.PLANNED
        }
      }

      const contactGroups = sources
        .filter((s) => s.sourceType === 'contact-groups')
        .map((cg) => +cg.sourceValue)
      const contacts = sources
        .filter((s) => s.sourceType === 'contacts')
        .map((c) => +c.sourceValue)
      const phones = sources
        .filter((s) => s.sourceType === 'phone')
        .map((p) => p.sourceValue)

      await mailingCreateDatagate({
        name: values.mailingName,
        mailing_entity_type_id: 1,
        mailing_type_id: values.date
          ? MAILING_TYPE_IDS.DELAYED
          : MAILING_TYPE_IDS.SIMPLE,
        mailing_status_id: getMailingStatusId(),
        text: values.smsMessage,
        dongle_id: values.deviceId,
        mailing_phones: phones,
        mailing_contact_ids: contacts,
        mailing_contact_group_ids: contactGroups,
        scheduled_at: values.date ? formatDate(values.date, true) : undefined,
      })

      const successText = () => {
        switch (values.creationType) {
          case 'create-draft':
            return intl.formatMessage(
              SmsMailingCreateModalMessages.saveInDraftsSuccess,
            )
          case 'create-planned':
            return intl.formatMessage(
              SmsMailingCreateModalMessages.createSuccess,
            )
          case 'send-now':
            return intl.formatMessage(
              SmsMailingCreateModalMessages.sendNowSuccess,
            )
        }
      }

      handleOpenSnackbar({
        type: SNACKBAR_TYPES.success,
        text: successText(),
      })

      handleHideModal()
      handleFetch?.()
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: resolveBackendError(getErrorMessage(e)),
      })
    }
  }

  const content = (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting, form, values }) => {
        const isPlanned = !!values.date

        return (
          <form onSubmit={handleSubmit} className={styles.Container}>
            <SmsMailingLimitTip />

            <InputField
              name={'mailingName'}
              validate={ruleRequired()}
              label={intl.formatMessage(
                SmsMailingCreateModalMessages.mailingNameLabel,
              )}
              placeholder={intl.formatMessage(
                SmsMailingCreateModalMessages.mailingNamePlaceholder,
              )}
              markAsRequired={true}
            />

            <SelectSearchField
              name="deviceId"
              validate={ruleRequired()}
              options={deviceOptions}
              label={intl.formatMessage(
                SmsMailingCreateModalMessages.deviceLabel,
              )}
              placeholder={intl.formatMessage(
                SmsMailingCreateModalMessages.devicePlaceholder,
              )}
              markAsRequired={true}
            />

            <PhoneMultipleSettings
              sources={sources}
              setSources={setSources}
              phoneSourceVariant={'all'}
              allPhonesFeatureOn={false}
            />

            <TextareaField
              name={'smsMessage'}
              validate={ruleRequired()}
              label={intl.formatMessage(
                SmsMailingCreateModalMessages.smsMessageLabel,
              )}
              placeholder={intl.formatMessage(
                SmsMailingCreateModalMessages.smsMessagePlaceholder,
              )}
            />

            <DatepickerOptionalField
              name={'date'}
              validate={ruleRequired()}
              isOpen={isScheduledAtDateOpen}
              setIsOpen={setIsScheduledAtDateOpen}
              title={intl.formatMessage(
                SmsMailingCreateModalMessages.scheduledAtDateLabel,
              )}
              minDateToday={true}
              onRemove={() => form.change('date', undefined)}
            />

            <div className={styles.Actions}>
              <Button
                text={intl.formatMessage(
                  SmsMailingCreateModalMessages.saveInDrafts,
                )}
                variant={'white'}
                disabled={submitting}
                onClick={() => {
                  form.change('creationType', 'create-draft')
                  handleSubmit()
                }}
              />

              {!isPlanned && (
                <Button
                  text={intl.formatMessage(
                    SmsMailingCreateModalMessages.sendNow,
                  )}
                  variant={'green'}
                  disabled={submitting}
                  onClick={() => {
                    form.change('creationType', 'send-now')
                    handleSubmit()
                  }}
                />
              )}

              {isPlanned && (
                <Button
                  text={intl.formatMessage(
                    SmsMailingCreateModalMessages.create,
                  )}
                  variant={'green'}
                  disabled={submitting}
                  onClick={() => {
                    form.change('creationType', 'create-planned')
                    handleSubmit()
                  }}
                />
              )}
            </div>
          </form>
        )
      }}
    />
  )

  return (
    <Drawer
      title={intl.formatMessage(SmsMailingCreateModalMessages.title)}
      isOpen={true}
      close={handleHideModal}
      additionalClassNames={[styles.DrawerAdditional]}
    >
      <LoadingContent loading={loading} content={content} />
    </Drawer>
  )
}
