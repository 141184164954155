import {wrapApiRequest} from "../../helpers/_common/wrap-api-request";

const orderDongleGetDatagateMapper = (data: any) => {
  const {
    data: {
      order_dongles
    },
  } = data;

  return order_dongles.map((orderDongle: any) => ({
    orderDongleId: orderDongle.order_dongle_id,
    tariffPackage: {
      donglePrice: orderDongle.tariff_package.dongle_price,
      dongleLimit: orderDongle.tariff_package.dongle_limit,
      tariffPackageId: orderDongle.tariff_package.tariff_package_id,
    }
  }))
}

export const orderDongleGetDatagate = wrapApiRequest<
  {},
  {},
  {}
>('order-dongle', orderDongleGetDatagateMapper as any, 'GET')