import { useEffect } from "react";
import { useSelector } from "react-redux";

export const useClickOutside = <T>(
  ref: React.RefObject<HTMLElement>,
  onClickOutside: () => void,
  excludeElements: (string | HTMLElement)[] = []
) => {
  // @ts-ignore
  const { isOpen } = useSelector((store) => store.dialog);

  useEffect(() => {
    const isExcluded = (target: Node): boolean =>
      excludeElements.some((exclude) => {
        if (typeof exclude === "string") {
          const element = document.querySelector(exclude);
          return element?.contains(target) ?? false;
        }
        return exclude.contains(target);
      });

    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as Node;

      if (
        ref.current &&
        !ref.current.contains(target) &&
        !isExcluded(target) &&
        !isOpen
      ) {
        onClickOutside();
      }
    };

    document.addEventListener("mousedown", handleClickOutside, true);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, true);
    };
  }, [ref, isOpen, onClickOutside, excludeElements]);
};
