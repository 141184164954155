
import React, {useEffect, useState} from "react";
import {defineMessages, useIntl} from "react-intl";
import clsx from "clsx";
import {useModal} from "../../../app/hooks/useModal";
import {CenteredDrawer} from "../../../shared/ui-kit-2/data-display/centered-drawer/CenteredDrawer";
import { ReactComponent as ArrowUp } from '../../../assets/icons/arrow-up-universal.svg';
import {IconButton} from "../../../shared/ui-kit-2/inputs/icon-button/IconButton";
import {useSnackbar} from "../../../app/hooks/useSnackbar";
import styles from './styles.module.scss';
import {LoadingContent} from "../../loading-content/LoadingContent";
import {Card} from "../../../shared/ui-kit-2/data-display/card/Card";
import {callType} from "../../../store/reducers/sip/types";


const CallModalMessages = defineMessages({
  startCall: {
    id: "CallModalMessages.startCall",
    defaultMessage: "Answer"
  },
  endCall: {
    id: "CallModalMessages.endCall",
    defaultMessage: "End"
  },
  timeLeft: {
    id: "CallModalMessages.timeLeft",
    defaultMessage: "Call"
  }
});

enum CALL_TYPES {
  incoming,
  outgoing
}

export const CallModal = () => {
  const intl = useIntl()
  const {
    props: {
      type,
      from,
      to,
      sip: {
        answerIncomingCall,
        endCall,
        hasIncomingCall,
        callHasAcceptedByOtherSide,
        loading
      }
    },
    handleHideModal
  } = useModal();

  const [timeLeft, setTimeLeft] = useState<number>(0);

  const isIncomingCall = type === CALL_TYPES.incoming;

  useEffect(() => {
    if (!callHasAcceptedByOtherSide) return;

    const timer: NodeJS.Timeout = setTimeout(() => {
      setTimeLeft(prevTime => prevTime + 1);
    }, 1000);

    return () => clearTimeout(timer);
  }, [callHasAcceptedByOtherSide, timeLeft]);

  const handleEndCall = () => {
    endCall();

    // @ts-ignore
    // window.stopAllSounds();
    handleHideModal();
  };

  // TODO: Need import global formatTime method
  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const IncomingCall = () => {
    const mainInfoSource = callHasAcceptedByOtherSide ? from : to;

    return (
      <div className={clsx(styles.Call, callHasAcceptedByOtherSide && styles.Call_hasAccepted)}>
        <div className={styles.MainInfo}>
          <div className={styles.Info}>
            <div className={clsx(styles.Name, callHasAcceptedByOtherSide && styles.Name_bold)}>
              {mainInfoSource?.name || mainInfoSource?.phoneNumber}
            </div>
            <div className={styles.PhoneNumber}>
              {mainInfoSource?.name ? (mainInfoSource?.phoneNumber || ' ') : ' '}
            </div>
          </div>
          {callHasAcceptedByOtherSide && (
            <div className={styles.TimeLeft}>
              {intl.formatMessage(CallModalMessages.timeLeft)} - {formatTime(timeLeft)}
            </div>
          )}
        </div>
        {!callHasAcceptedByOtherSide && (
          <>
            <div className={styles.Arrow}>
              <ArrowUp />
            </div>
            <div className={styles.Info}>
              <div className={clsx(styles.Name, styles.Name_bold)}>
                {from?.name || ''}
              </div>
              <div className={styles.PhoneNumber}>
                {from?.phoneNumber || ''}
              </div>
            </div>
          </>
        )}
        <div className={styles.Actions}>
          {(hasIncomingCall && !callHasAcceptedByOtherSide) && (
            <div className={styles.Action}>
              <div className={styles.Action__Container}>
                <IconButton
                  icon='callConnect'
                  additionalClassNames={[styles.Action__IconButton, styles.Action__IconButton_green]}
                  onClick={answerIncomingCall}
                />
              </div>
              <div className={styles.Action__Text}>
                {intl.formatMessage(CallModalMessages.startCall)}
              </div>
            </div>
          )}
          <div className={styles.Action}>
            <div className={styles.Action__Container}>
              <IconButton
                icon='callDisconnect'
                additionalClassNames={[styles.Action__IconButton, styles.Action__IconButton_red]}
                onClick={handleEndCall}
              />
            </div>
            <div className={styles.Action__Text}>
              {intl.formatMessage(CallModalMessages.endCall)}
            </div>
          </div>
        </div>
      </div>
    );
  }

  const OutgoingCall = () => {
    const mainInfoSource = callHasAcceptedByOtherSide ? to : from;

    return (
      <div className={clsx(styles.Call, callHasAcceptedByOtherSide && styles.Call_hasAccepted)}>
        <div className={styles.MainInfo}>
          <div className={styles.Info}>
            <div className={clsx(styles.Name, styles.Name_bold)}>
              {mainInfoSource?.name || mainInfoSource?.phoneNumber}
            </div>
            <div className={styles.PhoneNumber}>
              {mainInfoSource?.name ? (mainInfoSource?.phoneNumber || ' ') : ' '}
            </div>
          </div>
          {callHasAcceptedByOtherSide && (
            <div className={styles.TimeLeft}>
              {intl.formatMessage(CallModalMessages.timeLeft)} - {formatTime(timeLeft)}
            </div>
          )}
        </div>
        {!callHasAcceptedByOtherSide && (
          <>
            <div className={clsx(styles.Arrow, styles.Arrow_rotated)}>
              <ArrowUp />
            </div>
            <div className={styles.Info}>
              <div className={styles.Name}>
                {to?.name || to?.phoneNumber}
              </div>
              <div className={styles.PhoneNumber}>
                {to?.name ? to?.phoneNumber : ''}
              </div>
            </div>
          </>
        )}
        <div className={styles.Actions}>
          <div className={styles.Action}>
            <div className={styles.Action__Container}>
              <IconButton
                icon='callDisconnect'
                additionalClassNames={[styles.Action__IconButton, styles.Action__IconButton_red]}
                onClick={handleEndCall}
              />
            </div>
            <div className={styles.Action__Text}>
              {intl.formatMessage(CallModalMessages.endCall)}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <CenteredDrawer
      isOpen={true}
      close={() => null}
    >
      <Card additionalClassNames={[styles.Card]}>
        <LoadingContent
          loading={loading}
          content={type === CALL_TYPES.incoming ? <IncomingCall /> : <OutgoingCall />}
        />
      </Card>
    </CenteredDrawer>
  )
}