import React, {useEffect, useMemo, useState} from "react";
import {PageWrapper} from "../../../../layouts/page-wrapper/PageWrapper";
import { ReactComponent as WifiIcon } from '../../../../assets/icons/v2/ic-wifi.svg';
import { ReactComponent as VPNIcon } from '../../../../assets/icons/v2/ic-vpn.svg';
import { ReactComponent as SmsIcon } from '../../../../assets/icons/v2/ic-sms.svg';
import { ReactComponent as CallIcon } from '../../../../assets/icons/v2/ic-call.svg';
import {useDevices} from "../../../../sdk/hooks/use-devices/useDevices";
import {LoadingContent} from "../../../../layouts/loading-content/LoadingContent";
import {EventLog} from "./components/EventLog";
import {ActivateModem} from "./components/ActivateModem";
import {RoutePath} from "../../../../config/routes/constants/routePath";
import {Route} from "../../../../config/routes/enums/route";
import {useSmses} from "../../../../sdk/hooks/use-smses/useSmses";
import {useCalls} from "../../../../sdk/hooks/use-calls/useCalls";
import {CardWithEvents} from "./components/CardWithEvents";
import {SmsRoutePath} from "../../../../config/routes/constants/smsRoutePath";
import {SmsRoute} from "../../../../config/routes/enums/smsRoute";
import {CallsRoutePath} from "../../../../config/routes/constants/callsRoutePath";
import {CallsRoute} from "../../../../config/routes/enums/callsRoute";
import {Modems} from "./components/Modems";
import {SmallCard} from "./components/SmallCard";
import styles from './styles.module.scss';


export const DashboardPage = () => {
  const { devices, loading: devicesLoading } = useDevices({
    page: 0,
    limit: 1000,
    takeAll: true,
  });

  const [loading, setLoading] = useState<boolean>(true);

  const {
    countOnlineDevices,
    countOfflineDevices,
    countOnlineWifi,
    countOfflineWifi,
    countOnlineVPNs,
    countOfflineVPNs,
  } = useMemo(() => {
    if (devices.length === 0) {
      return {
        countOnlineDevices: 0,
        countOfflineDevices: 0,
        countOnlineWifi: 0,
        countOfflineWifi: 0,
        countOnlineVPNs: 0,
        countOfflineVPNs: 0,
      }
    }

    const { countOnlineDevices, countOnlineWifi, countOnlineVPNs } = devices.reduce((reducer, device) => {
      return {
        countOnlineDevices: reducer.countOnlineDevices + Number(device.isOnline),
        countOnlineWifi: reducer.countOnlineWifi + Number(device.isHotspotEnable),
        countOnlineVPNs: reducer.countOnlineVPNs + Number(device.isVPNConnected),
      }
    }, {
      countOnlineDevices: 0,
      countOnlineWifi: 0,
      countOnlineVPNs: 0,
    });

    return {
      countOnlineDevices,
      countOfflineDevices: devices.length - countOnlineDevices,
      countOnlineWifi,
      countOfflineWifi: devices.length - countOnlineWifi,
      countOnlineVPNs,
      countOfflineVPNs: devices.length - countOnlineVPNs,
    }
  }, [devices]);

  const { loading: smsesLoading, totalCount: smsesTotalCount } = useSmses({
    filters: [
      {
        name: 'page',
        value: '0'
      },
      {
        name: 'limit',
        value: '1'
      }
    ]
  })

  const { loading: callsLoading, totalCount: callsTotalCount } = useCalls({
    filters: [
      {
        name: 'page',
        value: '0'
      },
      {
        name: 'limit',
        value: '1'
      }
    ],
  })

  useEffect(() => {
    if (!devicesLoading && !callsLoading && !smsesLoading) {
      setTimeout(() => {
        setLoading(false);
      }, 0);
    }
  }, [devicesLoading, callsLoading, smsesLoading])

  const content = (
    <>
      <div className={styles.Top}>
        <div className={styles.Title}>
          Dashboard
        </div>
      </div>
      <div className={styles.Content}>
        <CardWithEvents
          title={'SMS'}
          itemsCount={smsesTotalCount}
          eventsCount={0}
          backgroundIcon={<SmsIcon />}
          seeAllUrl={SmsRoutePath[SmsRoute.SmsList]}
        />
        <CardWithEvents
          title={'Calls'}
          itemsCount={callsTotalCount}
          eventsCount={0}
          backgroundIcon={<CallIcon />}
          seeAllUrl={CallsRoutePath[CallsRoute.CallsList]}
        />
        <ActivateModem />
        <Modems
          devices={devices}
          countOnline={countOnlineDevices}
          countOffline={countOfflineDevices}
        />
        <EventLog />
        <SmallCard
          title={'Wi-Fi'}
          countOnline={countOnlineWifi}
          countOffline={countOfflineWifi}
          countDevices={devices.length}
          seeAllButtonRoute={RoutePath[Route.WifiList]}
          bgIcon={<WifiIcon />}
        />
        <SmallCard
          title={'VPN'}
          countOnline={countOnlineVPNs}
          countOffline={countOfflineVPNs}
          countDevices={devices.length}
          seeAllButtonRoute={RoutePath[Route.VPN]}
          bgIcon={<VPNIcon />}
        />
      </div>
    </>
  );

  return (
    <PageWrapper loading={loading}>
      <LoadingContent
        loading={loading}
        content={content}
      />
    </PageWrapper>
  )
}