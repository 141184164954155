import { AuthRoutePath } from '../../../../../constants/authRoutePath'
import { RoutePath } from '../../../../../constants/routePath'
import { AuthRoute } from '../../../../../enums/authRoute'
import { Route } from '../../../../../enums/route'
import { RouteDescriptor } from '../../../../../types/routeDescriptor'
import {
  DeviceConnectionStatus
} from "../../../../../../../pages/lk/subpages/device-connection-status/DeviceConnectionStatus";


export const deviceConnectionStatus: RouteDescriptor<Route.DeviceConnectionStatus> = {
  route: Route.DeviceConnectionStatus,
  type: 'subpage',
  title: 'Teleleo',
  path: RoutePath[Route.DeviceConnectionStatus],
  render: DeviceConnectionStatus,
  accessLevel: 'user',
  noAccessRedirectPath: AuthRoutePath[AuthRoute.SignIn],
}
