import { AuthRoutePath } from '../../../../../../../constants/authRoutePath'
import { AuthRoute } from '../../../../../../../enums/authRoute'
import { RouteDescriptor } from '../../../../../../../types/routeDescriptor'
import {CallsRoutePath} from "../../../../../../../constants/callsRoutePath";
import {CallsRoute} from "../../../../../../../enums/callsRoute";
import {
  CallsAutoDialer
} from "../../../../../../../../../pages/lk/subpages/calls/subpages/calls-auto-dialer/CallsAutoDialer";

export const callsAutoDialer: RouteDescriptor<CallsRoute.CallsAutoDialer> = {
  route: CallsRoute.CallsAutoDialer,
  type: 'subpage',
  title: 'Teleleo',
  path: CallsRoutePath[CallsRoute.CallsAutoDialer],
  render: CallsAutoDialer,
  accessLevel: 'user',
  noAccessRedirectPath: AuthRoutePath[AuthRoute.SignIn],
}
