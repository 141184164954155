
import React, {ReactNode} from "react";
import {useNavigate} from "react-router";
import {isNumber} from "lodash";
import {Sticker} from "../../../../../../shared/ui-kit-3/components/Sticker";
import {SeeAllButton} from "../SeeAllButton";
import styles from "./styles.module.scss";
import {Card} from "../Card";


type CardWithEventsProps = {
  title: string;
  itemsCount: number;
  eventsCount: number;
  backgroundIcon: ReactNode;
  seeAllUrl: string;
}


const CardWithEvents = ({ title, itemsCount, eventsCount, backgroundIcon, seeAllUrl }: CardWithEventsProps) => {
  const navigate = useNavigate()

  return (
    <Card>
      <>
        <div className={styles.Background}>
          {backgroundIcon}
        </div>
        <div className={styles.Content}>
          <div className={styles.Top}>
            <div className={styles.StickerRow}>
              <div className={styles.Title}>
                {title}
              </div>
              {isNumber(itemsCount) && (
                <Sticker
                  variant={'grey'}
                  text={`${itemsCount} ${itemsCount === 1 ? 'item' : 'items'}`}
                />
              )}
            </div>
          </div>
          <div className={styles.Cols}>
            <div className={styles.Counter}>
              {eventsCount}
            </div>
            <div className={styles.CounterSubText}>
              New {'\n'} events
            </div>
          </div>
          <div className={styles.Navigate}>
            <SeeAllButton onClick={() => navigate(seeAllUrl)}/>
          </div>
        </div>
      </>
    </Card>
  )
}

export default CardWithEvents;