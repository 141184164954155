import React, { FC, useEffect } from 'react'
import clsx from 'clsx'
import { defineMessages, useIntl } from 'react-intl'
import { useDeleteMailingSubmodal } from '../../../../../_hooks/use-bool-action-submodal/templates/useDeleteMailingSubmodal'
import { CommonButtonMessages } from '../../../../../../../config/intl/common-messages/common-button-messages/CommonButtonMessages'
import { SMS_STATUSES } from '../../../../../../../pages/lk/subpages/sms/components/SmsStatus/constants/SmsStatuses'
import { MailingGetResponse } from '../../../../../../../sdk/datagates/types/mailing/_crud/get'
import { formatPhoneNumber } from '../../../../../../../sdk/formatters/format-phone-number'
import { useSmses } from '../../../../../../../sdk/hooks/use-smses/useSmses'
import { Button } from '../../../../../../../shared/ui-kit-2/inputs/button/Button'
import { LoadingContent } from '../../../../../../loading-content/LoadingContent'
import styles from './styles.module.scss'

interface SmsMailingEndedContentProps {
  mailing: MailingGetResponse
  onDeleteMailing: () => Promise<void>
  forceAction: 'delete' | 'cancel' | null
}

const SmsMailingEndedContentMessages = defineMessages({
  overviewTitle: {
    id: 'SmsMailingEndedContentMessages.overviewTitle',
    defaultMessage: 'SMS Sent: {count}',
  },
  deliveredCount: {
    id: 'SmsMailingEndedContentMessages.deliveredCount',
    defaultMessage: 'Delivered: {count}',
  },
  notDeliveredCount: {
    id: 'SmsMailingEndedContentMessages.notDeliveredCount',
    defaultMessage: 'Not Delivered: {count}',
  },
  delivered: {
    id: 'SmsMailingEndedContentMessages.delivered',
    defaultMessage: 'Delivered',
  },
  notDelivered: {
    id: 'SmsMailingEndedContentMessages.notDelivered',
    defaultMessage: 'Not Delivered',
  },
})

export const SmsMailingEndedContent: FC<SmsMailingEndedContentProps> = (
  props,
) => {
  const { mailing, onDeleteMailing, forceAction } = props

  const intl = useIntl()
  const { setIsDeleteSubmodalOpen, deleteSubmodalComponent } =
    useDeleteMailingSubmodal({ onDeleteMailing })

  const mailingHistoryId = mailing.mailingHistory?.mailingHistoryId
  const { smses, loading, totalCount } = useSmses({
    mailingHistoryId: mailingHistoryId,
    filters: [
      {
        name: 'page',
        value: '0'
      },
      {
        name: 'limit',
        value: '1000'
      }
    ]
  })

  useEffect(() => {
    if (forceAction) setIsDeleteSubmodalOpen(true)
  }, [])

  const overviewContent = (
    <>
      <div className={styles.OverviewHeader}>
        <div className={styles.OverviewHeader__title}>
          {intl.formatMessage(SmsMailingEndedContentMessages.overviewTitle, {
            count: totalCount,
          })}
        </div>

        <div className={styles.OverviewHeader__statuses}>
          <div className={styles.OverviewStatusItem}>
            <div className={styles.OverviewStatusItem__greenCircle} />
            <div className={styles.OverviewStatusItem__text}>
              {intl.formatMessage(
                SmsMailingEndedContentMessages.deliveredCount,
                {
                  count: smses.filter(
                    (sms) => sms.smsStatusId === SMS_STATUSES.DELIVERED,
                  ).length,
                },
              )}
            </div>
          </div>

          <div className={styles.OverviewStatusItem}>
            <div className={styles.OverviewStatusItem__redCircle} />
            <div className={styles.OverviewStatusItem__text}>
              {intl.formatMessage(
                SmsMailingEndedContentMessages.notDeliveredCount,
                {
                  count: smses.filter((sms) =>
                    [
                      SMS_STATUSES.NOT_DELIVERED,
                      SMS_STATUSES.DELIVERY_FAIL,
                    ].includes(sms.smsStatusId),
                  ).length,
                },
              )}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.OverviewBody}>
        {smses.map((sms) => (
          <div key={sms.smsId} className={styles.Sms}>
            <div className={styles.Sms__receiver}>
              <div className={styles.Sms__receiver__phone}>
                {formatPhoneNumber(sms.numberReceiver)}
              </div>
              {/*TODO ADD CONTACT NAME*/}
              <div className={styles.Sms__receiver__contact}>{}</div>
            </div>

            <div
              className={clsx(
                sms.smsStatusId === SMS_STATUSES.DELIVERED
                  ? styles.Sms__green
                  : styles.Sms__red,
              )}
            >
              {sms.smsStatusId === SMS_STATUSES.DELIVERED
                ? intl.formatMessage(SmsMailingEndedContentMessages.delivered)
                : intl.formatMessage(
                    SmsMailingEndedContentMessages.notDelivered,
                  )}
            </div>
          </div>
        ))}
      </div>
    </>
  )

  return (
    <>
      <div className={styles.Container}>
        <div className={styles.Overview}>
          <LoadingContent loading={loading} content={overviewContent} />
        </div>

        <Button
          text={intl.formatMessage(CommonButtonMessages.delete)}
          variant={'danger'}
          icon={'trash'}
          onClick={() => setIsDeleteSubmodalOpen(true)}
          additionalClassNames={[styles.Button]}
        />
      </div>
      {deleteSubmodalComponent}
    </>
  )
}
