import React, { useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { defineMessages, useIntl } from 'react-intl'
import { PhoneMultipleSettings } from '../_components/phone-multiple-settings/PhoneMultipleSettings'
import {
  PhoneSourceVariant,
  Source,
} from '../_hooks/use-add-source/useAddSource'
import { useModal } from '../../../app/hooks/useModal'
import { useSnackbar } from '../../../app/hooks/useSnackbar'
import { ReactComponent as ForwardToIcon } from '../../../assets/icons/forward-to.svg'
import { CommonButtonMessages } from '../../../config/intl/common-messages/common-button-messages/CommonButtonMessages'
import {
  redirectionCreateDatagate,
  redirectionUpdateDatagate,
} from '../../../sdk/datagates/api/redirection'
import { useBackendErrorCodes } from '../../../sdk/datagates/helpers/_common/use-backend-error-codes'
import { RedirectionListResponse } from '../../../sdk/datagates/types/redirection/_crud/list'
import { formatPhoneNumber } from '../../../sdk/formatters/format-phone-number'
import { useContactGroups } from '../../../sdk/hooks/use-contact-groups/useContactGroups'
import { useContacts } from '../../../sdk/hooks/use-contacts/useContacts'
import { useDevices } from '../../../sdk/hooks/use-devices/useDevices'
import { REDIRECTION_TYPES } from '../../../sdk/hooks/use-redirection-types/constants/RedirectionTypes'
import { InputField } from '../../../shared/lib/form/form-field-adapters/v2/input-field/InputField'
import { MultiselectField } from '../../../shared/lib/form/form-field-adapters/v2/multiselect-field/MultiselectField'
import { TextareaField } from '../../../shared/lib/form/form-field-adapters/v2/textarea-field/TextareaField'
import { useFormRules } from '../../../shared/lib/form/form-rules'
import { getErrorMessage } from '../../../shared/lib/utils/get-error-message/getErrorMessage'
import { Drawer } from '../../../shared/ui-kit-2/data-display/drawer/Drawer'
import { Button } from '../../../shared/ui-kit-2/inputs/button/Button'
import { MultiselectOption } from '../../../shared/ui-kit-2/inputs/multiselect/Multiselect'
import { LC } from '../../../tests/e2e/locators'
import { LoadingContent } from '../../loading-content/LoadingContent'
import { SNACKBAR_TYPES } from '../../snackbar/SnackbarProvider'
import { RedirectionSettingsEmail } from './components/redirection-settings-email/RedirectionSettingsEmail'
import { RedirectionSettingsTelegram } from './components/redirection-settings-telegram/RedirectionSettingsTelegram'
import styles from './styles.module.scss'
import {useUserProfile} from "../../../pages/lk/subpages/purchase-process/hooks/useUserProfile";
import {useDialog} from "../../../app/hooks/useDialog";
import {DialogVersion} from "../../../store/reducers/dialog/types";

interface RedirectionSettingsModalProps {
  deviceId?: number
  redirectionTypeId: number
  redirection?: RedirectionListResponse['redirections'][0]
  handleFetch?: () => Promise<void>
  handleDeleteRedirection?: (redirectionId: number) => Promise<void>
  phoneSourceVariant?: PhoneSourceVariant
}

type RedirectionSettingsFormType = {
  forwardingName: string
  deviceIds: Array<string>
  autoReplyMessage?: string
}

const RedirectionSettingsModalMessages = defineMessages({
  titleCall: {
    id: 'RedirectionSettingsModalMessages.titleCall',
    defaultMessage: 'Create call notification',
  },
  titleSms: {
    id: 'RedirectionSettingsModalMessages.titleSms',
    defaultMessage: 'Create SMS forwarding rule',
  },
  titleAutoReply: {
    id: 'RedirectionSettingsModalMessages.titleAutoReply',
    defaultMessage: 'Create Auto Reply',
  },
  forwardingNameLabel: {
    id: 'RedirectionSettingsModalMessages.forwardingNameLabel',
    defaultMessage: 'Forwarding Name',
  },
  forwardingNamePlaceholder: {
    id: 'RedirectionSettingsModalMessages.forwardingNamePlaceholder',
    defaultMessage: 'Any name for your convenience',
  },
  deviceIdsLabel: {
    id: 'RedirectionSettingsModalMessages.deviceIdsLabel',
    defaultMessage: 'Devices',
  },
  deviceIdsPlaceholder: {
    id: 'RedirectionSettingsModalMessages.deviceIdsPlaceholder',
    defaultMessage: 'Any',
  },
  phonesLabel: {
    id: 'RedirectionSettingsModalMessages.phonesLabel',
    defaultMessage: 'Phone numbers',
  },
  forwardTo: {
    id: 'RedirectionSettingsModalMessages.forwardTo',
    defaultMessage: 'Forward to',
  },
  emailLabel: {
    id: 'RedirectionSettingsModalMessages.emailLabel',
    defaultMessage: 'Email',
  },
  telegramLabel: {
    id: 'RedirectionSettingsModalMessages.telegramLabel',
    defaultMessage: 'Telegram',
  },
  autoReplyMessageLabel: {
    id: 'RedirectionSettingsModalMessages.autoReplyMessageLabel',
    defaultMessage: 'Reply message',
  },
  autoReplyMessagePlaceholder: {
    id: 'RedirectionSettingsModalMessages.autoReplyMessagePlaceholder',
    defaultMessage: 'Enter SMS message you want to be sent as reply',
  },
  add: {
    id: 'RedirectionSettingsModalMessages.add',
    defaultMessage: 'Add',
  },
  deleteItemName: {
    id: 'RedirectionSettingsModalMessages.deleteItemName',
    defaultMessage: 'redirection',
  },
  positiveAdded: {
    id: 'RedirectionSettingsModalMessages.positiveAdded',
    defaultMessage: 'Redirection is successfully created',
  },
  negativeAdded: {
    id: 'RedirectionSettingsModalMessages.negativeAdded',
    defaultMessage: 'An error occurred while creating redirection',
  },
  positiveUpdated: {
    id: 'RedirectionSettingsModalMessages.positiveUpdated',
    defaultMessage: 'Redirection is successfully updated',
  },
  negativeUpdated: {
    id: 'RedirectionSettingsModalMessages.negativeUpdated',
    defaultMessage: 'An error occurred while updating redirection',
  },
})

export const RedirectionSettingsModal = () => {
  const { handleHideModal, props } = useModal()

  const {
    deviceId = undefined,
    redirectionTypeId,
    redirection,
    handleFetch = null,
    handleDeleteRedirection = null,
    phoneSourceVariant,
  } = props as RedirectionSettingsModalProps

  const intl = useIntl()
  const { handleOpenSnackbar } = useSnackbar()
  const { handleOpenDialog, handleHideDialog } = useDialog()
  const { resolveBackendError } = useBackendErrorCodes()
  const { ruleRequired } = useFormRules()
  const [allPhones, setAllPhones] = useState<boolean>(false)
  const { contacts } = useContacts({})
  const { contactGroups } = useContactGroups({})
  const { devices, loading } = useDevices({ page: 0, limit: 1000, takeAll: true })
  const {
    userProfileData,
  } = useUserProfile();

  type Errors = {
    email?: boolean,
    devices?: boolean,
    phones?: boolean
  };

  const [errors, setErrors] = useState<Errors>({});

  const deviceOptions = useMemo(
    () =>
      devices.map<MultiselectOption>((d) => ({
        key: d.dongleId.toString(),
        label: d.name,
        value: d.dongleId.toString(),
      })),
    [devices],
  )

  const [sources, setSources] = useState<Source[]>([])
  const [telegramIds, setTelegramIds] = useState<string[]>(
    redirection?.toTelegramChatIds.map((t) => t.toString()) ?? [],
  )
  const [emails, setEmails] = useState<string[]>(
    redirection?.toEmails.map((t) => t.toString()) ?? [],
  )

  const isAutoReply = redirectionTypeId === REDIRECTION_TYPES.AUTO_REPLY

  useEffect(() => {
    const phoneSources =
      redirection?.fromPhones.map<Source>((p) => {
        return {
          sourceType: 'phone',
          sourceName: formatPhoneNumber(p),
          sourceValue: p,
        }
      }) ?? []

    const contactSources =
      redirection?.fromContactIds.map<Source>((cId) => {
        const contact = contacts.find((c) => c.contactId.toString() === cId)
        return {
          sourceType: 'contacts',
          sourceName: contact
            ? `${contact.name} ${formatPhoneNumber(contact.phone)}`
            : '',
          sourceValue: cId.toString(),
        }
      }) ?? []

    const contactGroupSources =
      redirection?.fromContactGroupIds.map<Source>((cgId) => {
        const contactGroup = contactGroups.find(
          (cg) => cg.contactGroupId.toString() === cgId,
        )
        return {
          sourceType: 'contact-groups',
          sourceName: contactGroup?.name ?? '',
          sourceValue: cgId.toString(),
        }
      }) ?? []

    const allSources = [
      ...phoneSources,
      ...contactSources,
      ...contactGroupSources,
    ]

    setSources(allSources)
  }, [contacts, contactGroups])

  const redirectionTypeForCondition = useMemo(
    () => (redirectionTypeId === REDIRECTION_TYPES.SMS ? 'sms' : 'call'),
    [],
  )

  const onSubmit = async (values: RedirectionSettingsFormType) => {
    try {
      const condition: Array<any> = userProfileData?.userId ? ['and', ['=', 'dongle_user_id', userProfileData?.userId]] : [];

      const contactGroups = sources
        .filter((s) => s.sourceType === 'contact-groups')
        .map((cg) => +cg.sourceValue)
      const contacts = sources.some((s) => s.sourceType === 'all-contacts')
        ? []
        : sources
            .filter((s) => s.sourceType === 'contacts')
            .map((c) => +c.sourceValue)
      const phones = sources
        .filter((s) => s.sourceType === 'phone')
        .map((p) => p.sourceValue)

      if (values.deviceIds.length > 0)
        condition.push([
          'in',
          'dongle_dongle_id',
          values.deviceIds.map((dId) => +dId),
        ])

      if (contacts.length > 0)
        condition.push([
          'in',
          `${redirectionTypeForCondition}_contact_id`,
          contacts,
        ])

      if (contactGroups.length > 0)
        condition.push([
          'intersect_source',
          `${redirectionTypeForCondition}_initial_contact_group_ids`,
          contactGroups,
        ])

      if (!allPhones && phones.length > 0)
        condition.push([
          'in',
          `${redirectionTypeForCondition}_number_caller`,
          phones.map(phone => `+${phone}`),
        ])

      const params: Object = {
        emails: emails,
        telegram_chat_ids: telegramIds,
        auto_reply_message: values.autoReplyMessage,
      }

      let fetchErrors: Errors = {};

      if (!isAutoReply && devices.length === 0) {
        fetchErrors = {
          ...fetchErrors,
          devices: true,
        }
      }

      if (phones.length === 0) {
        fetchErrors = {
          ...fetchErrors,
          phones: true
        }
      }

      if (!isAutoReply && emails.length === 0) {
        fetchErrors = {
          ...fetchErrors,
          email: true
        }
      }

      setErrors(fetchErrors)

      if (!redirection) {
        await redirectionCreateDatagate({
          redirection_type_id: redirectionTypeId,
          name: values.forwardingName,
          condition,
          params,
        })
      } else {
        const urlParams = [
          {
            name: 'redirection_id',
            value: redirection.redirectionId.toString(),
          },
        ]

        await redirectionUpdateDatagate(
          {
            redirection_type_id: redirectionTypeId,
            name: values.forwardingName,
            condition,
            params,
          },
          urlParams,
        )
      }

      setErrors({})
      await handleFetch?.()

      handleHideModal()
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.success,
        text: intl.formatMessage(
          redirection
            ? RedirectionSettingsModalMessages.positiveUpdated
            : RedirectionSettingsModalMessages.positiveAdded,
        ),
      })
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: resolveBackendError(
          getErrorMessage(e),
          intl.formatMessage(
            redirection
              ? RedirectionSettingsModalMessages.negativeUpdated
              : RedirectionSettingsModalMessages.negativeAdded,
          ),
        ),
      })
    }
  }

  const onDelete = () => {
    if (handleDeleteRedirection && redirection) {
      handleOpenDialog({
        version: DialogVersion.v2,
        props: {
          title: 'Delete call notification?',
          subtitle: 'This action cannot be undone',
          cancel: {
            text: 'Cancel',
            onClick: () => handleHideDialog()
          },
          submit: {
            icon: 'trash',
            text: 'Delete',
            onClick: () => {
              handleHideDialog();
              handleDeleteRedirection(redirection.redirectionId);
            }
          },
        }
      })
    }
  }

  const getCreateTitle = () => {
    switch (redirection?.redirectionTypeId ?? redirectionTypeId) {
      case REDIRECTION_TYPES.SMS:
        return intl.formatMessage(RedirectionSettingsModalMessages.titleSms)
      case REDIRECTION_TYPES.CALL:
        return intl.formatMessage(RedirectionSettingsModalMessages.titleCall)
      case REDIRECTION_TYPES.AUTO_REPLY:
        return intl.formatMessage(
          RedirectionSettingsModalMessages.titleAutoReply,
        )
    }
  }

  const modalTitle = redirection?.name ? redirection.name : getCreateTitle()

  const initialDeviceIds = useMemo(() => {
    if (redirection?.deviceIds)
      return redirection.deviceIds.map((id) => id.toString())
    if (deviceId) return [deviceId.toString()]
    return []
  }, [])

  const initialValues = useMemo(
    () => ({
      forwardingName: redirection?.name,
      deviceIds: initialDeviceIds,
      emails: redirection?.toEmails
        ? redirection.toEmails.map((e) => ({ email: e }))
        : [],
      telegrams: redirection?.toTelegramChatIds
        ? redirection.toTelegramChatIds.map((t) => ({ telegram: t }))
        : [],
      autoReplyMessage: redirection?.autoReplyMessage,
    }),
    [],
  )

  const removeError = (name: string) => {
    setErrors(prev => ({
      ...prev,
      [name]: false
    }))
  }

  const content = (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, submitting }) => {
        return (
          <form onSubmit={handleSubmit} className={styles.Container}>
            <div className={styles.SectionFrom}>
              <InputField
                testId={LC.REDIRECTIONS.MODAL.INPUTS.NAME}
                name={'forwardingName'}
                validate={ruleRequired()}
                label={intl.formatMessage(
                  RedirectionSettingsModalMessages.forwardingNameLabel,
                )}
                placeholder={intl.formatMessage(
                  RedirectionSettingsModalMessages.forwardingNamePlaceholder,
                )}
                markAsRequired={true}
              />

              <MultiselectField
                testId={LC.REDIRECTIONS.MODAL.INPUTS.DEVICES_SELECT}
                name={'deviceIds'}
                options={deviceOptions}
                defaultValues={deviceOptions.filter((o) =>
                  initialDeviceIds.map((id) => id).includes(o.value),
                )}
                label={intl.formatMessage(
                  RedirectionSettingsModalMessages.deviceIdsLabel,
                )}
                placeholder={intl.formatMessage(
                  RedirectionSettingsModalMessages.deviceIdsPlaceholder,
                )}
                error={errors?.devices ? `${errors?.devices}` : undefined}
              />

              <PhoneMultipleSettings
                sources={sources}
                setSources={setSources}
                phoneSourceVariant={phoneSourceVariant}
                allPhones={allPhones}
                setAllPhones={setAllPhones}
                error={errors?.phones}
                onChange={() => removeError('phones')}
              />
            </div>

            {!isAutoReply && (
              <div className={styles.ForwardTo}>
                <div>
                  {intl.formatMessage(
                    RedirectionSettingsModalMessages.forwardTo,
                  )}
                </div>
                <ForwardToIcon />
              </div>
            )}

            <div className={styles.SectionTo}>
              {!isAutoReply && (
                <RedirectionSettingsEmail
                  emails={emails}
                  setEmails={setEmails}
                  error={errors?.email}
                  onChange={() => removeError('email')}
                />
              )}
              {!isAutoReply && (
                <RedirectionSettingsTelegram
                  telegramIds={telegramIds}
                  setTelegramIds={setTelegramIds}
                />
              )}

              {isAutoReply && (
                <TextareaField
                  name={'autoReplyMessage'}
                  validate={ruleRequired()}
                  label={intl.formatMessage(
                    RedirectionSettingsModalMessages.autoReplyMessageLabel,
                  )}
                  placeholder={intl.formatMessage(
                    RedirectionSettingsModalMessages.autoReplyMessagePlaceholder,
                  )}
                  additionalClassNames={[styles.AutoReplyMessageAdditional]}
                />
              )}
            </div>

            <div className={styles.Actions}>
              {handleDeleteRedirection && (
                <Button
                  text={intl.formatMessage(CommonButtonMessages.delete)}
                  additionalClassNames={[styles.SubmitBtn]}
                  onClick={onDelete}
                  icon={'trash'}
                  variant={'danger'}
                />
              )}

              <Button
                type={'submit'}
                text={intl.formatMessage(CommonButtonMessages.save)}
                additionalClassNames={[styles.SubmitBtn]}
                disabled={submitting}
                loading={submitting}
                testId={LC.REDIRECTIONS.MODAL.SUBMIT_BTN}
              />
            </div>
          </form>
        )
      }}
    />
  )

  return (
    <Drawer
      title={modalTitle}
      isOpen={true}
      close={handleHideModal}
      additionalClassNames={[styles.DrawerAdditional]}
    >
      <LoadingContent loading={loading} content={content} />
    </Drawer>
  )
}
