export enum TARIFF_PACKAGE_ID {
  MONTH = 1,
  ANNUAL = 2,
}

export const TARIFF_PACKAGE_IDS_EN_TO_RU = {
    [TARIFF_PACKAGE_ID.MONTH]: 3,
    [TARIFF_PACKAGE_ID.ANNUAL]: 4
}

export enum TARIFF_PACKAGE_IDS {
  TARIFF_PACKAGE_MONTH_EUROPE = 1,
  TARIFF_PACKAGE_YEAR_EUROPE = 2,
  TARIFF_PACKAGE_MONTH_RUSSIAN = 3,
  TARIFF_PACKAGE_YEAR_RUSSIAN = 4,
  TARIFF_PACKAGE_MONTH_UK = 5,
  TARIFF_PACKAGE_YEAR_UK = 6
}

export const TARIFF_MONTH_PACKAGES_IDS = [
  TARIFF_PACKAGE_IDS.TARIFF_PACKAGE_MONTH_EUROPE,
  TARIFF_PACKAGE_IDS.TARIFF_PACKAGE_MONTH_UK,
  TARIFF_PACKAGE_IDS.TARIFF_PACKAGE_MONTH_RUSSIAN,
]

export const TARIFF_YEAR_PACKAGES_IDS = [
  TARIFF_PACKAGE_IDS.TARIFF_PACKAGE_YEAR_EUROPE,
  TARIFF_PACKAGE_IDS.TARIFF_PACKAGE_YEAR_UK,
  TARIFF_PACKAGE_IDS.TARIFF_PACKAGE_YEAR_RUSSIAN,
]

export const ORDER_PAYMENT_TYPE_IDS = {
  BANK_CARD: 1,
  WIRE_TRANSFER: 2,
  BALANCE: 3,
}
