import {useDispatch} from "react-redux";
import {clearDialog, setDialog} from "../../store/reducers/dialog";
import {DialogVersion} from "../../store/reducers/dialog/types";
import {ReactNode} from "react";

export const useDialog = () => {
  const dispatch = useDispatch()

  const handleOpenDialog = ({ version, props, cookedTemplate } : {
    version: DialogVersion,
    props: Record<string, unknown>,
    cookedTemplate?: ReactNode
  }) => {
    dispatch(
      setDialog({
        isOpen: true,
        version,
        cookedTemplate,
        props
      })
    )
  }

  const handleHideDialog = () => {
    dispatch(clearDialog());
  }

  return {
    handleOpenDialog,
    handleHideDialog
  }
}