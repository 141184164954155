type GroupedByDate<T> = [string, T[]][];

/**
 * Универсальная функция для группировки массива по указанному вложенному ключу
 * и возвращения отсортированного массива.
 * @param array - Массив данных для группировки.
 * @param keyPath - Ключ или путь к ключу (например, 'mailingsObj.date').
 * @param formatFunction - Опциональная функция для форматирования даты.
 * @returns Массив с группами, отсортированными по датам сверху вниз. Элементы без даты — в конце.
 */
export const groupArrayByDate = <T>(
  array: T[],
  keyPath: string,
  formatFunction?: (date: Date) => string
): GroupedByDate<T> => {
  const grouped = array.reduce<Record<string, T[]>>((acc, item) => {
    const rawDate = keyPath.split('.').reduce<any>((obj, key) => obj?.[key], item);

    const formattedDate = rawDate
      ? formatFunction
        ? formatFunction(new Date(rawDate))
        : new Date(rawDate).toISOString().split('T')[0] // По умолчанию формат YYYY-MM-DD
      : 'No date';

    if (!acc[formattedDate]) {
      acc[formattedDate] = [];
    }
    acc[formattedDate].push(item);

    return acc;
  }, {});

  return Object.entries(grouped).sort(([dateA], [dateB]) => {
    if (dateA === 'No date') return 1; // 'No date' всегда в конце
    if (dateB === 'No date') return -1; // 'No date' всегда в конце
    return new Date(dateB).getTime() - new Date(dateA).getTime(); // Сортировка по убыванию
  });
};
