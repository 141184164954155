import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../../../../../../app/hooks/useModal'
import { MODAL_TYPES } from '../../../../../../../../layouts/modals/ModalsProvider'
import { Card } from '../../../../../../../../shared/ui-kit-2/data-display/card/Card'
import { ContactsPageContext } from '../../../../contexts/ContactsPageContext'
import styles from './styles.module.scss'
import ControlHeader from "../../../../../../../../shared/ui-kit-3/components/ControlHeader/ControlHeader";
import { ReactComponent as UserPlusIcon } from '../../../../../../../../assets/icons/user-plus.svg';
import {Table} from "../../../../../../../../shared/ui-kit-2/data-display/table/Table";
import {LC} from "../../../../../../../../tests/e2e/locators";
import {
  useTableFooterInfo
} from "../../../../../../../../layouts/tables/_helpers/hooks/use-table-footer-info/useTableFooterInfo";
import {
  HeaderCol,
  TABLE_FILTERS_VARIANTS
} from "../../../../../../../../shared/ui-kit-2/data-display/table/components/TableHeader/TableHeader";
import {
  DEFAULT_COLS,
  DEFAULT_COLS_VARIANTS
} from "../../../../../../../../shared/ui-kit-2/data-display/table/contants/defaultCols";
import {
  ContactTableRows
} from "../../../../../../../../layouts/tables/contact-table/components/contact-table-rows/ContactTableRows";
import {useTableFilters} from "../../../../../../../../shared/ui-kit-2/data-display/table/hooks/useTableFilters";
import {
  tableFiltersToUrlFilters
} from "../../../../../../../../shared/lib/utils/table-filters-to-url-filters/tableFiltersToUrlFilters";


const ContactListMessages = defineMessages({
  title: {
    id: 'ContactListMessages.title',
    defaultMessage: 'Contacts',
  },
  searchPlaceholder: {
    id: 'ContactListMessages.searchPlaceholder',
    defaultMessage: 'Search',
  },
  addContact: {
    id: 'ContactListMessages.addContact',
    defaultMessage: 'Add contact',
  },
})

export const ContactList = () => {
  const intl = useIntl()

  const {
    contacts,
    contactsTotalCount,
    contactsLoading,
    handleFetchContacts,
    handleDeleteContact,
    handleFetchContactGroups,
    setFilters
  } = useContext(ContactsPageContext)

  const { handleOpenModal } = useModal()
  const [searchLoading, setSearchLoading] = useState<boolean>(false)
  const sliceContacts = contacts.slice(0, 3000)

  const openAddContactModal = () => {
    handleOpenModal({
      type: MODAL_TYPES.CONTACT_SETTINGS_MODAL,
      props: {
        handleFetchContacts,
        handleFetchContactGroups,
      },
    })
  }

  const COLS = {
    name: 'name',
    groups: 'groups'
  }

  const headerCols = [
    {
      id: COLS.name,
      filterType: TABLE_FILTERS_VARIANTS.TEXT_FIELD,
      label: 'Name',
      defaultText: 'Enter the name',
    },
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.phone],
    {
      id: COLS.groups,
      label: 'Groups',
    },
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.actions],
  ] as HeaderCol[];

  const {
    filters,
    handleChangeFilters,
    handlePageChange,
    debouncedFilters,
  } = useTableFilters({
    queryIds: [
      ...headerCols.map(({ id }) => id),
      'page',
      'limit',
    ],
    defaultFilterValues: [
      {
        name: 'page',
        value: 1
      },
      {
        name: 'limit',
        value: 50
      }
    ]
  })

  const currentFilters = useMemo(() => tableFiltersToUrlFilters([
    { name: 'page', value: debouncedFilters?.page - 1 },
    { name: 'limit', value: debouncedFilters?.limit },
    { name: 'name', value: debouncedFilters?.name },
    { name: 'phone', value: debouncedFilters?.phone && encodeURIComponent(debouncedFilters?.phone) },
  ]), [debouncedFilters])

  useEffect(() => {
    setFilters(currentFilters);
  }, [currentFilters]);

  const { footerInfo } = useTableFooterInfo(filters.page, filters.limit, contactsTotalCount)

  const rowGroups = ContactTableRows(
    contacts,
    handleFetchContacts,
    handleFetchContactGroups,
    handleDeleteContact,
  )

  const loading = contactsLoading || searchLoading;

  return (
    <Card additionalClassNames={[styles.CardAdditional]}>
      <ControlHeader
        title={'Contacts'}
        counter={{
          min: contacts.length,
          max: contactsTotalCount
        }}
        actions={[
          {
            variant: 'greenFilled',
            size: 'md',
            text: intl.formatMessage(ContactListMessages.addContact),
            prefix: <UserPlusIcon />,
            onClick: openAddContactModal,
          },
        ]}
        loading={loading}
      />

      <Table
        headerCols={headerCols}
        filters={filters}
        handleChangeFilters={handleChangeFilters}
        name={'ContactTable'}
        rowGroups={rowGroups}
        currentPage={filters.page}
        totalCount={contactsTotalCount}
        onPageChange={handlePageChange}
        itemsPerPage={filters.limit}
        loading={loading}
        footerInfo={footerInfo}
        columnWidths={[1, 1, 1, 1]}
        testId={LC.CALLS.TABLE._}
      />
    </Card>
  )
}
