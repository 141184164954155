import { parseStringAsUTCDate } from '../../../../../../../shared/lib/utils/date-utils/parseStringAsUTCDate'
import {
  MailingGetBackendResponse,
  MailingGetResponse,
} from '../../../../../types/mailing/_crud/get'

export const mapMailingGetBackendToMailingGet = (
  item: MailingGetBackendResponse,
): MailingGetResponse => {
  const {
    name,
    mailing_type_id,
    mailing_status_id,
    mailing_phones,
    mailing_id,
    mailing_history,
    count_mailing_phones,
    text,
    dongle,
    scheduled_at,
    created_at,
    is_call_record,
    file,
  } = item.data

  const scheduledAtDate = scheduled_at
    ? parseStringAsUTCDate(scheduled_at)
    : undefined

  const createdAtDate = parseStringAsUTCDate(created_at)

  return {
    mailingId: mailing_id,
    name: name,
    mailingTypeId: mailing_type_id,
    mailingStatusId: mailing_status_id,
    mailingPhones: mailing_phones?.map((mp) => ({
      mailingPhoneId: mp.mailing_phone_id,
      phone: mp.phone,
      mailingId: mp.mailing_id,
    })),
    countMailingPhones: count_mailing_phones,
    text: text,
    dongle: {
      dongleId: dongle.dongle_id,
      name: dongle.name,
      number: dongle.number,
    },
    mailingHistory: mailing_history
      ? {
          mailingHistoryId: mailing_history.mailing_history_id,
          mailingId: mailing_history.mailing_id,
          createdAt: parseStringAsUTCDate(mailing_history.created_at),
        }
      : undefined,
    file: {
      cdnUrl: file?.cdn_url,
    },
    scheduledAt: scheduledAtDate,
    createdAt: createdAtDate,
    isCallRecord: is_call_record
  }
}
