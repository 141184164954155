import React, {useEffect, useMemo, useRef, useState} from 'react'
import styles from '../../styles.module.scss'
import { Card } from './components/card/Card'
import {ProfileFormMessages, ProfileFormType} from './components/profile-page-form/ProfileForm'
import {CloudTabs, CloudTabType} from "../../../../../../shared/ui-kit-2/navigation/cloud-tabs/CloudTabs";
import {useNavigate} from "react-router";
import {defineMessages, useIntl} from "react-intl";
import {Route} from "../../../../../../config/routes/enums/route";
import {RoutePath} from "../../../../../../config/routes/constants/routePath";
import {useScrollToAnchor} from "../../../../../../shared/lib/hooks/useScrollToAnchor";
import {useBackendErrorCodes} from "../../../../../../sdk/datagates/helpers/_common/use-backend-error-codes";
import {useSnackbar} from "../../../../../../app/hooks/useSnackbar";
import {useDevices} from "../../../../../../sdk/hooks/use-devices/useDevices";
import {useCountrySelectOptions} from "../../../../../../sdk/hooks/use-countries/useCountrySelectOptions";
import {USER_TYPE_IDS} from "../../../../../../sdk/datagates/types/user/_crud/get";
import {useModal} from "../../../../../../app/hooks/useModal";
import {useDialog} from "../../../../../../app/hooks/useDialog";
import {userGetDatagate, userUpdateDatagate} from "../../../../../../sdk/datagates/api/user";
import {compareAddresses} from "../../../../../../shared/lib/utils/compare-addresses";
import {SNACKBAR_TYPES} from "../../../../../../layouts/snackbar/SnackbarProvider";
import {getErrorMessage} from "../../../../../../shared/lib/utils/get-error-message/getErrorMessage";
import {DialogVersion} from "../../../../../../store/reducers/dialog/types";
import {
  SegmentedControlOptionProps
} from "../../../../../../shared/ui-kit-2/inputs/segmented-control/components/SegmentedControlOption";
import {MODAL_TYPES} from "../../../../../../layouts/modals/ModalsProvider";
import {ItemWrapper} from "../../../../../../shared/ui-kit/data-display/ItemWrapper/ItemWrapper";
import {Avatar} from "../../../../../../shared/ui-kit-2/data-display/avatar/Avatar";
import {Counter} from "../../../../../../shared/ui-kit-2/data-display/counter/Counter";
import {MainMessages} from "../../../../../../shared/intl-messages/MainMessages";
import {SegmentedControl} from "../../../../../../shared/ui-kit-2/inputs/segmented-control/SegmentedControl";
import {Button} from "../../../../../../shared/ui-kit-2/inputs/button/Button";
import {Form} from "react-final-form";
import {LoadingContent} from "../../../../../../layouts/loading-content/LoadingContent";
import {InputField} from "../../../../../../shared/lib/form/form-field-adapters/v2/input-field/InputField";
import {
  PhoneInputFieldNumber
} from "../../../../../../shared/lib/form/form-field-templates/v2/phone-input-field-new/PhoneInputFieldNew";
import {useFormRules} from "../../../../../../shared/lib/form/form-rules";
import {
  SelectSearchField
} from "../../../../../../shared/lib/form/form-field-adapters/v2/select-search-field/SelectSearchField";

const PROFILE_PAGE_TABS_IDS = {
  ACCOUNT: 'account',
  LEGAL_ADDRESS: 'legal_address',
  DELIVERY_ADDRESS: 'delivery_address',
}

export const PROFILE_ANCHORS = {
  [PROFILE_PAGE_TABS_IDS.ACCOUNT]: '#account',
  [PROFILE_PAGE_TABS_IDS.LEGAL_ADDRESS]: '#legal_address',
  [PROFILE_PAGE_TABS_IDS.DELIVERY_ADDRESS]: '#delivery_address',
}

const ProfilePageMessages = defineMessages({
  account: {
    id: 'CallsPageMessages.account',
    defaultMessage: 'Account',
  },
  legalAddress: {
    id: 'CallsPageMessages.legalAddress',
    defaultMessage: 'Billing address',
  },
  delivery: {
    id: 'CallsPageMessages.delivery',
    defaultMessage: 'Shipping address',
  },
  balanceHistory: {
    id: 'CallsPageMessages.balanceHistory',
    defaultMessage: 'Balance history',
  },
});

const SCROLL_OFFSET = 100;

const ProfilePageEn = () => {
  const intl = useIntl();
  const navigate = useNavigate()
  useScrollToAnchor(SCROLL_OFFSET);
  const { resolveBackendError } = useBackendErrorCodes();
  const { handleOpenSnackbar } = useSnackbar();

  const touchedFields = useRef(new Set<string>());

  const handleFocus = (fieldName: string) => {
    touchedFields.current.add(fieldName);
  };

  const {
    devices: devices,
    totalCount: devicesCount,
    loading: fetchActiveDevicesLoading,
  } = useDevices({
    page: 0,
    limit: 1000,
    takeAll: true,
    isActive: true,
  });

  const { countryOptions, loading: fetchCountriesLoading } = useCountrySelectOptions();
  const { ruleMustBeNumber, ruleNotIncludeNumbers } = useFormRules();

  const [initialValues, setInitialValues] = useState<Partial<ProfileFormType>>({});
  const [userInitialPhone, setUserInitialPhone] = useState<string | null>(null);
  const [fetchUserLoading, setFetchUserLoading] = useState<boolean>(false);
  const [userType, setUserType] = useState(USER_TYPE_IDS.NATURAL_PERSON.toString());
  const [isLegalAddressSame, setIsLegalAddressSame] = useState<boolean>(true);
  const { handleOpenModal } = useModal();
  const isOrganization = useMemo(
    () => userType === USER_TYPE_IDS.ORGANIZATION.toString(),
    [userType],
  );
  const onlineDevicesCount = useMemo(
    () => devices.filter((d) => d.isOnline).length,
    [fetchActiveDevicesLoading],
  );

  const { handleOpenDialog, handleHideDialog } = useDialog();

  const fetchDefaultPageData = async (hidden?: boolean) => {
    try {
      if (!hidden) {
        setFetchUserLoading(true);
      }

      const { data } = await userGetDatagate();
      const { userPhone } = data;
      setUserInitialPhone(userPhone ?? null);
      let initialValues: {
        firstName: string | undefined;
        lastName: string | undefined;
        email: string;
        companyName: string | undefined;
        vatNumber: string | undefined;
        billingEmail: string | undefined;
        countryId: string | undefined;
        postalCode: string | undefined;
        streetAddress1: string | undefined;
        streetAddress2: string | undefined;
        userPhone: string | undefined;
        companyPhone: string | undefined;
        city: string | undefined;
        region: string | undefined;
        legal?: {
          countryId: string | undefined;
          postalCode: string | undefined;
          streetAddress1: string | undefined;
          streetAddress2: string | undefined;
          city: string | undefined;
          region: string | undefined;
        };
      } = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        companyName: data.companyName,
        userPhone: data.userPhone,
        companyPhone: data.companyPhone,
        vatNumber: data.vatNumber,
        billingEmail: data.billingEmail,
        countryId: data.countryId?.toString(),
        postalCode: data.postalCode,
        streetAddress1: data.streetAddress1,
        streetAddress2: data.streetAddress2,
        city: data.city,
        region: data.region,
      };

      let isSameAddress = true;

      if (data?.legal) {
        const legalAddress = {
          countryId: data.legal.countryId?.toString() ?? data.countryId?.toString(),
          postalCode: data.legal.postalCode,
          streetAddress1: data.legal.streetAddress1,
          streetAddress2: data.legal.streetAddress2,
          city: data.legal.city,
          region: data.legal.region,
        };

        initialValues = {
          ...initialValues,
          legal: legalAddress,
        };

        const primaryAddress = {
          countryId: data.countryId?.toString(),
          postalCode: data.postalCode,
          streetAddress1: data.streetAddress1,
          streetAddress2: data.streetAddress2,
          city: data.city,
          region: data.region,
        };

        isSameAddress = compareAddresses(legalAddress, primaryAddress);
      }

      setIsLegalAddressSame(isSameAddress);
      setInitialValues(initialValues);

      setUserType(
        data.userTypeId?.toString() ?? USER_TYPE_IDS.ORGANIZATION.toString(),
      );

      // dispatch(setUser(data));
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: resolveBackendError(
          getErrorMessage(e),
          intl.formatMessage(ProfileFormMessages.fetchUserError),
        ),
      });
    } finally {
      if (!hidden) {
        setFetchUserLoading(false);
      }
    }
  };

  useEffect(() => {
    (async () => {
      await fetchDefaultPageData();
    })();
  }, []);

  const onSetNewProfile = async (values: ProfileFormType) => {
    try {
      !isLegalAddressSame
        ? await userUpdateDatagate({
          user_type_id: +userType,
          name: values.firstName,
          last_name: values.lastName,
          company_name: isOrganization ? values.companyName : '',
          company_phone: isOrganization ? values.companyPhone : '',
          phone: values.userPhone,
          billing_email: values.billingEmail ?? '',
          vat_number: isOrganization ? values.vatNumber : '',
          country_id: +values.countryId,
          postal_code: values.postalCode ?? '',
          street_address_1: values.streetAddress1 ?? '',
          street_address_2: values.streetAddress2 ?? '',
          city: values.city ?? '',
          region: values.region ?? '',
          legal_street_address_1: values?.legal?.streetAddress1 ?? '',
          legal_street_address_2: values?.legal?.streetAddress2 ?? '',
          legal_postal_code: values?.legal?.postalCode ?? '',
          legal_country_id: values.legal?.countryId !== undefined ? +values.legal.countryId : undefined,
          legal_city: values?.legal?.city ?? '',
          legal_region: values?.legal?.region ?? '',
        })
        : await userUpdateDatagate({
          user_type_id: +userType,
          name: values.firstName,
          last_name: values.lastName,
          company_name: isOrganization ? values.companyName : '',
          company_phone: isOrganization ? values.companyPhone : '',
          phone: values.userPhone,
          billing_email: values.billingEmail ?? '',
          vat_number: isOrganization ? values.vatNumber : '',
          country_id: +values.countryId,
          postal_code: values.postalCode ?? '',
          street_address_1: values.streetAddress1 ?? '',
          street_address_2: values.streetAddress2 ?? '',
          city: values.city ?? '',
          region: values.region ?? '',
          legal_street_address_1: values.streetAddress1 ?? '',
          legal_street_address_2: values.streetAddress2 ?? '',
          legal_postal_code: values.postalCode ?? '',
          legal_country_id: +values.countryId,
          legal_city: values.city ?? '',
          legal_region: values.region ?? '',
        });

      handleOpenSnackbar({
        type: SNACKBAR_TYPES.success,
        text: intl.formatMessage(ProfileFormMessages.success),
      });

      void fetchDefaultPageData(true);
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: resolveBackendError(
          getErrorMessage(e),
          intl.formatMessage(ProfileFormMessages.error),
        ),
      });
    }
  };

  const handleChangeTypeProfile = (newUserType: string) => {
    if (userType !== newUserType) {
      handleOpenDialog({
        version: DialogVersion.v2,
        props: {
          title: 'Change profile type?',
          subtitle: 'After this action, you may be asked to enter additional information',
          cancel: {
            text: 'Cancel',
            onClick: () => handleHideDialog()
          },
          submit: {
            variant: 'green',
            text: 'Change',
            onClick: () => {
              setUserType(newUserType);
              handleHideDialog();
            }
          },
        }
      })
    }
  };

  const accountTypeOptions: SegmentedControlOptionProps[] = [
    {
      label: intl.formatMessage(ProfileFormMessages.accountTypePerson),
      value: USER_TYPE_IDS.NATURAL_PERSON.toString(),
      comparedValue: userType,
      onClick: () => handleChangeTypeProfile(USER_TYPE_IDS.NATURAL_PERSON.toString()),
    },
    {
      label: intl.formatMessage(ProfileFormMessages.accountTypeOrganization),
      value: USER_TYPE_IDS.ORGANIZATION.toString(),
      comparedValue: userType,
      onClick: () => handleChangeTypeProfile(USER_TYPE_IDS.ORGANIZATION.toString()),
    },
  ];

  const handleChangePassword = () => {
    handleOpenModal({ type: MODAL_TYPES.CHANGE_PASSWORD });
  };

  const handleEdit = () => {
    handleOpenModal({
      type: MODAL_TYPES.EDIT_PROFILE,
      props: {
        initialValues,
        onClose: fetchDefaultPageData,
        userInitialPhone,
        fetchDefaultPageData,
        userTypeInitial: userType,
        isLegalAddressSameEdit: isLegalAddressSame,
        setIsLegalAddressSame,
      },
    });
  };

  const cloudTabOptions: CloudTabType[] = [
    {
      id: PROFILE_PAGE_TABS_IDS.ACCOUNT,
      label: intl.formatMessage(ProfilePageMessages.account),
      anchorId: PROFILE_PAGE_TABS_IDS.ACCOUNT
    },
    ...(userType === USER_TYPE_IDS.ORGANIZATION.toString() ? [
      {
        id: PROFILE_PAGE_TABS_IDS.LEGAL_ADDRESS,
        label: intl.formatMessage(ProfilePageMessages.legalAddress),
        anchorId: PROFILE_PAGE_TABS_IDS.LEGAL_ADDRESS
      },
    ] : []),
    {
      id: PROFILE_PAGE_TABS_IDS.DELIVERY_ADDRESS,
      label: intl.formatMessage(ProfilePageMessages.delivery),
      anchorId: PROFILE_PAGE_TABS_IDS.DELIVERY_ADDRESS
    },
  ];

  const handleTabChange = (path: string) => {
    navigate(`${RoutePath[Route.Profile]}${PROFILE_ANCHORS[path]}`)
  };

  return (
    <div className={styles.ProfilePage}>
      <div className={styles.Tabs}>
        <CloudTabs
          items={cloudTabOptions}
          defaultTabId={PROFILE_PAGE_TABS_IDS.ACCOUNT}
          handleChange={handleTabChange}
          classes={{
            nav: styles.CloudTabsAdditional
          }}
          enableScrollTracking
          scrollTrackingOffset={SCROLL_OFFSET + 300}
        />
      </div>
      <LoadingContent
        loading={
          fetchCountriesLoading ||
          fetchUserLoading ||
          fetchActiveDevicesLoading ||
          fetchActiveDevicesLoading
        }
        content={(
          <Form
            onSubmit={onSetNewProfile}
            initialValues={initialValues}
            render={({ handleSubmit, form, values, dirtyFields }) => {
              const handleBlur = async (fieldName: string) => {
                // Проверяем, что изменено только это поле
                if (!dirtyFields[fieldName]) return;

                try {
                  await form.submit();
                } catch (error) {
                  console.error(error);
                }
              };

              let nickname = initialValues.email

              if (initialValues.companyName) nickname = initialValues.companyName
              else if (initialValues.lastName && initialValues.firstName)
                nickname = `${initialValues.firstName} ${initialValues.lastName}`

              return (
                <form onSubmit={handleSubmit} className={styles.Cols}>
                  <div id={PROFILE_PAGE_TABS_IDS.ACCOUNT}>
                    <Card>
                      <ItemWrapper additionalClassNames={[styles.NicknameHeader]}>
                        <div className={styles.Header}>
                          <div className={styles.Avatar}>
                            <Avatar />
                          </div>
                          <div className={styles.NicknameSection}>
                            <div className={styles.Nickname}>{nickname}</div>
                            <div className={styles.DevicesAmount}>
                              <Counter
                                color='green'
                                count={onlineDevicesCount}
                                text={intl.formatMessage(MainMessages.Online)}
                              />
                              <Counter
                                color='red'
                                count={devicesCount - onlineDevicesCount}
                                text={intl.formatMessage(MainMessages.Offline)}
                              />
                            </div>
                          </div>
                        </div>
                      </ItemWrapper>
                      <ItemWrapper>
                        <div className={styles.Controls}>
                          <SegmentedControl
                            name={'accountType'}
                            group={accountTypeOptions}
                          />
                          <div className={styles.ChangePassword}>
                            <Button
                              text={intl.formatMessage(
                                ProfileFormMessages.changePassword,
                              )}
                              variant="blackTextGreenBorderOutlined"
                              onClick={handleChangePassword}
                              size={'small'}
                            />
                          </div>
                        </div>
                      </ItemWrapper>
                      <ItemWrapper additionalClassNames={[styles.Card__Grid]}>
                        <InputField
                          name="firstName"
                          validate={ruleNotIncludeNumbers(true)}
                          label={intl.formatMessage(ProfileFormMessages.firstNameLabel)}
                          placeholder={intl.formatMessage(ProfileFormMessages.firstNameLabel)}
                          onFocus={() => handleFocus('firstName')}
                          onBlur={() => handleBlur('firstName')}
                        />
                        <InputField
                          name="lastName"
                          validate={ruleNotIncludeNumbers(true)}
                          label={intl.formatMessage(ProfileFormMessages.lastNameLabel)}
                          placeholder={intl.formatMessage(ProfileFormMessages.lastNameLabel)}
                          onFocus={() => handleFocus('lastName')}
                          onBlur={() => handleBlur('lastName')}
                        />

                        {isOrganization && (
                          <InputField
                            name="companyName"
                            label={intl.formatMessage(ProfileFormMessages.companyNameLabel)}
                            placeholder={intl.formatMessage(ProfileFormMessages.companyNameLabel)}
                            onFocus={() => handleFocus('companyName')}
                            onBlur={() => handleBlur('companyName')}
                          />
                        )}

                        {isOrganization && (
                          <InputField
                            name="vatNumber"
                            validate={ruleMustBeNumber(true)}
                            label={intl.formatMessage(ProfileFormMessages.vatNumberLabel)}
                            placeholder={intl.formatMessage(ProfileFormMessages.vatNumberLabel)}
                            onFocus={() => handleFocus('vatNumber')}
                            onBlur={() => handleBlur('vatNumber')}
                          />
                        )}

                        <div className={styles.fullSizeItem}>
                          <PhoneInputFieldNumber
                            name="userPhone"
                            values={values}
                            label={intl.formatMessage(ProfileFormMessages.phoneNumberLabel)}
                            initialValue={userInitialPhone as string}
                            noHelperText={true}
                            onFocus={() => handleFocus('userPhone')}
                            onBlur={() => handleBlur('userPhone')}
                          />
                        </div>

                        <InputField
                          name="email"
                          label={intl.formatMessage(ProfileFormMessages.emailLabel)}
                          placeholder={intl.formatMessage(ProfileFormMessages.emailLabel)}
                          disabled
                          onFocus={() => handleFocus('email')}
                          onBlur={() => handleBlur('email')}
                        />

                        <InputField
                          name="billingEmail"
                          label={intl.formatMessage(ProfileFormMessages.billingEmailLabel)}
                          placeholder={intl.formatMessage(ProfileFormMessages.billingEmailLabel)}
                          onFocus={() => handleFocus('billingEmail')}
                          onBlur={() => handleBlur('billingEmail')}
                        />
                      </ItemWrapper>
                    </Card>
                  </div>
                  {userType === USER_TYPE_IDS.ORGANIZATION.toString() && (
                    <div id={PROFILE_PAGE_TABS_IDS.LEGAL_ADDRESS}>
                      <Card>
                        <ItemWrapper>
                          <div className={styles.Card__Title}>
                            Billing address
                          </div>
                        </ItemWrapper>
                        <ItemWrapper additionalClassNames={[styles.Card__Grid]}>
                          <SelectSearchField
                            name="legal.countryId"
                            options={countryOptions}
                            label={intl.formatMessage(ProfileFormMessages.countryLabel)}
                            placeholder={intl.formatMessage(ProfileFormMessages.countryPlaceholder)}
                            defaultInputValue={
                              countryOptions.find((c) => c.value === initialValues.legal?.countryId)
                                ?.inputLabel
                            }
                            onFocus={() => handleFocus('legal.countryId')}
                            onBlur={() => handleBlur('legal.countryId')}
                          />

                          <InputField
                            name="legal.postalCode"
                            label={intl.formatMessage(ProfileFormMessages.postalCodeLabel)}
                            placeholder={intl.formatMessage(ProfileFormMessages.postalCodeLabel)}
                            onFocus={() => handleFocus('legal.postalCode')}
                            onBlur={() => handleBlur('legal.postalCode')}
                          />

                          <InputField
                            name="legal.streetAddress1"
                            label={intl.formatMessage(ProfileFormMessages.addressLine1Label)}
                            placeholder={intl.formatMessage(ProfileFormMessages.addressLine1Label)}
                            onFocus={() => handleFocus('legal.streetAddress1')}
                            onBlur={() => handleBlur('legal.streetAddress1')}
                          />

                          <InputField
                            name="legal.streetAddress2"
                            label={intl.formatMessage(ProfileFormMessages.addressLine2Label)}
                            placeholder={intl.formatMessage(ProfileFormMessages.addressLine2Label)}
                            onFocus={() => handleFocus('legal.streetAddress2')}
                            onBlur={() => handleBlur('legal.streetAddress2')}
                          />

                          <InputField
                            name="legal.city"
                            validate={ruleNotIncludeNumbers()}
                            label={intl.formatMessage(ProfileFormMessages.cityLabel)}
                            placeholder={intl.formatMessage(ProfileFormMessages.cityLabel)}
                            onFocus={() => handleFocus('legal.city')}
                            onBlur={() => handleBlur('legal.city')}
                          />

                          <InputField
                            name="legal.region"
                            validate={ruleNotIncludeNumbers()}
                            label={intl.formatMessage(ProfileFormMessages.regionLabel)}
                            placeholder={intl.formatMessage(ProfileFormMessages.regionLabel)}
                            onFocus={() => handleFocus('legal.region')}
                            onBlur={() => handleBlur('legal.region')}
                          />
                        </ItemWrapper>
                      </Card>
                    </div>
                  )}
                  <div id={PROFILE_PAGE_TABS_IDS.DELIVERY_ADDRESS}>
                    <Card>
                      <ItemWrapper>
                        <div className={styles.Card__Title}>
                          Shipping address
                        </div>
                      </ItemWrapper>
                      <ItemWrapper additionalClassNames={[styles.Card__Grid]}>
                        <SelectSearchField
                          name="countryId"
                          options={countryOptions}
                          label={intl.formatMessage(ProfileFormMessages.countryLabel)}
                          placeholder={intl.formatMessage(ProfileFormMessages.countryPlaceholder)}
                          defaultInputValue={
                            countryOptions.find((c) => c.value === initialValues.countryId)
                              ?.inputLabel
                          }
                          onFocus={() => handleFocus('countryId')}
                          onBlur={() => handleBlur('countryId')}
                        />

                        <InputField
                          name="postalCode"
                          label={intl.formatMessage(ProfileFormMessages.postalCodeLabel)}
                          placeholder={intl.formatMessage(ProfileFormMessages.postalCodeLabel)}
                          onFocus={() => handleFocus('postalCode')}
                          onBlur={() => handleBlur('postalCode')}
                        />

                        <InputField
                          name="streetAddress1"
                          label={intl.formatMessage(ProfileFormMessages.addressLine1Label)}
                          placeholder={intl.formatMessage(ProfileFormMessages.addressLine1Label)}
                          onFocus={() => handleFocus('streetAddress1')}
                          onBlur={() => handleBlur('streetAddress1')}
                        />

                        <InputField
                          name="streetAddress2"
                          label={intl.formatMessage(ProfileFormMessages.addressLine2Label)}
                          placeholder={intl.formatMessage(ProfileFormMessages.addressLine2Label)}
                          onFocus={() => handleFocus('streetAddress2')}
                          onBlur={() => handleBlur('streetAddress2')}
                        />

                        <InputField
                          name="city"
                          validate={ruleNotIncludeNumbers()}
                          label={intl.formatMessage(ProfileFormMessages.cityLabel)}
                          placeholder={intl.formatMessage(ProfileFormMessages.cityLabel)}
                          onFocus={() => handleFocus('city')}
                          onBlur={() => handleBlur('city')}
                        />

                        <InputField
                          name="region"
                          validate={ruleNotIncludeNumbers()}
                          label={intl.formatMessage(ProfileFormMessages.regionLabel)}
                          placeholder={intl.formatMessage(ProfileFormMessages.regionLabel)}
                          onFocus={() => handleFocus('region')}
                          onBlur={() => handleBlur('region')}
                        />
                      </ItemWrapper>
                    </Card>
                  </div>
                </form>
              )
            }}
          />
        )}
      />
    </div>
  )
}

export default ProfilePageEn
