import { SMS_SOURCE_TYPES } from '../../../../../../hooks/use-redirection-types/constants/SmsSourceTypes'
import {
  RedirectionListBackendResponse,
  RedirectionListResponse,
} from '../../../../../types/redirection/_crud/list'

export const mapRedirectionListBackendToRedirectionList = (
  item: RedirectionListBackendResponse,
): RedirectionListResponse => {
  const { redirections, total_count } = item.data

  return {
    redirections: redirections.map((el) => {
      const deviceIds =
        el.condition.filter((x) => x[1] === 'dongle_dongle_id')?.[0]?.[2] ?? []

      const phones =
        el.condition.filter((x) =>
          ['call_number_caller', 'sms_number_caller'].includes(x[1]),
        )?.[0]?.[2] ?? []

      const contactIds =
        el.condition.filter((x) =>
          ['call_contact_id', 'sms_contact_id'].includes(x[1]),
        )?.[0]?.[2] ?? []

      const contactGroupIds =
        el.condition.filter((x) =>
          [
            'call_initial_contact_group_ids',
            'sms_initial_contact_group_ids',
          ].includes(x[1]),
        )?.[0]?.[2] ?? []

      const smsSourceTypeIds =
        el.condition.filter(
          (x) => 'sms_sms_source_type_id' === x[1],
        )?.[0]?.[2] ?? []

      const smsSourceTypeInternational = Array.isArray(smsSourceTypeIds) && smsSourceTypeIds.includes(
        SMS_SOURCE_TYPES.INTERNATIONAL,
      )
      const smsSourceTypeLocal = Array.isArray(smsSourceTypeIds) && smsSourceTypeIds.includes(
        SMS_SOURCE_TYPES.LOCAL,
      )
      const smsSourceTypeShort = Array.isArray(smsSourceTypeIds) && smsSourceTypeIds.includes(
        SMS_SOURCE_TYPES.SHORT,
      )

      const emails = (el.params as any)['emails'] ?? []
      const telegramChatIds = (el.params as any)['telegram_chat_ids'] ?? []
      const autoReplyMessage = (el.params as any)['auto_reply_message'] ?? ''

      return {
        redirectionId: el.redirection_id,
        redirectionTypeId: el.redirection_type_id,
        name: el.name,
        deviceIds: deviceIds,
        fromPhones: phones,
        fromContactIds: contactIds,
        fromContactGroupIds: contactGroupIds,
        toEmails: emails,
        toTelegramChatIds: telegramChatIds,
        smsSourceTypeIds: smsSourceTypeIds,
        smsSourceTypeInternational: smsSourceTypeInternational,
        smsSourceTypeLocal: smsSourceTypeLocal,
        smsSourceTypeShort: smsSourceTypeShort,
        autoReplyMessage: autoReplyMessage,
        createdAt: el.created_at,
        updatedAt: el.updated_at,
      }
    }),
    totalCount: total_count,
  }
}
