// @ts-nocheck
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from "react-redux";
import { Invitation, Inviter, Registerer, SessionState, UserAgent } from 'sip.js';
import { useModal } from "../../../app/hooks/useModal";
import { sipUserGetDatagate } from "../../../sdk/datagates/api/sip-user";
import { setSip } from "../../../store/reducers/sip";
import { useDevices } from "../../../sdk/hooks/use-devices/useDevices";


export const useSip = () => {
  const [ua, setUa] = useState(null);
  const [hasOutgoingCall, setHasOutgoingCall] = useState<boolean>(false);
  const [hasIncomingCall, setHasIncomingCall] = useState<boolean>(false);
  const [callHasAcceptedByOtherSide, setCallHasAcceptedByOtherSide] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [sipConfig, setSipConfig] = useState(null);
  const [userAgent, setUserAgent] = useState(null);
  const [sipError, setSipError] = useState<boolean>(false);

  const callRef = useRef(null);
  const registererRef = useRef(null);
  const audioRef = useRef(null);

  const dispatch = useDispatch();
  const { handleHideModal } = useModal();

  const {
    devices,
    loading: fetchActiveDevicesLoading,
    handleFetchDevices
  } = useDevices({
    page: 0,
    limit: 1000,
    takeAll: true,
    isActive: true,
  });

  const [phoneFrom, setPhoneFrom] = useState<string | null>(null);

  const initializeUserAgent = async () => {
    try {
      const { data } = await sipUserGetDatagate();

      if (data) {
        const {
          sipLogin,
          sipPassword,
          pbxSipUrl
        } = data;

        const config = {
          displayName: sipLogin,
          uri: `sip:${sipLogin}@${pbxSipUrl || 'pbx.telecorn.com'}`,
          password: sipPassword,
          socket: `wss://${pbxSipUrl || 'pbx.telecorn.com'}:7443`
        };

        setSipConfig(config);
        dispatch(setSip({}));

        const ua = new UserAgent({
          uri: UserAgent.makeURI(config.uri),
          transportOptions: {
            wsServers: [config.socket],
          },
          authorizationUsername: config.displayName,
          authorizationPassword: config.password,
          displayName: config.displayName,
        });

        if (ua) {
          setUserAgent(ua);

          ua.start()
            .then(() => {
              registererRef.current = new Registerer(ua);
              registererRef.current.register()
                .catch((error) => console.error("Failed to register:", error));

              setUa(ua);
            })
            .catch((error) => console.error("Failed to start UserAgent:", error));

          ua.delegate = {
            onInvite: (invitation) => {
              callRef.current = invitation;

              setHasIncomingCall(true);
              setPhoneFrom(invitation?.incomingInviteRequest?.message?.headers?.['X-Call-From']?.[0]?.raw);

              handleFetchDevices({
                imeiManufacture: invitation.incomingInviteRequest.message.headers.From[0].parsed.uri.normal.user
              });

              invitation.stateChange.addListener((newState) => {
                console.log('newState (incoming)', newState);

                if (newState === SessionState.Establishing) {
                  window.playSound('ringback');
                  setLoading(true);
                }

                if (newState === SessionState.Established) {
                  window.stopSound('ringback');
                  window.playSound('answered');
                  setCallHasAcceptedByOtherSide(true);
                  setLoading(false);
                }

                if (newState === SessionState.Terminated) {
                  handleRemoteHangup();
                }
              });

              invitation.delegate = {
                onCancel: () => handleRemoteHangup(),
                onTerminated: () => handleRemoteHangup(),
                onBye: () => handleRemoteHangup(),
              };
            }
          };
        }
      }
    } catch (error) {
      console.error(error);
      setSipError(true);
    }
  };

  useEffect(() => {
    initializeUserAgent();

    return () => {
      if (userAgent) {
        userAgent.stop();
      }

      window.stopAllSounds();

      if (registererRef.current) {
        try {
          registererRef.current.unregister();
        } catch (e) {
          console.error("Failed to unregister:", e);
        }
      }
    };
    // eslint-disable-next-line
  }, []);

  const connectMediaStream = (sessionDescriptionHandler) => {
    const remoteStream = new MediaStream();
    sessionDescriptionHandler.peerConnection.getReceivers().forEach(receiver => {
      if (receiver.track) {
        remoteStream.addTrack(receiver.track);
      }
    });

    if (audioRef.current) {
      audioRef.current.srcObject = remoteStream;
      audioRef.current.play().catch(error => console.error("Failed to play audio:", error));
    }
  };

  const makeCall = async ({ targetUriSip, targetUriID }) => {
    if (!ua) return;

    const targetUri = `sip:${targetUriSip}%23${targetUriID}@pbx.telecorn.com`;
    setLoading(true);

    if (!window.isPlaying('ringback')) {
      window.playSound('ringback');
    }

    try {
      const inviter = new Inviter(ua, UserAgent.makeURI(targetUri), {
        sessionDescriptionHandlerOptions: { constraints: { audio: true, video: false } }
      });

      callRef.current = inviter;

      inviter.stateChange.addListener((newState) => {
        console.log('newState (outgoing)', newState);

        if (newState === SessionState.Establishing) {
          window.playSound('ringback');
          setLoading(true);
        }

        if (newState === SessionState.Established) {
          window.stopSound('ringback');
          window.playSound('answered');
          setCallHasAcceptedByOtherSide(true);
          setLoading(false);
        }

        if (newState === SessionState.Terminated) {
          handleRemoteHangup();
        }
      });

      inviter.delegate = {
        onAccept: () => {
          setCallHasAcceptedByOtherSide(true);
        },
        onTerminate: () => {
          handleRemoteHangup();
        }
      };

      await inviter.invite();
      setHasOutgoingCall(true);
      connectMediaStream(inviter.sessionDescriptionHandler);
      setLoading(false);
    } catch (error) {
      console.error("❌ Failed to make call:", error);
      window.stopSound('ringback');
      setLoading(false);
    }
  };

  const answerIncomingCall = () => {
    if (callRef.current && callRef.current instanceof Invitation) {
      callRef.current.accept({
        sessionDescriptionHandlerOptions: {
          constraints: { audio: true, video: false }
        }
      })
        .then(() => {
          setCallHasAcceptedByOtherSide(true);
          setHasIncomingCall(true);
          setLoading(false);
          connectMediaStream(callRef.current.sessionDescriptionHandler);
        })
        .catch((error) => console.error("Failed to answer call:", error));
    }
  };

  /**
   * Выполняется при завершении сессии (сбросе вызова):
   * останавливаем активные звуки, если играют.
   */
  const handleSessionEnd = () => {
    if (window.isPlaying('ringback')) {
      window.stopSound('ringback');
    }
    if (window.isPlaying('ringing')) {
      window.stopSound('ringing');
    }
  };

  const endCall = () => {
    if (!callRef.current) {
      console.warn("⚠️ No active call to end.");
      return;
    }

    const activeCall = callRef.current;
    callRef.current = null;

    handleSessionEnd();

    // Останавливаем треки в peerConnection (если есть)
    if (activeCall.sessionDescriptionHandler) {
      const pc = activeCall.sessionDescriptionHandler.peerConnection;
      pc.getSenders().forEach(sender => sender.track?.stop());
      pc.getReceivers().forEach(receiver => receiver.track?.stop());
      pc.close();
    }

    if (audioRef.current) {
      audioRef.current.srcObject = null;
    }

    switch (activeCall.state) {
      case SessionState.Initial:
      case SessionState.Establishing:
        if (activeCall instanceof Inviter) {
          activeCall.cancel();
        } else {
          activeCall.reject();
        }
        break;

      case SessionState.Established:
        activeCall.bye();
        break;

      case SessionState.Terminating:
      case SessionState.Terminated:
        if (activeCall.terminate) {
          activeCall.terminate();
        } else {
          activeCall.bye();
        }
        break;

      default:
        break;
    }

    resetCallState();
  };

  const handleRemoteHangup = () => {
    if (callRef.current) {
      handleSessionEnd();
      resetCallState();
    }
  };

  const resetCallState = () => {
    if (!callRef.current) {
      console.warn("⚠️ resetCallState called, but callRef.current is already null.");
    }

    setHasOutgoingCall(false);
    setHasIncomingCall(false);
    setCallHasAcceptedByOtherSide(false);
    setLoading(false);

    setTimeout(() => {
      handleHideModal();
    }, 1500);
  };

  return {
    deviceFrom: devices?.[0],
    phoneFrom,
    makeCall,
    answerIncomingCall,
    endCall,
    ua,
    hasOutgoingCall,
    hasIncomingCall,
    callHasAcceptedByOtherSide,
    loading: loading || fetchActiveDevicesLoading,
    setAudioRef: (ref) => (audioRef.current = ref),
    sipError,
  };
};
