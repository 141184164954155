import {FC} from "react";
import {useSelector} from "react-redux";
import {Dialog_v2} from "../../shared/ui-kit-2/data-display/dialog-v2/Dialog_v2";
import {DialogVersion} from "../../store/reducers/dialog/types";

export const DialogProvider: FC = () => {
  // @ts-ignore
  const { isOpen, version, props, cookedTemplate } = useSelector(store => store.dialog);

  if (version !== DialogVersion.v2 || !isOpen) {
    return <></>
  }

  return <Dialog_v2 isOpen={isOpen} props={props} cookedTemplate={cookedTemplate} />
}
