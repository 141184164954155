import React from 'react'
import { useDevices } from '../../../../../../sdk/hooks/use-devices/useDevices'
import {CallsListPageContainer} from "./components/callsListPage/CallsListPageContainer";

export const CallsListPage = () => {
  const { devices, loading } = useDevices({
    page: 0,
    limit: 1000,
    takeAll: true,
    isActive: true,
  })

  return <CallsListPageContainer devices={devices} loading={loading} />
}
