import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {defineMessages, useIntl} from 'react-intl'
import {useNavigate} from 'react-router'
import {useModal} from '../../../../app/hooks/useModal'
import {ReactComponent as ReceivedIcon} from '../../../../assets/icons/received-mini.svg'
import {ReactComponent as SentIcon} from '../../../../assets/icons/sent-mini.svg'
import {RoutePath} from '../../../../config/routes/constants/routePath'
import {Route} from '../../../../config/routes/enums/route'
import {MODAL_TYPES} from '../../../../layouts/modals/ModalsProvider'
import {PageWrapper} from '../../../../layouts/page-wrapper/PageWrapper'
import {NoDevices} from '../../../../layouts/status-layouts/no-devices/NoDevices'
import {formatPhoneNumber} from '../../../../sdk/formatters/format-phone-number'
import {useDevices} from '../../../../sdk/hooks/use-devices/useDevices'
import {ScreenDevices, useLessThanDeviceScreen,} from '../../../../shared/lib/hooks/useLessThanDeviceScreen'
import {Card} from '../../../../shared/ui-kit-2/data-display/card/Card'
import {Counter} from '../../../../shared/ui-kit-2/data-display/counter/Counter'
import {PaginationMobile} from '../../../../shared/ui-kit-2/data-display/pagination/PaginationMobile'
import {Button} from '../../../../shared/ui-kit-2/inputs/button/Button'
import {useUserSubscription} from '../../../../store/hooks/useUserSubscription'
import styles from './styles.module.scss'
import clsx from 'clsx'
import {StatusNetwork} from "../../../../shared/ui-kit-2/data-display/status-network/StatusNetwork";
import {StatusSubscription} from "../../../../shared/ui-kit-2/data-display/status-subscription/StatusSubscription";
import {changeUrlParams} from "../../../../shared/lib/utils/change-url-params/change-url-params";
import {getUrlParams} from "../../../../shared/lib/utils/get-url-params/get-url-params";
import {DongleFlags} from "../../../../sdk/datagates/types/dongle/_crud/list";
import {dongleGetDatagate} from "../../../../sdk/datagates/api/dongle";
import ControlHeader from "../../../../shared/ui-kit-3/components/ControlHeader/ControlHeader";
import { ReactComponent as ActivateIcon } from '../../../../assets/icons/activate.svg'
import { ReactComponent as BuyIcon } from '../../../../assets/icons/buy.svg'
import {Table} from "../../../../shared/ui-kit-2/data-display/table/Table";
import {LC} from "../../../../tests/e2e/locators";
import {DeviceTableRows} from "../../../../layouts/tables/device-table/components/device-table-rows/DeviceTableRows";
import {DEFAULT_COLS, DEFAULT_COLS_VARIANTS} from "../../../../shared/ui-kit-2/data-display/table/contants/defaultCols";
import {
  HeaderCol,
  TABLE_FILTERS_VARIANTS
} from "../../../../shared/ui-kit-2/data-display/table/components/TableHeader/TableHeader";
import {useTableFilters} from "../../../../shared/ui-kit-2/data-display/table/hooks/useTableFilters";
import {
  tableFiltersToUrlFilters
} from "../../../../shared/lib/utils/table-filters-to-url-filters/tableFiltersToUrlFilters";
import {useSearchParams} from "react-router-dom";


const DeviceListPageMessages = defineMessages({
  title: {
    id: 'DeviceListPageMessages.title',
    defaultMessage: 'Devices',
  },
  activateDevices: {
    id: 'DeviceListPageMessages.activateDevices',
    defaultMessage: 'Activate Device',
  },
  redirections: {
    id: 'DeviceListPageMessages.redirections',
    defaultMessage: 'Redirections',
  },
  autoReplies: {
    id: 'DeviceListPageMessages.autoReplies',
    defaultMessage: 'Auto replies',
  },
  device: {
    id: 'DeviceListPageMessages.device',
    defaultMessage: 'device',
  },
  devices: {
    id: 'DeviceListPageMessages.devices',
    defaultMessage: 'devices',
  },
  buyDevice: {
    id: 'DeviceListPageMessages.buyDevice',
    defaultMessage: 'Buy Device',
  },
  online: {
    id: 'DeviceListPageMessages.online',
    defaultMessage: 'Online',
  },
  offline: {
    id: 'DeviceListPageMessages.offline',
    defaultMessage: 'Offline',
  },
  received: {
    id: 'DeviceListPageMessages.received',
    defaultMessage: 'Received: {count}',
  },
  sent: {
    id: 'DeviceListPageMessages.sent',
    defaultMessage: 'Sent: {count}',
  },
  sms: {
    id: 'DeviceListPageMessages.sms',
    defaultMessage: 'SMS',
  },
  calls: {
    id: 'DeviceListPageMessages.calls',
    defaultMessage: 'Calls',
  },
  details: {
    id: 'DeviceListPageMessages.details',
    defaultMessage: 'Details',
  },
  active: {
    id: 'DeviceTableRows.active',
    defaultMessage: 'active',
  },
  inactive: {
    id: 'DeviceTableRows.inactive',
    defaultMessage: 'inactive',
  },
})

export const DevicesPage = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { isSubscriptionExpired } = useUserSubscription()
  const isMobile = useLessThanDeviceScreen(ScreenDevices.MOBILE_LK)
  const [searchParams] = useSearchParams()

  const { handleOpenModal, type: modalType } = useModal()

  const COLS = {
    modemName: 'modemName',
    sms: 'sms',
    calls: 'calls',
  }

  const headerCols = [
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.id],
    {
      id: COLS.modemName,
      filterType: TABLE_FILTERS_VARIANTS.TEXT_FIELD,
      label: 'Modem name',
      defaultText: 'Enter the name',
    },
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.phone],
    {
      id: COLS.sms,
      label: 'SMS',
    },
    {
      id: COLS.calls,
      label: 'Calls',
    },
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.subscription],
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.network],
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.actions],
  ] as HeaderCol[];

  const {
    filters,
    handleChangeFilters,
    handlePageChange,
    debouncedFilters,
  } = useTableFilters({
    queryIds: [
      ...headerCols.map(({ id }) => id),
      'page',
      'limit',
    ],
    defaultFilterValues: [
      {
        name: 'page',
        value: 1
      },
      {
        name: 'limit',
        value: 50
      }
    ]
  })

  const currentFilters = useMemo(() => tableFiltersToUrlFilters([
    { name: 'page', value: debouncedFilters?.page - 1 },
    { name: 'limit', value: debouncedFilters?.limit },
    { name: 'dongle_ids[]', value: debouncedFilters?.id },
    { name: 'name', value: debouncedFilters?.modemName },
    { name: 'number', value: debouncedFilters?.phone && encodeURIComponent(debouncedFilters?.phone) },
    { name: 'is_tariff_package_active', value: debouncedFilters?.subscription },
    { name: 'is_online', value: debouncedFilters?.network },
  ]), [debouncedFilters]);

  const {
    devices,
    totalCount,
    handleFetchDevices,
    handleDeleteDevice,
    handleSwitchHotspot,
    loading,
    setDevices,
  } = useDevices({
    filters: currentFilters
  })

  const {
    loading: inactiveDevicesLoading,
    totalCount: inactiveDevicesTotalCount,
  } = useDevices({
    page: 0,
    limit: 1000,
    takeAll: true,
    isActive: false,
  })

  // TODO: any -> на нужный тип для device
  const handleOpenDeviceInfo = (dongleId: string, openSubscriptionsByDefault?: boolean) => {
    handleOpenModal({
      type: MODAL_TYPES.DEVICE_DETAILS,
      props: {
        deviceId: dongleId,
        handleFetchDevices,
        handleDeleteDevice,
        handleSwitchHotspot,
        openSubscriptionsByDefault
      },
    })
  }

  useEffect(() => {
    const modalDeviceId = searchParams.get('dongleId');

    if (modalDeviceId && modalDeviceId !== 'null' && !modalType) {
      handleOpenDeviceInfo(modalDeviceId, true);
    }
  }, []);

  const toBuyNewDevice = () => {
    handleOpenModal({
      type: MODAL_TYPES.BUY_NEW_DEVICE,
    })
  }

  const toActivateNewDevice = () => navigate(RoutePath[Route.ActivateDevice])

  const deviceWithPhoneNumberChangeLoading = useMemo(() =>
    devices.find(({ dongleFlags }) => dongleFlags?.find(flag => flag.dongleFlagId === DongleFlags.MANUAL_PHONE_DETERMINING))?.dongleId
  , [devices]);

  const timerRef = useRef(null);

  const handleFetchDevice = async (deviceId: number) => {
    try {
      const { data } = await dongleGetDatagate(null, [{ name: 'dongle_id', value: `${deviceId}` }])
      return data;
    } catch {}
  }

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    if (deviceWithPhoneNumberChangeLoading) {
      // @ts-ignore
      timerRef.current = setInterval(async () => {
        const updatedDongle = await handleFetchDevice(deviceWithPhoneNumberChangeLoading);
        if (updatedDongle && !updatedDongle.dongleFlags?.find(flag => flag.dongleFlagId === DongleFlags.MANUAL_PHONE_DETERMINING)) {
          setDevices(prev => {
            const copyArr = [...prev];
            const indexOfUpdatedDongle = copyArr.findIndex(({ dongleId }) => dongleId === updatedDongle.dongleId)
            copyArr[indexOfUpdatedDongle] = updatedDongle;
            return copyArr;
          })
        }
      }, 10000);
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [deviceWithPhoneNumberChangeLoading]);

  const DeviceListMobile = () => {
    return devices.length > 0 ? (
      <>
        <div className={styles.DeviceListMobile}>
          {devices.map(device => (
            <div
              key={device.dongleId}
              className={styles.DeviceListMobile__Item}
            >
              <div className={styles.DeviceListMobile__Top}>
                <div className={styles.DeviceListMobile__TopRow}>
                  <div className={styles.DeviceListMobile__Title}>
                    {device.name}
                  </div>
                  <StatusNetwork
                    isOnline={device.isOnline}
                    dongleSignalQualityId={device.dongleSignalQualityId}
                  />
                </div>
                <div className={styles.DeviceListMobile__Number}>
                  {formatPhoneNumber(device.phoneNumber)}
                </div>
              </div>

              <div className={styles.DeviceListMobile__Divider} />

              <div className={styles.DeviceListMobile__Cols}>
                <div className={styles.DeviceListMobile__Col}>
                  <div className={styles.DeviceListMobile__Label}>
                    {intl.formatMessage(DeviceListPageMessages.sms)}
                  </div>

                  <div className={styles.DeviceListMobile__Row}>
                    <div className={styles.DeviceListMobile__Icon}>
                      <SentIcon />
                    </div>
                    {intl.formatMessage(DeviceListPageMessages.sent, {
                      count: device.smsOutgoing,
                    })}
                  </div>

                  <div className={styles.DeviceListMobile__Row}>
                    <div className={styles.DeviceListMobile__Icon}>
                      <ReceivedIcon />
                    </div>
                    {intl.formatMessage(DeviceListPageMessages.received, {
                      count: device.smsIncoming,
                    })}
                  </div>
                </div>

                <div className={styles.DeviceListMobile__Col}>
                  <div className={styles.DeviceListMobile__Label}>
                    {intl.formatMessage(DeviceListPageMessages.calls)}
                  </div>

                  <div className={styles.DeviceListMobile__Row}>
                    <div className={styles.DeviceListMobile__Icon}>
                      <SentIcon />
                    </div>
                    {intl.formatMessage(DeviceListPageMessages.sent, {
                      count: device.callOutgoing,
                    })}
                  </div>

                  <div className={styles.DeviceListMobile__Row}>
                    <div className={styles.DeviceListMobile__Icon}>
                      <ReceivedIcon />
                    </div>
                    {intl.formatMessage(DeviceListPageMessages.received, {
                      count: device.callIncoming,
                    })}
                  </div>
                </div>
              </div>

              <div className={styles.DeviceListMobile__Button}>
                <StatusSubscription isTariffPackageActive={device.isTariffPackageActive}/>
                <Button
                  text={intl.formatMessage(DeviceListPageMessages.details)}
                  onClick={() => handleOpenDeviceInfo(String(device.dongleId))}
                  variant={'greenTextOutlined'}
                  icon={'caret'}
                  reverse
                />
              </div>
            </div>
          ))}
        </div>
      </>
    ) : (
      <NoDevices />
    )
  }

  const noData = totalCount + inactiveDevicesTotalCount === 0;

  const rows = DeviceTableRows(
    devices,
    handleFetchDevices,
    handleDeleteDevice,
    handleSwitchHotspot,
  )

  return (
    <PageWrapper>
      <Card additionalClassNames={[styles.CardAdditional]}>
        <ControlHeader
          title={'Modems'}
          counter={{
            min: devices.length,
            max: totalCount
          }}
          actions={[
            {
              variant: 'greenOutlined',
              size: 'md',
              text: intl.formatMessage(DeviceListPageMessages.activateDevices),
              prefix: <ActivateIcon />,
              onClick: toActivateNewDevice,
              disabled: isSubscriptionExpired || isMobile,
            },
            {
              variant: 'greenFilled',
              size: 'md',
              text: intl.formatMessage(DeviceListPageMessages.buyDevice),
              prefix: <BuyIcon />,
              onClick: toBuyNewDevice,
              disabled: isSubscriptionExpired,
            }
          ]}
          loading={loading}
        />

        {isMobile ? (
          <DeviceListMobile />
        ) : (
          <Table
            headerCols={headerCols}
            filters={filters}
            handleChangeFilters={handleChangeFilters}
            name={'DeviceTable'}
            rowGroups={rows}
            currentPage={filters.page}
            totalCount={totalCount}
            onPageChange={handlePageChange}
            itemsPerPage={filters.limit}
            columnWidths={['80px', 5, 4, 4, 4, 2.5, 2, 1.5]}
            loading={loading}
            noDataComponent={noData ? <NoDevices /> : undefined}
            testId={LC.DEVICES.TABLE._}
            classes={{
              rowGroups: noData ? styles.RowGroups : '',
              container: styles.ContainerDevices
            }}
          />
        )}
      </Card>
    </PageWrapper>
  )
}
