
import React, {useMemo, useState} from "react";
import {Form} from "react-final-form";
import { Drawer } from '../../../shared/ui-kit-2/data-display/drawer/Drawer'
import {useModal} from "../../../app/hooks/useModal";
import {InputField} from "../../../shared/lib/form/form-field-adapters/v2/input-field/InputField";
import {useFormRules} from "../../../shared/lib/form/form-rules";
import {PhoneMultipleSettings} from "../_components/phone-multiple-settings/PhoneMultipleSettings";
import {Source} from "../_hooks/use-add-source/useAddSource";
import {useDevices} from "../../../sdk/hooks/use-devices/useDevices";
import {SelectSearchField} from "../../../shared/lib/form/form-field-adapters/v2/select-search-field/SelectSearchField";
import {SelectSearchOption} from "../../../shared/ui-kit-2/inputs/select-search/SelectSearch";
import {Button} from "../../../shared/ui-kit-2/inputs/button/Button";
import {blobToBase64} from "../../../shared/lib/utils/blob-to-base-64/blobToBase64";
import {formatPhoneNumber} from "../../../sdk/formatters/format-phone-number";
import {AudioUpload} from "../../../shared/ui-kit-3/components/AudioUpload";
import styles from './styles.module.scss';


export const CallsAutoResponseRulesModal = () => {
  const {
    props: {
      rule: propsRule,
      handleFetchRules,
      handleUpdateRules,
      handleAddRule,
      handleUpdateRule,
    },
    handleHideModal
  } = useModal();
  const [rule, setRule] = useState(propsRule);
  const { ruleRequired } = useFormRules();
  const [sources, setSources] = useState<Source[]>(
    rule?.call_scenario_phones
      ? rule?.call_scenario_phones?.map(({ phone }: { phone: string }) => {
        const formattedPhone = formatPhoneNumber(phone);

        return {
          sourceName: formattedPhone,
          sourceType: 'phone',
          sourceValue: formattedPhone
        };
      })
      : []
  );
  const { devices } = useDevices({
    page: 0,
    limit: 1000,
    takeAll: true,
    brandId: 2,
    callTypeId: 2
  })
  const [allPhones, setAllPhones] = useState<boolean>(sources.length === 0);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [errors, setErrors] = useState<Record<string, string | boolean> | null>(null);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const dongleOptions: SelectSearchOption[] = useMemo(
    () =>
      devices.map((d) => {
        return {
          key: d.dongleId,
          label: d.name,
          inputLabel: d.name,
          value: d.dongleId.toString(),
        }
      }),
    [devices],
  );

  const handleSubmit = async (data: any) => {
    setSubmitLoading(true);

    if (!uploadedFile && !rule?.file?.cdn_url) {
      setErrors(prev => ({ uploadedFile: prev?.uploadedFile || true }));
      setSubmitLoading(false);
      return;
    }

    if (errors?.uploadedFile) {
      setSubmitLoading(false);
      return;
    }

    const audioBase64 = uploadedFile ? await blobToBase64(new Blob([uploadedFile], { type: uploadedFile.type })) : '';
    const contactIds = sources
      .filter(({ sourceType }) => sourceType === "contacts")
      .map(({ sourceValue }) => +sourceValue);
    const contactGroupIds = sources
      .filter(({ sourceType }) => sourceType === "contact-groups")
      .map(({ sourceValue }) => +sourceValue);
    const phones = sources
      .filter(({ sourceType }) => sourceType === "phone")
      .map(({ sourceValue }) => `+${sourceValue}`);

    try {
      if (rule) {
        await handleUpdateRule({
          callScenarioId: rule.call_scenario_id,
          contactIds,
          contactGroupIds,
          phones,
          ...(data.name !== rule.name ? { name: data.name } : {}),
          ...(audioBase64 && uploadedFile ? { audioFileBase64: audioBase64, audioFileName: uploadedFile.name } : {}),
        })

        handleUpdateRules();
      } else {
        await handleAddRule({
          dongleId: +data.dongleId,
          name: data.name,
          audioFileBase64: audioBase64,
          audioFileName: uploadedFile?.name,
          contactIds,
          contactGroupIds,
          phones,
        })

        handleFetchRules();
      }

      handleHideModal();
    } catch {} finally {
      setSubmitLoading(false);
    }
  }

  return (
    <Drawer
      isOpen={true}
      close={handleHideModal}
      title={rule?.name || 'Create rule'}
      limitedWidth={false}
    >
      <Form
        onSubmit={handleSubmit}
        initialValues={{
          name: rule?.name,
          dongleId: String(rule?.dongle_id)
        }}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit} className={styles.Form}>
            <InputField
              name={'name'}
              validate={ruleRequired()}
              markAsRequired={true}
              label={'Name'}
              placeholder={'Name of rule'}
            />
            <SelectSearchField
              name="dongleId"
              validate={!rule ? ruleRequired() : undefined}
              options={dongleOptions}
              label={'Modem'}
              placeholder={'Modem'}
              markAsRequired={true}
              dropdownElementsInView={12}
              disabled={rule}
              {...(rule?.dongle?.name ? { defaultInputValue: rule.dongle.name } : {})}
            />
            <PhoneMultipleSettings
              title='Numbers'
              sources={sources}
              setSources={setSources}
              phoneSourceVariant={'all'}
              error={errors?.phones as boolean}
              allPhones={allPhones}
              setAllPhones={setAllPhones}
            />

            <AudioUpload
              uploadedFile={uploadedFile}
              setUploadedFile={setUploadedFile}
              errors={errors}
              setErrors={setErrors}
              handleClear={() => {
                setRule((prev: any) => ({
                  ...prev,
                  file: {
                    cdn_url: null
                  }
                }))
              }}
              cdnUrl={rule?.file?.cdn_url}
            />

            {typeof errors?.uploadedFile === 'string' && (
              <div className={styles.FileError}>
                {errors?.uploadedFile}
              </div>
            )}

            <div className={styles.Submit}>
              <Button
                type={'submit'}
                text={rule ? 'Update' : 'Create'}
                loading={submitLoading}
                disabled={submitLoading}
              />
            </div>
          </form>
        )}
      />
    </Drawer>
  )
}