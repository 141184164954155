import {useCallback, useEffect, useMemo, useState} from "react";
import {orderDongleGetDatagate} from "../datagates/api/order-dongle";
import {TARIFF_MONTH_PACKAGES_IDS, TARIFF_YEAR_PACKAGES_IDS} from "../datagates/types/order/_common";

export const useOrderDongle = () => {
  const [orderDongle, setOrderDongle] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchOrderDongle = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await orderDongleGetDatagate();

      if (data) {
        // @ts-ignore
        setOrderDongle(data);
      }
    } catch {} finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchOrderDongle();
  }, []);

  const monthlyAvailable = useMemo(() => {
    if (orderDongle) {
      // @ts-ignore
      return orderDongle.filter(item => TARIFF_MONTH_PACKAGES_IDS.includes(item.tariffPackage.tariffPackageId))
    }

    return null;
  }, [orderDongle]);

  const annualAvailable = useMemo(() => {
    if (orderDongle) {
      // @ts-ignore
      return orderDongle.filter(item => TARIFF_YEAR_PACKAGES_IDS.includes(item.tariffPackage.tariffPackageId))
    }

    return null;
  }, [orderDongle])

  return {
    orderDongle,
    monthlyAvailable,
    annualAvailable,
    fetchOrderDongle,
    loading
  }
}